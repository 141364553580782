import AbcIcon from "@mui/icons-material/Abc"
import CandlestickChartIcon from "@mui/icons-material/CandlestickChartOutlined"
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter"
import ShowChartIcon from "@mui/icons-material/ShowChart"
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart"
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Select,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import {
  getChartOptionsData,
} from "../../../common/utility/configData"
import {
  PROFILE_VIEW,
  TEXT_MSGS,
  USER_RESOURCES_LIST,
} from "../../../common/utility/constant"
import {
  CHART_TYPE,
  VA_TYPE,
  VP_TYPE,
} from "../../../common/utility/mp_chart_constants"
// import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ScoreOutlinedIcon from "@mui/icons-material/ScoreOutlined"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';

const ITEM_HEIGHT = 48
const ITEM_HEIGHT_NEW = 26
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_NEW * 10 + ITEM_PADDING_TOP,
      width: 120,
      autoFocus: false,
    },
  },
}

export const FooterToolbar = styled(Toolbar)(
  ({ theme }) => `
    background-color:${theme.palette.primaryTheme.shade01};
    width:100vw;
    height:32px;
    position:fixed;
    text-align:center;
    ${theme.breakpoints.up("mobile")}{
      min-height:32px;
    }
   `
)

/**
 *
 * @returns fixed toolbar for copyright text
 */
function LineChartFooter(props) {
  const theme = useTheme()
  const [userSettings, setUserSettings] = useState(props.userSettings)
  const [selectedInstrumentData, setSelectedInstrumentData] = useState(
    props.selectedInstrumentData
  )

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [chartOptionsData, setChartOptionsData] = useState(
    getChartOptionsData(props.auth)
  )
  const [isStocksAllowed, setIsStocksAllowed] = useState(
    props.auth.resources?.find((el) => el == USER_RESOURCES_LIST.OF_STOCKS)
      ? true
      : false
  )
  const [isIndexAllowed, setIsIndexAllowed] = useState(
    props.auth.resources?.find((el) => el == USER_RESOURCES_LIST.OF_INDEX)
      ? true
      : false
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event, index) => {
    setAnchorEl(null)
    if (index >= 0) {
      console.log(
        "event.target.value menu=======>",
        event.target.value,
        chartOptionsData[index],
        index
      )
      props.handleChartChange(chartOptionsData[index])
    }
  }

  const openLineChart = () => {
    if (props.basicUser) props.openSubscribeModal()
    else props.openLineChart()
  }

  const openOFChart = () => {
    if (
      !isStocksAllowed &&
      selectedInstrumentData.resource &&
      selectedInstrumentData.resource == "stocks"
    ) {
      props.openSunscriptionModal(
        true,
        TEXT_MSGS.OF_CHART_STOCKS_RESOURCE_NOT_SUBSCRIBED_DESC
      )
      return
    } else if (
      !isIndexAllowed &&
      selectedInstrumentData.resource &&
      selectedInstrumentData.resource == "index"
    ) {
      props.openSunscriptionModal(
        true,
        TEXT_MSGS.OF_CHART_INDEX_RESOURCE_NOT_SUBSCRIBED_DESC
      )
      return
    } else props.openOFChart()
  }

  const openMPChart = () => {
    props.openMPChart()
  }

  useEffect(() => {
    setSelectedInstrumentData(props.selectedInstrumentData)
  }, [props.selectedInstrumentData])

  const openSettings=()=>{
    props.openSettings();
  }
  return (
    <>
      <FooterToolbar
        sx={{
          paddingLeft: "4px",
          paddingRight: "8px",
          borderTop: `1px solid ${theme.palette.primaryTheme.shade03}`,
          borderRight: `2px solid ${theme.palette.primaryTheme.shade03}`,
          left: props.chartStateData.left,
          top: props.chartStateData.top + props.chartStateData.height + 20,
          width: props.chartStateData.width,
        }}
      >
        <>
          <Box
            display="flex"
            sx={{
              height: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              width: `calc(${props.chartStateData.width - 160}px)`,
              alignItems: "center",
            }}
          >
            {props.chartType==CHART_TYPE.LINE_CHART || props.chartType==CHART_TYPE.BAR_CHART?
             <Tooltip title={props.chartType==CHART_TYPE.LINE_CHART?"Line Chart Settings":"Bar Chart Settings"}>
              <SettingsIcon onClick={openSettings}sx={{ml:"2px",marginRight:"6px", marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",cursor:'pointer'}}/>
          </Tooltip>:
          <></>
          }

            <Typography sx={{ fontSize: 14, ml: 2, mt: 0 }}>Charts</Typography>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {chartOptionsData.map((option, index) => (
                <MenuItem
                  key={option.key}
                  onClick={(event) => handleClose(event, index)}
                >
                  {option.key}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ height: "100%", width: "170px", alignItems: "center" }}
        >
          {selectedInstrumentData.line_chart ? (
            <Tooltip title="Line Chart">
              <ShowChartIcon
                onClick={openLineChart}
                sx={{
                  border: "0.5px solid",
                  ml: 1,
                  marginTop:
                    props.chartType == CHART_TYPE.MARKET_PROFILE
                      ? "-3px"
                      : "-3px",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
          {selectedInstrumentData.of ? (
            <Tooltip title="Orderflow Chart">
              <ScoreOutlinedIcon
                onClick={openOFChart}
                sx={{
                  border: "0.5px solid",
                  ml: 1,
                  marginTop:
                    props.chartType == CHART_TYPE.MARKET_PROFILE
                      ? "-3px"
                      : "-3px",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}

          <Tooltip title="MP chart">
            <AbcIcon
              // disabled
              onClick={openMPChart}
              sx={{
                border: "0.5px solid",
                ml: 1,
                marginTop:
                  props.chartType == CHART_TYPE.MARKET_PROFILE
                    ? "-3px"
                    : "-3px",
                height: 20,
                width: 20,
                cursor: "pointer",
              }}
            ></AbcIcon>
          </Tooltip>
        </Box>
      </FooterToolbar>
    </>
  )
}

export default LineChartFooter
