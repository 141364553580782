import { useEffect,useRef,useState } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import {Snackbar, Box,Select,Typography,Container,CircularProgress,Alert, Grid} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import OptionsFooter from './components/options_footer';
import dayjs from 'dayjs';
import { Button, DatePicker} from 'antd';
import { TEXT_MSGS ,SNACKBAR_AUTO_HIDE_DURATION_SHORT} from '../../common/utility/constant';
import {styled, useTheme} from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { ToolbarFixed } from '../../layouts/component.js';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS } from '../../common/utility/constant';
import ConfirmActionDialog from './components/confirm_dialog';
import LoginModal from './components/login_popups';

const DateFormat = 'DD-MM-YYYY';
const FooterToolbar=styled(ToolbarFixed)(
    ({theme})=> `
    bottom:0px;
    justify-content:center;
    text-align:center;
    `
  )
export default function FeedAdmin() {
    const navigate = useNavigate(); 
    const theme=useTheme();
    const from = "/login";
    const { auth } = useAuth();
    const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelRefresh,responseDataRefresh,errorRefresh,loadedRefresh,resetRefresh,refreshInMemoryCache] = useAxiosWithAuth();
    const [cancelReload,responseDataReload,errorReload,loadedReload,resetReload,reloadHistTicks] = useAxiosWithAuth();
    const [cancelSwitch,responseDataSwitch,errorSwitch,loadedSwitch,resetSwitch,switchFeedDevice] = useAxiosWithAuth();
    const [cancelMpliteSwitch,responseMpliteSwitch,errorMpliteSwitch,loadedMpliteSwitch,resetMpliteSwitch,switchMpliteRoles] = useAxiosWithAuth();
    const [showLoginPopup,setShowLoginPopup]=useState(false);
    const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);

    const [state, setState] = useState({
      isLoading: true,
      data: undefined,
      eodProcessStatus: undefined,
      lastTradeDate: undefined,
      mpliteAccess: undefined,
      bhavCopyUpdated: undefined,
      cachedChartData: undefined,
      isFeedLive: false,
      reloadInerval: 5*1000, // 5 sec
    });
    const { isLoading, data, eodProcessStatus, lastTradeDate, mpliteAccess, bhavCopyUpdated, cachedChartData, isFeedLive, reloadInerval } = state

    const [buttonState, setButtonState] = useState({
      buttonName: "",
      isSuccess: false
    })
;   const { buttonName, isSuccess } = buttonState;

    const [buttonRefreshClickState,setButtonRefreshClickState]=useState({
      openRefreshConfirmDialog:false
    })
    const {openRefreshConfirmDialog}=buttonRefreshClickState;

    const [buttonReloadClickState,setButtonReloadClickState]=useState({
      openReloadConfirmDialog:false
    })
    const {openReloadConfirmDialog}=buttonReloadClickState;

    const [buttonSwitchClickState,setButtonSwitchClickState]=useState({
      openSwitchConfirmDialog:false,
      deviceName:""
    })
    const {openSwitchConfirmDialog, deviceName}=buttonSwitchClickState;

    const [msgState, setMsgState] = useState({
        open: false,
        msg:"" ,
        severity:"info"
    });
    const { open, msg, severity } = msgState;

    const stateRef = useRef(state);
    const buttonStateRef = useRef(buttonState);

     /**
        * autoclosing of the snackbar msg bar 
    */ 
    const handleClose = (event, reason) => {
        setMsgState({...msgState,open:false});
    };

    const cancelConfirmRefreshHandler=()=>{
      setButtonRefreshClickState({
        openRefreshConfirmDialog:false
      })
    }
  
    const confirmRefreshAction=()=>{
      setButtonRefreshClickState({
        openRefreshConfirmDialog:false
      })
      refreshInMemoryCache(`${URL.REFRESH_IMDATA}`,"GET",{});
    }

    const RefreshInMemoryData=()=>{
      setButtonRefreshClickState({
        openRefreshConfirmDialog:true
      })
    }

    const cancelConfirmReloadHandler=()=>{
      setButtonReloadClickState({
        openReloadConfirmDialog:false
      })
    }
  
    const confirmReloadAction=()=>{
      setButtonReloadClickState({
        openReloadConfirmDialog:false
      })
      reloadHistTicks(`${URL.RELOAD_FEED_HIST}`,"GET",{});
    }

    const ReloadHistTicks=()=>{
      setButtonReloadClickState({
        openReloadConfirmDialog:true
      })
    }

    const cancelConfirmSwitchHandler=()=>{
      setButtonSwitchClickState({
        openSwitchConfirmDialog:false,
        deviceName:""
      })
    }
  
    const confirmSwitchAction=()=>{
      switchFeedDevice(`${URL.SWITCH_FEED_DEVICE}?deviceName=${deviceName}`,"GET",{});
      setButtonSwitchClickState({
        openSwitchConfirmDialog:false,
        deviceName:""
      })
    }

    const SwitchDevice=(device_name)=>{
      setButtonSwitchClickState({
        openSwitchConfirmDialog:true,
        deviceName:device_name
      })
    }

    const SwitchTestAccess=(role_name)=>{
      switchMpliteRoles(`${URL.SWITCH_MPLITE_ROLES}?roleName=${role_name}`,"GET",{});
    }

    useEffect(() => {
      //set the ref to current state
      stateRef.current = state;
  
      console.log("use effect called=", stateRef.current, reloadInerval);
  
      const timeout = setInterval(() => {
        executeAPI(`${URL.FEED_DATA}`,"GET",{}) 
      }, reloadInerval);
  
      return () => {
        // just to clear the timeout when component unmounts
        clearInterval(timeout);
      };
    }, [state]);
    
    
    useEffect(()=>{
        executeAPI(`${URL.FEED_DATA}`,"GET",{}) ;
    },[])

    useEffect(() => {
      buttonStateRef.current = buttonState;

      if(loadedRefresh){
        console.log("loaded data ",responseDataRefresh);
        if(responseDataRefresh!=null){
             setButtonState((prevState)=>({
                ...prevState,
                buttonName:"Cache Refresh",
                isSuccess:responseDataRefresh.result === "success",
             }))
        }
        else if(errorRefresh!==null){
          console.log("Error data=",errorRefresh);
            setMsgState({open:true,msg:errorRefresh?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            //if unauthorized then redirec it to login page
            if(errorRefresh?.response?.status === 401 || errorRefresh?.response?.status === 403){
              console.log("status received =",errorRefresh?.response?.status)
              // navigate(from, { replace: true });
              setLoginMsg(errorRefresh?.response?.data?.message);
              setShowLoginPopup(true);
            }
        }
        reset();
      }
  },[loadedRefresh,responseDataRefresh]);

  useEffect(() => {
    buttonStateRef.current = buttonState;
    
    if(loadedReload){
      console.log("loaded data ",responseDataReload);
      if(responseDataReload!=null){
           setButtonState((prevState)=>({
              ...prevState,
              buttonName:"Data Reload",
              isSuccess:responseDataReload.result === "success",
           }))
      }
      else if(errorReload!==null){
        console.log("Error data=",errorReload);
          setMsgState({open:true,msg:errorReload?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorReload?.response?.status === 401 || errorReload?.response?.status === 403){
            console.log("status received =",errorReload?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorReload?.response?.data?.message);
            setShowLoginPopup(true);
          }
      }
      reset();
    }
},[loadedReload,responseDataReload]);

useEffect(() => {
  buttonStateRef.current = buttonState;
  
  if(loadedSwitch){
    console.log("loaded data ",responseDataSwitch);
    if(responseDataSwitch!=null){
         setButtonState((prevState)=>({
            ...prevState,
            buttonName:"Feed Device Switch to " + responseDataSwitch.device_name,
            isSuccess:responseDataSwitch.result === "success",
         }))
    }
    else if(errorSwitch!==null){
      console.log("Error data=",errorSwitch);
        setMsgState({open:true,msg:errorSwitch?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSwitch?.response?.status === 401 || errorSwitch?.response?.status === 403){
          console.log("status received =",errorSwitch?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorSwitch?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    reset();
  }
},[loadedSwitch,responseDataSwitch]);

useEffect(() => {
  buttonStateRef.current = buttonState;
  
  if(loadedMpliteSwitch){
    console.log("loaded data ",responseMpliteSwitch);
    if(responseMpliteSwitch!=null){
      setState((prevState)=>({
        ...prevState,
        mpliteAccess:responseMpliteSwitch.mpliteAccess,
      }))
        //  setButtonState((prevState)=>({
        //     ...prevState,
        //     buttonName:"Access Device Switch to " + responseMpliteSwitch.role_name,
        //     isSuccess:responseMpliteSwitch.result === "success",
        //  }))
    }
    else if(errorMpliteSwitch!==null){
      console.log("Error data=",errorMpliteSwitch);
        setMsgState({open:true,msg:errorMpliteSwitch?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorMpliteSwitch?.response?.status === 401 || errorMpliteSwitch?.response?.status === 403){
          console.log("status received =",errorMpliteSwitch?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorMpliteSwitch?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    reset();
  }
},[loadedMpliteSwitch,responseMpliteSwitch]);
  useEffect(() => {
      if(loaded){
        console.log("loaded data ",responseData);
        if(responseData!=null){
              console.log("active device", responseData.data.ACTIVE_DEVICE)
              setState((prevState)=>({
                ...prevState,
                data:responseData.data,
                eodProcessStatus:responseData.eodProcessStatus,
                lastTradeDate:responseData.lastTradeDate,
                mpliteAccess:responseData.mpliteAccess,
                bhavCopyUpdated:responseData.bhavCopyUpdated,
                cachedChartData:responseData.cachedDates,
                isFeedLive:responseData.isLive,
                reloadInerval:responseData.isLive?5*1000:5*60*1000,
                isLoading:false  
              }))
        }
        else if(error!==null){
          console.log("Error data=",error);
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
              console.log("status received =",error?.response?.status)
              // navigate(from, { replace: true });
              setLoginMsg(error?.response?.data?.message);
              setShowLoginPopup(true);
            }
        }
        reset();
      }
  },[loaded,responseData]);

  useEffect(() => {
    document.title = "Feed Admin";
  }, []);

    return(
        <>
        {!isLoading?
          <div style={{margin:20,marginTop:40}}>
            <p>Active Device: {data.ACTIVE_DEVICE}</p>
            <p>Last Update on Active Device: {dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_UPDATE, "DD-MM-YYYY hh:mm:ss"), 'seconds')} sec ago.
            <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Update from FYR1  Feed: {dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_UPDATE_td1, "DD-MM-YYYY hh:mm:ss"), 'seconds')} sec ago.
            <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Update from FYR2 Feed: {dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_UPDATE_fyr2, "DD-MM-YYYY hh:mm:ss"), 'seconds')} sec ago.
            <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Update from ZDH1 Feed: {dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_UPDATE_zdh1, "DD-MM-YYYY hh:mm:ss"), 'seconds')} sec ago.
            <br/>Last Ping on Active Device: {dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_PING, "DD-MM-YYYY hh:mm:ss"), 'seconds')} sec ago.
            <br/>Last Hist Refresh on Active Device: {
                dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_HIST_UPDATE, "DD-MM-YYYY hh:mm:ss"), "minutes") < 60
                ? dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_HIST_UPDATE, "DD-MM-YYYY hh:mm:ss"), "minutes") + " minutes"
                : dayjs().diff(dayjs(data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_HIST_UPDATE, "DD-MM-YYYY hh:mm:ss"), "hours", true).toFixed(2) + " hours"} ago at {data.DEVICE_STATUS[data.ACTIVE_DEVICE].LAST_HIST_UPDATE}</p>
            <p>Other Devices:
            <br/>
            {data.DEVICE_PRIORITY_LIST.map((device) =>
              device == data.ACTIVE_DEVICE 
              ? <></> 
              : <>{device + " - Last ping time: " + dayjs().diff(dayjs(data.DEVICE_STATUS[device].LAST_PING, "DD-MM-YYYY hh:mm:ss"), 'seconds') + " sec ago."
                + " Last update time: " + (dayjs().diff(dayjs(data.DEVICE_STATUS[device].LAST_UPDATE, "DD-MM-YYYY hh:mm:ss"), 'seconds') < 60 
                  ? dayjs().diff(dayjs(data.DEVICE_STATUS[device].LAST_UPDATE, "DD-MM-YYYY hh:mm:ss"), 'seconds') + " seconds" 
                  : dayjs().diff(dayjs(data.DEVICE_STATUS[device].LAST_UPDATE, "DD-MM-YYYY hh:mm:ss"), 'minutes') + " minutes") 
                + " ago at " + data.DEVICE_STATUS[device].LAST_UPDATE}<br/></>
            )}
            </p>
            <br/>
            <p>Clear temporary Redis cache and refresh the in-memory cache:</p>
            <Button  variant="contained" onClick={()=>RefreshInMemoryData()} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Refresh InMemory Cache
            </Button>
            <br/><br/>
            <p>Trigger Intra Historical data fetch: </p>
            <Button  variant="contained" onClick={()=>ReloadHistTicks()} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Trigger Feed Data Reload/Backfill
            </Button>
            <br/><br/>
            <p>Switch Feed Device: </p>
            <Button  variant="contained" onClick={()=>SwitchDevice("AWS1_SERVER")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to AWS1_SERVER
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchDevice("DELL_STAGE_SERVER")} sx={{width:"130px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to DELL_STAGE_SERVER
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchDevice("SUYOG_LAPTOP")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to SUYOG_LAPTOP
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchDevice("SHAI_LAPTOP")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to SHAI_LAPTOP
            </Button>
            <br/><br/>
            {isSuccess? "Last " + buttonName + " Action Status: SUCCESS" : ""}
            
            <br/>
            <b>mplite</b> account active privileges: <b>{mpliteAccess}</b>
            <br/>Switch privileges to:<br/>
            <Button  variant="contained" onClick={()=>SwitchTestAccess("basic")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to Basic
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchTestAccess("fast_data")} sx={{width:"130px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to Premium (no Stocks)
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchTestAccess("pro_1")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to Premium +
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button  variant="contained" onClick={()=>SwitchTestAccess("pro_1_orderflow")} sx={{width:"115px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
              Switch to Premium + with both Orderflow
            </Button>
            <br/><br/>
            Last Traded Date: {lastTradeDate} - {lastTradeDate == cachedChartData.lastTradeDate ? "updated in cache" : "NOT updated in cache"}; {lastTradeDate == cachedChartData.lastDateInSortedList ? "updated in dates list" : "NOT updated in dates list"}
            <br/><br/>BhavCopy available for {lastTradeDate}?&nbsp;&nbsp;{bhavCopyUpdated ? "TRUE": "FALSE"}
            <br/>EOD data update status: <b>{eodProcessStatus["success"]? "ALL SUCCESS" : eodProcessStatus["metadata"]["last_run_start_ts"] > eodProcessStatus["metadata"]["last_run_end_ts"] ? "RUNNING" : "SLEEPING"}</b>
            <br/><br/>
            Last date in Cached Charts Date List: {cachedChartData.lastDateInSortedList}<br/>
            <pre>Active Expiries per Symbol: <br/>{JSON.stringify(cachedChartData.activeExpiries, null, 2)}</pre>
            <pre>Expiry Map in DB: <br/>{JSON.stringify(cachedChartData.expiryMap, null, 2)}</pre>
            <pre>EOD data update status [<b>{eodProcessStatus["success"]? "ALL SUCCESS" : eodProcessStatus["metadata"]["last_run_start_ts"] > eodProcessStatus["metadata"]["last_run_end_ts"] ? "RUNNING" : "SLEEPING"}</b>]:<br/>{JSON.stringify(eodProcessStatus, null, 2)}</pre>
            <br/><br/>

            {openRefreshConfirmDialog
              ?<ConfirmActionDialog open={true} cancelHandler={cancelConfirmRefreshHandler} saveHandler={confirmRefreshAction} 
                  title={"Confirm Cache Refresh!"} description={"Are you sure you want to proceed?"}></ConfirmActionDialog>
              :<></>
            }
            
            {openReloadConfirmDialog
              ?<ConfirmActionDialog open={true} cancelHandler={cancelConfirmReloadHandler} saveHandler={confirmReloadAction} 
                  title={"Confirm Intra Tick Data Reload!"} description={"Are you sure you want to proceed?"}></ConfirmActionDialog>
              :<></>
            }

            {openSwitchConfirmDialog
              ?<ConfirmActionDialog open={true} cancelHandler={cancelConfirmSwitchHandler} saveHandler={confirmSwitchAction} 
                  title={"Confirm Feed Switch!"} description={"Are you sure you want to manually switch the feed device?"}></ConfirmActionDialog>
              :<></>
            }

        {showLoginPopup?
                <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}  

          </div>
          
          :
          <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
          </div>
        }
        
        <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  
    </> 
      )
}