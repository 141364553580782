/**
 * Constants related to mp chart
 */

//colors 
export const CHART_COLORS={
    TPO_COLOR_DEFAULT:"#d8f6fa",
    TPO_COLOR_LIST:["#d8f6fa","#ebb098","#ebe698","#98ebe3","#e598eb"],
    INITIAL_TPO_COLOR:"#F8D806",  ///e6bd80
    SPECIAL_TPO_COLOR:"#0f172a",
    SPECIAL_TPO_BG:"#f8e28b",
    LIVE_PRICE_LINE:"#86b3e8", //2pix dotted
    FROZEN_PRICE_LINE:"#BDF797", //2pix dark to mark level selection boundary
    LIVE_PRICE_CONTAINER_BG:"#325ddb",
    IB_LINE:"#f8d806",
    IB_LINE_LOW:"#f8d866",
    VA_LINE:"#6f747f",
    VA_BG:"#1e2a46",
    VOLUME_BAR_ABOVE_96:"#A8CF45",
    VOLUME_BAR_ABOVE_70:"#719954",
    VOLUME_BAR_ABOVE_40:"#5B8558",
    VOLUME_BAR_ABOVE_28:"#47735A",
    VOLUME_BAR_BELOW_28:"#346359",
    VOLUME_NUMBER_COLOR:"#ffffff",
    VWAP:"#128E0B",
    VPOC:"#AB039D",
    HVN:"#865021",
    SELECTED_PROFILE:"#865021",
    SD_LINE:"#FA58AC",
    OI_SPIKE_NEG_BG:"#E64F12",
    OI_SPIKE_POS_BG:"#00FF84",
    CLOSING_VWAP_TPO:"#E0F6FC",
    VPOC_TEXT:"#ff77ff",
    VWAP_TEXT:"#7fff00",
    OPEN:"#3c75dc",         //will be used in right panel
    CLOSE:"#b0946b",        //will be used in right panel
    HIGH:"#49BD38",         //will be used in right panel
    LOW:"#EA1529",        //will be used in right panel
    CANDLE_GREEN:"#00FF00",
    CANDLE_RED:"#E64F12",
    SIGNAL_IS_BK:"#6A4C9B",
    SIGNAL_IB_BK:"#06572D",
    SIGNAL_IS_CC_BK:"#f9daf4",
    SIGNAL_IB_CC_BK:"#d8dc95",
    BUY_VOL_HIGHLIGHTED_COLOR:"#00FF00",
    SELL_VOL_HIGHLIGHTED_COLOR:"#F36B33",
    VPOC_BOX_COLOR:"#FFFC33",
    BOTTOM_TABLE_BK: "#092654", //"#163569",
    SELL_VOL_BK:"#FFC1C1",
    BUY_VOL_BK:"#84C7A7",
    OF_VOL_NUM_COLOR:"#FFFFFF",
    IS_RS_MARKER_COLOR:"#E64F12",
    IB_RB_MARKER_COLOR:"#00FF00",
    IS_RS_CC_MARKER_COLOR:"#f020ed",
    IB_RB_CC_MARKER_COLOR:"#9dfe1a",
    TEXT_RED:"#F36B33",
    TEXT_GREEN:"#00FF00",
    OF_VPOC_BOX_TEXT:"#FFFC33",
    OF_START_PROFILE_LINE:"#8B8A8A",
    OF_HIGH_LOW_TEXT:"#FFFFFF",
    OF_HIGH_RANGE_MARKER:"#E4D00A",
    OF_LOW_RANGE_MARKER:"#E4D00A",
    OF_NPOC:"#FFBF00",
    OF_NPOC_TEXT:"#FFEA00",
    OF_VPOC:"#FFFFFF",
    OF_VWAP:"#128E0B",
    MP_KRL_LABEL:"#FFBF00",
    // MP_NPOC:"#FFBF00",
    MP_NPOC:"#AB039D",
    MP_NPOC_LABEL:"#FFFFFF",
    OF_IB_LINE:"#FCC003",
    OF_DAY_HIGH_LOW:"#8B8A8A",
    HALF_BACK_BG:"#00FF84",
    HALF_BACK_LINE:"#A7EFA3",
    BUYER_LLT_MARKER_COLOR:"#00FF00",
    SELLER_LLT_MARKER_COLOR:"#F36B33",
    CANDLE_VWAP:"#128E0B",
    CANDLE_VPOC:"#AB039D",
    LC_OI:"#FF9933",
    LC_VOL_BAR:"#68B7DC",
    // LC_OPT_OI:"#FF9933",  //"#68B7DC",
    // LC_OPT_IV:"#0000FF",
    LC_OPT_OI:"#FF9933",  // "#68B7DC",
    LC_OPT_IV:"#FFFFFF",
    LC_OPT_DOI_BAR_GREEN:"#00FF00",
    LC_OPT_DOI_BAR_RED:"#FF0000",
    LC_OPT_SPIKE_AXIS:"#FFFFFF",
    LC_SPT_GWALL_HIGH:"#0DFAEE",
    LC_SPT_GWALL_LOW:"#0DFAEE",
    LC_SPT_UPPER_MVIZ:"#DCFF00",
    LC_SPT_LOWER_MVIZ:"#DCFF00",
    LC_SPT_GZERO:"#f0bfa0", //"#4F92F7",
    LC_SPT_PREM_BAR:"#68B7DC",
    LC_DOIX_CALL:"#01FF00",
    LC_DOIX_PUT:"#FF00FF",
    LC_DOIX_NET:"#FF0000",
    LC_DOI_CALL:"#01FF00",
    LC_DOI_PUT:"#FF00FF",
    LC_DOI_NET:"#FFFFFF",
    LC_DOI_NET_BAR:"#000000",  // #012e5a
    LC_DAY_START_LINE:"#8B8A8A",
    CANDLE_BAR_POSITIVE:"#00FF00",
    CANDLE_BAR_NEGATIVE:"#FF0000",
    LC_BOTTOM_BK:"#092654",  // "#163569",  // "#0d203f"
    CANDLE_ROLLING_COT:"#FFFFFF",
    LC_BOTTOM_BORDER:"#FFFFFF",
    LC_DOI_AXIS:"#FFFFFF",
    LC_DOIX_AXIS:"#FFFFFF",
    MODAL_TEXT_ENABLED:"#FFFFFF",
    MODAL_TEXT_DISABLED:"#808080",

}

export const CADNLE_ROLLING_COT_COUNT=10;



export const CHART_FONT_DEFAULT="600 12px sans-serif";
export const CHART_FONT_ITALIC_DEFAULT="italic 600 12px sans-serif";
export const CHART_FONT_OF_VOL_DEFAULT="600 12px sans-serif";
export const CHART_FONT_BOLD_OF_VOL="900 14px sans-serif";
export const OF_TABLE_FONT_DEFAULT="600 14px sans-serif";
export const SPECIAL_TPO_CHAR_FONT="600 18px sans-serif";

export const DIMENSIONS={

}

export const VP_TYPE={
    NONE:"none",
    ALONE:"alone",
    BACKGROUND:"background",
    ASIDE:"aside"
}

export const VA_TYPE={
    VOLUME_BASED:"vol",
    TPO_BASED:"tpo",
    HYBRID:"hybrid"
}

export const CANDLE_BOTTOM_VIEW_TYPE={
    VOLUME:"vol",
    COT:"cot",
    DOI:"doi",
 }

 export const MAIN_PANEL_ON_BOTTOM_PANEL={
    SHOW:"show",
    HIDE:"hide"
 }

 export const BOTTOM_PANEL_VIEWS={
    SAME_CONTAINER:"same_container",    //main panel overlap
    HIDDEN:"hidden",                    //hide the bottom panel1
    TOP_LAYER:"top_payer"               //show on top of main panel
 }

 export const INFO_PANEL_VIEWS={
    EXPANDED:"expanded",    
    COLLAPSED:"collapsed",                  
 }


export const CHART_SCREEN_TYPE={
    FULL_CHART:0,
    TWO_COLUMNS:1,
    TWO_ROWS:2,
    GRID:3,
    THREE_COLUMNS_ONE_ROW:4,
    THREE_COLUMNS_TWO_ROWS:5
}

export const CHART_TYPE={
    MARKET_PROFILE:"mp",
    LINE_CHART:"lc",
    BAR_CHART:"bc",
    HV_CHART:"hv",
    OF_CHART:"of"
}

export const RIGHT_PANEL_CONTAINER_WIDTH=220;

export const OF_CHART_LINE_WIDTH={
    VWAP:3,
    VPOC:3,
    NPOC:1.5,
    DAILY_HIGH_LOW:1.5,
    IB:1.5,
    OI:3
}

export const LC_CHART_LINE_WIDTH={
    VWAP:2,
    VPOC:2,
    NPOC:1.5,
    DAILY_HIGH_LOW:1.5,
    IB:1.5,
    OI:2,
    IV:1,
    GWALL:1.5,
    MVIZ:1.5,
    GZERO:2,
    CALLOI:2,
    PUTOI:2,
    NETOI:2,
    DAY_START_LINE:1,
    ROLLING_COT:2,
    DOIX_AXIS:1,
    DOI_AXIS:1

    
}

export const LINE_DASHED_LIST={
    GZERO:[10,4],
    DOIX_AXIS:[5,3],
    DOI_AXIS:[5,3]
}
export const OF_NPOC_MIN_PROFILE_DIFFERENCE=2;
export const MP_NPOC_MIN_PROFILE_DIFFERENCE=2;

export const KRL_CATEGORIES={
    ANCHORED_VWAP:"ANCHORED_VWAP",
    ANCHORED_VPOC:"ANCHORED_VPOC",
    A_SINGLES:"A_SINGLES",
    HVN:"HVN",
    FA:"FA",
    MULTIDAY_FA:"MULTIDAY_FA",
    NPOC:"NPOC",
}

export const KRL_CATEGORIES_COLORS={
    ANCHORED_VWAP:"#128E0B",
    ANCHORED_VPOC:"#AB039D",
    A_SINGLES:"#AAAAAA",
    HVN:"#865021",
    FA:"FF5555",
    MULTIDAY_FA:"#FF9955",
    NPOC:"#AB039D",
}

export const KRL_CATEGORIES_WIDTH={
    ANCHORED_VWAP:3,
    ANCHORED_VPOC:3,
    A_SINGLES:3,
    HVN:3,
    FA:3,
    MULTIDAY_FA:3,
    NPOC:3,
}

export const KRL_CATEOGRY_TYPE={
    STRAIGHT_LINE:"line",
    DASHED_LINE:"dashed_line",
    DOTTED_LINE:"dotted_line",
    RECTANGLE:"rectangle",
    HOLLOW_RECTANGLE:"hollow_rectangle"
}

export const KRL_DEFAULT_COLOR="#FFFFFF";

export const KRL_STYLING_CATEGORY={
    category_default:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:1.5
    },
    category_npoc:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:3
    },
    style_line:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:1.5
    },
    style_dotted_line:{
        type:KRL_CATEOGRY_TYPE.DOTTED_LINE,
        width:1.5
    },
    style_dashed_line:{
        type:KRL_CATEOGRY_TYPE.DASHED_LINE,
        width:1.5
    },
    style_thick_line:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:3
    },
    style_dotted_thick_line:{
        type:KRL_CATEOGRY_TYPE.DOTTED_LINE,
        width:3
    },
    style_dashed_thick_line:{
        type:KRL_CATEOGRY_TYPE.DASHED_LINE,
        width:3
    },
}


export const STYLING_CATEGORY_LIST=[
   {
        name:"Line",
        value:"style_line"
    },
    {
        name:"Dotted Line",
        value:"style_dotted_line"
    },
    {
        name:"Dashed Line",
        value:"style_dashed_line"
    },
    {
        name:"Thick Line",
        value:"style_thick_line"
    },
    {
        name:"Thick Dotted Line",
        value:"style_dotted_thick_line"
    },
    {
        name:"Thick Dashed Line",
        value:"style_dashed_thick_line"
    },
    
]

export const OF_MARKER_STYLING_CATEGORY={
    npoc:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:1.5
    },
    day_high_low:{
        type:KRL_CATEOGRY_TYPE.STRAIGHT_LINE,
        width:2
    },
    ib_high_low:{
        type:KRL_CATEOGRY_TYPE.DASHED_LINE,
        width:3
    },


    
}
export const MAX_PROFILE_TO_LOAD=15;
export const CHART_FACTOR_MP=2;
export const CHART_FACTOR_OF=1.5;
export const CHART_FACTOR_CC=1.5;

export const CUSTOM_KRL_TYPES=[
        {
            name:"Custom",
            value:"CUSTOM_KRL"
        },
        {
            name:"VPOC",
            value:"CUSTOM_KRL_VPOC"
        },
        {
            name:"VWAP",
            value:"CUSTOM_KRL_VWAP"
        },
        {
            name:"TPOC",
            value:"CUSTOM_KRL_TPOC"
        },
        {
            name:"DH",
            value:"CUSTOM_KRL_DH"
        },
        {
            name:"DL",
            value:"CUSTOM_KRL_DL"
        },
        {
            name:"IBH",
            value:"CUSTOM_KRL_IBH"
        },
        {
            name:"IBL",
            value:"CUSTOM_KRL_IBL"
        },
        {
            name:"VAH",
            value:"CUSTOM_KRL_VAH"
        },
        {
            name:"VAL",
            value:"CUSTOM_KRL_VAL"
        },
    ]

    export const CUSTOM_KRL_TYPES_NON_VP=[
        {
            name:"Custom",
            value:"CUSTOM_KRL"
        },
        {
            name:"TPOC",
            value:"CUSTOM_KRL_TPOC"
        },
        {
            name:"DH",
            value:"CUSTOM_KRL_DH"
        },
        {
            name:"DL",
            value:"CUSTOM_KRL_DL"
        },
        {
            name:"IBH",
            value:"CUSTOM_KRL_IBH"
        },
        {
            name:"IBL",
            value:"CUSTOM_KRL_IBL"
        },
        {
            name:"VAH",
            value:"CUSTOM_KRL_VAH"
        },
        {
            name:"VAL",
            value:"CUSTOM_KRL_VAL"
        },
    ]

 export const CUSTOM_KRL_ANCHORED_MAX_DATE_DIFFERENCE=65;   

 export const CHART_VISIBILITY_MATRIX={"vol":true,"vpoc":true,"vwap":true,"oi":true,"doi":true,"iv":true,
    "gwall_high":true,"gwall_low":true,"gzero":true,"mviz_high":true,"mviz_low":true,"prem":true,
    "cot":true,"rolling_cot":true,"call_oi":true,"put_oi":true,"net_oi":true
  }

  //values in percentage w.r.to main panel height
  export const BOTTOM_PANEL_HEIGHT_VAL={
    "min":5,
    "max":60,
    "CANDLE_CHART":15,
    "FUTURE_CHART":15,
    "OPTIONS_CHART":15,
    "SPECTRUM_CHARTS":10,
    "NTM_OI_CHANGE_CHART":10,
    "OI_CHANGE_CHART":10
  }

