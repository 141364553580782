
/**
 * Common constants to be used throughout the project
 */
export const CONSTANTS = {
    FIXED_HEADER_HEIGHT:32,
    FIXED_FOOTER_HEIGHT:32,
    DASHBOARD_HEADER_HEIGHT:48,
    FIXED_RIGHT_PANEL_WIDTH:50
}

export const TEXT_MSGS={
    NETWORK_ERROR_MSG:"Oops! Looks like there is a temporary outage at our end. Please try again in few minutes!",
    MERGE_ERROR:"You can merge only the adjacent profiles.",
    UNMERGE_NOT_ALLOWED:"Monthly/Yearly Profile unmerge is not supported right now.",
    USER_SETTINGS_SAVED:"User details updated successfully.",
    SUBSCRIPTION_POPUP_TITLE:"Premium",
    SUBSCRIPTION_PRO_POPUP_TITLE:"Expert or higher plan",
    SUBSCRIPTION_POPUP_DESC:"This feature is available only to our premium users right now.",
    LIVE_PANEL_NOT_SUPPORTED:"Live panel only supported in full chart view.",
    LIVE_PANEL_NOT_SUPPORTED_LC:"Live panel only supported for market profile chart",
    MARKET_NOT_LIVE:"Market is not live.",
    POSITION_SAVED_SUCCESS:"Position is saved successfully.",
    POSITION_DELETED_SUCCESS:"Position is deleted successfully.",
    POSITION_NOTHING_TO_SAVE:"There is no non zero position to save.",
    BOOKMARK_SAVED_SUCCESS:"Bookmark is saved successfully.",
    CHART_SAVED_SUCCESS:"Saved successfully.",
    CHART_SAVE_NOT_ALLOWED_LIVE_COMPOSITE:"Can't save the chart. Please unmerge the live composite before saving the chart.",
    BOOKMARK_SAVE_NOT_ALLOWED_LIVE_COMPOSITE:"Can't save the chart. Please unmerge the live composite before saving the chart.",
    BOOKMARK_DELETED_SUCCESSFULLY:"Bookamrk deleted.",
    BOOKMARK_OVERWRITTEN_TITLE:"Overwrite bookmark",
    BOOKMARK_OVERWRITTEN_DESC:"Bookmark is not up to date. Do you want to overwrite it as per the current chart state?",
    CLEAR_AUTOSAVED_TITLE:"Clear Saved State",
    CLEAR_AUTOSAVED_BOOKMARK:"Do you want to clear the saved chart state?",
    CLEAR_AUTOSAVED_BOOKMARK_SUCCESS:"Chart state is reset successfully",
    USER_DETAILS_UPDATED_SUCCESSFULLY:"Contact details updated successfully",
    PASSWORD_UPDATED_SUCCESSFULLY:"Password is updated successfully",
    PREMIUM_PLUS_UPGRADE_MSG:"Upgrade to a Expert or Elite plan to get access to advanced features and gain edge in your trading with our proprietary Market and Inventory Visualizations and Spectrum charts.",
    PREMIUM_PLUS_UPGRADE_ORDERFLOW_MSG:"Upgrade to an Elite plan to get access to our unique Orderflow charts with proprietary tools and gain edge in your trading.",
    MAX_ALLOWED_STRIKES:"Maximum 3 strikes can be selected.",
    CHART_DATA_NOT_AVAILABLE:"Chart data is not available",
    ERROR_MSG_TABLE_NO_DATA:"Data not available",
    MULTICHART_NOT_SUPPORTED:"Multichart is not supported on small screen devices",
    SET_LEVEL_TITLE:"Freeze Levels",
    SET_LEVEL_DESC:"This action will load a fresh chart that shows profiles overlapping the selected region only.\nDo you want to freeze the levels and plot the chart?",
    DELETE_LEVEL_TITLE:"Delete selected level",
    DELETE_LEVEL_DESC:"Deleting the level selection may reset the current chart back to normal.\n Do you want to continue?",
    LINE_CHART_LIVE_DATA_DELAY:"Please wait 2 minutes for the live data to start flowing..",
    MP_CHART_STOCK_RESOURCE_NOT_SUBSCRIBED_DESC:"Please subscribe to Stocks FnO to access the Stocks MP chart",
    OF_CHART_INDEX_RESOURCE_NOT_SUBSCRIBED_DESC:"Please subscribe to Orderflow for Index FnO or  Orderflow for All FnO to access Index Orderflow Chart",
    OF_CHART_STOCKS_RESOURCE_NOT_SUBSCRIBED_DESC:"Please subscribe to Orderflow for All FnO to access Stocks Orderflow Chart",
    MP_STOCKS_ADD_ONS_NOT_SELECTED:"Please select the Stocks FnO to upgrade your existing Premium subscription",
    OF_STOCK_INDEX_ADD_ONS_NOT_SELECTED:"Please select Orderflow for Index or Orderflow for All FnO to upgrade your existing Premium + subscription",
    DELETE_KRL_TITLE:"Delete KRL",
    DELETE_KRL_DESC:"You sure you wish to delete KRL at Level: ",
    DELETE_KRL_DESC_ANCHORED:"You sure you wish to delete the selected Anchored KRL?",
    SAVE_EDIT_BOOKMARK_NOT_ALLOWED_FROM_OTHER_USER:"Oops! you are not allowed to change data in someone else's bookmark.",
    BROKER_AUTHENTICATION_SUCCESS:"Broker integration is done successfully",
    MULTICHART_3_COLS_NOT_SUPPORTED:"Unable to render 3 column view due to smaller screen size",
    LOGIN_POPUP_MSG:"Session expired. Please login again to continue.",
    INTERNET_ERROR_MESSAGE:"Oops! Looks like you went offline. Please check your internet connection and try again.",
}

export const PREMIUM_UPGRADE_MSGS_LIST = [
    "Go Premium, Gain Live Data - Right Now!",
    "Time is Money - Don't Settle for Delays. Upgrade Now!",
    "Stay Ahead of the Curve with Premium Live Data!",
    "Why Wait 15 Minutes? Get Real-Time Data with Premium!",
    "Discover More Features, Discover More Success - Upgrade Now!",
    "Leave Delay Behind. Enter the Fast Lane with Premium!",
    "Your Trading Deserves More - Go Premium Today!",
    "Experience the Best in Trading - Become a Premium Member!",
    "Don't Settle for Less. Unleash Full Potential with Premium!",
    "Live Data, Advanced Tools, More Wins - Upgrade Today!",
    "Why Limit Yourself? Enjoy Unlimited Access with Premium!",
    "Trade Like a Pro, in Real-Time. Go Premium Now!",
    "Never Miss a Trading Opportunity Again - Upgrade Now!",
    "Get Spectrum on Your Side - Upgrade to Premium!",
    "Catch Every OI Change Live - Go Premium Now!",
    "Fine-Tune Your Trades with Gamma Charts - Upgrade Today!",
    "Work with Gamma Like a Pro - Upgrade to Premium!",
    "Experience Real-Time OI Changes - Go Premium Now!",
    "Live Data, Spectrum Access, More Success - Upgrade Today!",
    "Discover the Power of Live Gamma - Upgrade to Premium!",
    "Unlock Real-Time OI Changes - Become a Premium Member Today!",
    "Get an Edge with Live Spectrum Access - Upgrade Now!",
    "Tune Into Live Market Changes with Premium - Upgrade Today!",
    "Experience Full Spectrum of Trading - Go Premium Now!",
    "Stay on Top of Live OI Changes - Upgrade to Premium!",
    "Trade Smarter with Gamma Charts - Go Premium Now!",
    "Harness the Power of Live Data & Spectrum - Upgrade Today!",
]

export const USER_ROLE_FIELDS={
    BASIC:'basic',
    PRO:'pro_1',
    PRO2:'pro_2',
    ADMIN:'admin',
    FAST_DATA:'fast_data',
    ORDERFLOW:'orderflow'
}

export const USER_RESOURCES_LIST={
    MP_INDEX:'index',
    TBT_FEED:"tbt_data",
    MP_STOCKS:"mp_stocks",
    OF_INDEX:"orderflow_index",
    OF_STOCKS:"orderflow_stocks"

}

export const CHART_RELOAD_TIME={
    SLOW_DATA:60*1000,  //1min  reload
    FAST_DATA:1100,     //1.1sec reload
    LC_DATA:10*1000,    // 25 seconds reload - make sure to reload at least twice in same minute - slow polling due to performance issue
    POLLING_TIME:5*60*1000, //5mins
    // POLLING_TIME:10*1000,
    OPTIONS_DATA:8*1000,     //8 seconds - it is a slow process approx 0.6 sec
    GREEKS_DATA:5*1000,      //5 seconds - greeks data is takes 2 secs to process normally - with caching it takes less than a second - needs further optimisation
    INDEX_DATA_FAST:4*1000,  //4 seconds - index data actually can be 1 second refresh too - takes few ms to get record.
    TICKER_DATA_FAST:2000,   //locally it takes more than 1 sec for update sometimes - causing cancellation issues - will do slow polling till issue fix.
    INDEX_DATA_SLOW:70*1000,
    STRIKES_RELOAD_TIME:30*60*1000, //30mins
    // STRIKES_RELOAD_TIME:10*1000 //test 10sec
    FAST_POLLING:60*1000    //1min polling for market status check  
}


export const MP_CHART_RELOAD_TIME={
    SLOW_DATA:70000,      //70 seconds  reload
    MINWISE_DATA:30000,      //50 seconds  reload
    FAST_DATA:1100,         //1.1sec reload
    POLLING_TIME:5*60*1000, //5mins polling
    // POLLING_TIME:30*1000, //5mins polling
    FAST_POLLING:60*1000,   //1min polling for market status check    
    KRL_POLLING_TIME:5*60*1000, //5mins polling  
    FAST_KRL_POLLING_TIME:1*60*1000, //1mins polling  
}


export const RESEND_TIMER_VAL=120; //120 seconds

export const SNACKBAR_AUTO_HIDE_DURATION=5000;  //5seconds
export const SNACKBAR_AUTO_HIDE_DURATION_SHORT=2000;  //5seconds
export const MIN_WIDTH_3_COLS=1920;
export const  REFRESH_POLLING_INTERVAL=2000;

export const TIME_FRAME_VALUES={
    daily:"daily",
    weekly:"weekly",
    weekly_series:"weekly_s",
    monthly:"monthly",
    monthly_series:"monthly_s",
    yearly:"yearly_d"
}

export const PROFILE_VIEW={
    tpo_only:"TPO_ONLY",
    vol_tpo_side:"VOL_TPO_SIDE",
    only_vol:"VOL_ONLY",
    tpo_on_vol:"TPO_ON_VOL",
 }

//  export const CANDLE_BOTTOM_COL={
//     vol:"vol",
//     cot:"cot",
//     doi:"doi",
//  }

 export const AUTO_CENTER_CONFIG={
    DELAY:1000,  //throttle/debounce delay
    THROTTLING:1,
    DEBOUCE:2,
    USE_THROTTLE_OR_DEBOUNCE:1,     //1 for throttling based auto center, 2 for debounce based
    DELTA_X:10,                     //represent x translation required to trigger auto center
    DELTA_Y:20                      //less than this value required for auto centering otherwise user is vertically scrolling so dont trigger auto center here
 }

 export const WATERMARK_CONFIG={
    TEXT:"© 2024 Vtrender Charts",
    TABLE_TEXT:"© 2024 Vtrender Charts",
    LC_TEXT:"© 2024 Vtrender Charts"
 }

 // allowed roles are the selected and above roles (for hierarchy considerations)
 // admin is not ideally hierarchial, but some pages are xclusive only to admin user
 export const ALL_USER_ROLES=["basic", "fast_data", "pro_1", "pro_2", "admin"];
 export const ALLOWED_ROLES_ALL=ALL_USER_ROLES;
 export const ALLOWED_ROLES_ADMIN=["admin"] ;
 export const ALLOWED_ROLES_PRO_1=["pro_1", "pro_2"] ;
 export const ALLOWED_ROLES_PRO_2=["pro_2"] ;
 export const ALLOWED_ROLES_FAST_DATA=["fast_data", "pro_1", "pro_2"] ;
 export const ALLOWED_ROLES_ORDERFLOW=["pro_1"] ;
 export const RESOURCE_LIST={
    ORDERFLOW:["orderflow_index","orderflow_stocks"]
 }

export const  LOCALE_STRING="en-IN" ;

export const BOOKMARKT_CATEGORY_DATA=[
    {
        name:"New",
        value:"New"
    },
    {
      name:"Options",
      value:"Options"
    },
    {
      name:"Futures",
      value:"Futures"
    },
  ]

  export const BOOKMARK_NAME_MAX_LENGTH=30;
  export const BOOKMARK_CATEGORY_MAX_LENGTH=30;
  export const BOOKMARK_CATEGORY_AUTOSAVE="AUTO_SAVED";
  export const BOOKMARK_AUTO_SAVE_DURATION=3*60*1000;             //3mins
  export const ERROR_CODE_BOOKMARK_ALREADY_MODIFIED=307;

  export const DateFormat = 'DD-MM-YYYY';
  
  const ITEM_HEIGHT = 26;
  const ITEM_PADDING_TOP = 8;
  export const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 80,
        autoFocus: false
      },
    },
  };
 
  export const NO_OF_DAYS=[
    {
        key:"Intra Only",
        value:-1
    },
    {
        key:1,
        value:1
    },
    {
        key:2,
        value:2
    }, 
    {
        key:3,
        value:3
    },    
    {
        key:4,
        value:4
    },
    {
        key:5,
        value:5
    },    
    {
        key:6,
        value:6
    },    
]

export const HV_CHART_V_TYPES=[
    {
        key:"C2C Log Ratio Std Dev",
        value:"c2c_sigma"
    },
    {
        key:"C2O Log Ratio Std Dev",
        value:"c2o_sigma"
    },
    {
        key:"Gap Std Dev",
        value:"gap_sigma"
    },
    {
        key:"Day Range Std Dev",
        value:"o2c_sigma"
    },
    {
        key:"C2C Diff Std Dev",
        value:"c2cd_sigma"
    },
    {
        key:"C2C Log Ratio Mean",
        value:"c2c_mean"
    },
    {
        key:"C2O Log Ratio Mean",
        value:"c2o_mean"
    },
    {
        key:"Gap Mean",
        value:"gap_mean"
    },
    {
        key:"Day Range Diff Mean",
        value:"o2c_mean"
    },
    {
        key:"C2C Diff Mean",
        value:"c2cd_mean"
    }
]

export const HV_CHART_PLOT_TYPES=[
    {
        key:"ATR 5",
        value:"5_atr"
    },
    {
        key:"ATR 20",
        value:"20_atr"
    },
    {
        key:"ADR 5",
        value:"5_adr"
    },
    {
        key:"ADR 20",
        value:"20_adr"
    },
]

export const HV_OI_TYPES=[
    {
        key:"COI",
        value:"coi"
    },
    {
        key:"dCOI",
        value:"dcoi"
    },
    {
        key:"Volume",
        value:"vol"
    },
]

export const SNAKE_CHART_PLOT_TYPE=[
    {
        key:"Multi Strike Straddle Price",
        value:"pair_price"
    },
    {
        key:"Multi Strike Straddle OI",
        value:"oi"
    },
    {
        key:"Multi Strike IV and Writers' Cash",
        value:"iv_wcash"
    },
    {
        key:"Synthetic Futures",
        value:"synth_fut"
    },
    
]

// this menu is for admin and pro_2
export const BAR_CHART_PLOT_TYPE=[
    {
        key:"Strike Available Intra Value - Split",
        value:"_gcash"
    },
    {
        key:"Strike Available Total Value - Split",
        value:"_dgcash"
    },
    {
        key:"Writers' Cash - Split",
        value:"_wcash"
    },
    {
        key:"OI - Split",
        value:"_oi"
    },
    {
        key:"OI Change - Split",
        value:"_doi"
    },
    {
        key:"Volume - Split",
        value:"_vol"
    },
    {
        key:"Strike Available Intra Value - Net",
        value:"net_gcash"
    },
    {
        key:"Strike Available Total Value - Net",
        value:"net_dgcash"
    },
    {
        key:"Strike Available Intra Value - Sum",
        value:"total_gcash"
    },
    {
        key:"Strike Available Total Value - Sum",
        value:"total_dgcash"
    },
    {
        key:"Put Call Ratio",
        value:"pcr"
    },
    {
        key:"OI Difference - (Call OI - Put OI)",
        value:"net_oi"
    },
    {
        key:"Straddle OI - Sum",
        value:"total_oi"
    },
    {
        key:"Straddle Value",
        value:"straddle"
    },
    {
        key:"OI Pain Value",
        value:"oi_pain"
    },
    {
        key:"IV",
        value:"iv"
    },
]

// this is Options Strikewise OI Visualization menu for premium users
export const BAR_CHART_OI_PLOT_TYPE=[
    {
        key:"OI - Split",
        value:"_oi"
    },
    {
        key:"OI Change - Split",
        value:"_doi"
    },
    {
        key:"OI Difference - (Call OI - Put OI)",
        value:"net_oi"
    },
    {
        key:"Straddle OI - Sum",
        value:"total_oi"
    },
    {
        key:"Volume - Split",
        value:"_vol"
    },
    {
        key:"OI Pain Value",
        value:"oi_pain"
    },
    {
        key:"IV",
        value:"iv"
    },
]

// this menu is for admin
export const BAR_CHART_ADMIN_PLOT_TYPE=[
    {
        key:"Inventory Resistance Estimate - Sum",
        value:"total_dgcash"
    },
    {
        key:"Inventory Resistance Estimate - Net",
        value:"net_dgcash"
    },
    {
        key:"Inventory Resistance Estimate - Split",
        value:"_dgcash"
    },
    {
        key:"Gamma Exposure Value - Sum",
        value:"total_gcash"
    },
    {
        key:"Gamma Exposure Value - Net",
        value:"net_gcash"
    },
    {
        key:"Gamma Exposure Value - Split",
        value:"_gcash"
    },
    // {
    //     key:"Inventory Value - Sum",
    //     value:"total_ngex"
    // },
    // {
    //     key:"Inventory Value - Split",
    //     value:"_ngex"
    // },
    {
        key:"Gamma Value - Sum",
        value:"total_igex"
    },
    {
        key:"Gamma Value - Split",
        value:"_igex"
    },
    {
        key:"Writers' Cash - Split",
        value:"_wcash"
    },
    {
        key:"GEX Curves",
        value:"gex_curves"
    },
]

// this is Options Strikewise Market Inventory Visualization menu, mainly for premium plus users
export const BAR_CHART_MI_PLOT_TYPE=[
    {
        key:"Gamma Exposure Value - Sum",
        value:"total_gcash"
    },
    {
        key:"Gamma Exposure Value - Split",
        value:"_gcash"
    },
    // {
    //     key:"Inventory Value - Sum",
    //     value:"total_ngex"
    // },
    // {
    //     key:"Inventory Value - Split",
    //     value:"_ngex"
    // },
    {
        key:"Gamma Value - Sum",
        value:"total_igex"
    },
    {
        key:"Gamma Value - Split",
        value:"_igex"
    },
    {
        key:"Writers' Cash - Split",
        value:"_wcash"
    },
]


export const MARKET_OPEN_TIME="09:15";
export const MARKET_CLOSE_TIME="15:30";

export const TABLE_TYPES={
    INDEX_COMPONENTS:"INDEX_COMPONENTS",
    INDEX_FUT_COMPONENTS:"INDEX_FUT_COMPONENTS",
    OPTCHAIN:"OPTCHAIN",
    INDEX_TICKER:"INDEX_TICKER"
}

export const INDEX_TICKER_COLUMNS_LIST={
    spot_3D_comp_va:"spot_va",
    spot_open_high_low:"spot_open_high_low",
    spot_open_change_ltp:"spot_change",
    spot_timestamp:"spot_timestamp",
    fut_tr_atr:"fut_tr_atr",
    fut_open_high_low:"fut_open_high_low",
    hv_high_low:"hv_high_low",
    pe_chg_ltp:"pe_chg_ltp",
    fut_timestamp:"fut_timestamp",
    fut_prem_group:"fut_prem_group",
    prem_stats:"prem_stats",
    max_min_prem:"max_min_prem",
    sma_wma:"sma_wma",
    idx_ad:"idx_ad"
}

export const INDEX_COMPONENTS_COLUMNS_LIST={
    high:"high",
    low:"low",
    ltp:"ltp",
    change:"change",
    change_percent:"change_percent",
    tr:"tr",
    atr:"atr",
    avg_daily_vol:"avg_daily_vol",
    max_vol:"max_vol",
    wt_percent:"wt_percent",
    idx_change_pts:"idx_change_pts",
    change_ltp:"change_ltp",
    open_high_low:"open_high_low",
    value_area:"value_area",
    spot_chg:"spot_chg",
    netidxpts:"netidxpts",
    vol:"vol",
    tbcot:"tbcot",
    vol_vs_avg_vol:"vol_vs_avg_vol",
    percent_vol_tf_stats:"perc_vol_tf_stats",
    percent_avg_vol:"perc_avg_vol",
    max_daily_stats:"max_daily_stats",

 }

export const OPTCHAIN_COLUMNS_LIST={
    calls_max_min_oi:"min_max_oi",
    calls_doi_oi:"calls_doi_oi",
    calls_iv:"iv",
    calls_vol:"vol",
    calls_vpoc_vwap:"vpoc_vwap",
    calls_chg_ltp:"chg_ltp",
    puts_max_min_oi:"min_max_oi",
    puts_doi_oi:"calls_doi_oi",
    puts_iv:"iv",
    puts_vol:"vol",
    puts_vpoc_vwap:"vpoc_vwap",
    puts_chg_ltp:"chg_ltp",
    oi_spike:"oi_spike",
    straddle_pcr:"straddle_pcr",
    strike_pain:"strike_pain",
    synthetic_fut:"synthetic_fut"
}


export const SUBSCRIPTION_PLAN_LIST=[
    {
        key:"PREMIUM_MONTHLY_1",
        value:"PREMIUM_MONTHLY_1"
    },
    {
        key:"PREMIUM_MONTHLY_6",
        value:"PREMIUM_MONTHLY_6"
    },
    {
        key:"PREMIUM_MONTHLY_12",
        value:"PREMIUM_MONTHLY_12"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_1",
        value:"PREMIUM_PLUS_MONTHLY_1"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_6",
        value:"PREMIUM_PLUS_MONTHLY_6"
    },
    {
        key:"PREMIUM_PLUS_MONTHLY_12",
        value:"PREMIUM_PLUS_MONTHLY_12"
    },  
    {
        key:"PREMIUM_STOCKS_MONTHLY_1",
        value:"PREMIUM_STOCKS_MONTHLY_1"
    },
    {
        key:"PREMIUM_STOCKS_MONTHLY_6",
        value:"PREMIUM_STOCKS_MONTHLY_6"
    },
    {
        key:"PREMIUM_STOCKS_MONTHLY_12",
        value:"PREMIUM_STOCKS_MONTHLY_12"
    }, 
    {
        key:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_1",
        value:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_1"
    },
    {
        key:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_6",
        value:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_6"
    },
    {
        key:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_12",
        value:"PREMIUM_PLUS_ORDERFLOW_MONTHLY_12"
    },   
    {
        key:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_1",
        value:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_1"
    },
    {
        key:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_6",
        value:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_6"
    },
    {
        key:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_12",
        value:"PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_12"
    },  
    {
        key:"ORDERFLOW_MONTHLY",
        value:"ORDERFLOW_MONTHLY"
    },
    {
        key:"ORDERFLOW_STOCKS_MONTHLY",
        value:"ORDERFLOW_STOCKS_MONTHLY"
    }
]   


export const PAYMENT_STATUS={
    SUCCESS:"success",
    CANCELLED:"cancelled",
    FAILURE:"failure",
    ERROR:"error"
}

export const MIN_CHART_WIDTH="630px";
export const MIN_SNAKE_CHART_WIDTH="1024px";
export const MIN_HV_CHART_WIDTH="1210px";
export const MIN_BAR_CHART_WIDTH="1024px";
export const MIN_TABLE_CONTAINER_WIDTH="1180px";


export const INSTRUMENT_POLLING_TIME=5*60*1000;         //5mins


/**
 * OF Related constants
 */

export const OF_CHART_RELOAD_TIME={
    SLOW_DATA:70000,      //70 seconds  reload
    FAST_DATA:1100,         //1.1sec reload
    POLLING_TIME:5*60*1000, //5mins polling
    // POLLING_TIME:30*1000, //5mins polling
    FAST_POLLING:60*1000    //1min polling for market status check        
}

export const OF_TIME_FRAME=[
    {
      key:1,
      value:"1",
      displayText:"1 min"
    },
    {
        key:3,
        value:"3",
        displayText:"3 mins"
      },
      {
        key:5,
        value:"5",
        displayText:"5 mins"
      },
      {
        key:15,
        value:"15",
        displayText:"15 mins"
      },
      {
        key:30,
        value:"30",
        displayText:"30 mins"
      },
  
  ]
export const OF_TIME_FRAME_VALUES={
    tf_1_min:"1",
    tf_3_min:"3",
    tf_5_min:"5",
    tf_15_min:"15",
    tf_30_min:"30",
}

export const DEFAULT_OF_TIME_FRAME="3";

export const DEFAULT_CANDLE_TIME_FRAME="3";

export const OF_HIGHLIGHT_CONSTANT={
    VOL_THRESHOLD:5,
    BUY_HIGHLIGHT_MIN_RATIO:3,
    SELL_HIGHLIGHT_MIN_RATIO:3
}


export const SUBSCRIPTION_PLANS_PRICE={
    "monthly":{
        "premium":{
            "base":300,
            "base_gst":54,
            "stocks":400,
            "stocks_gst":72,
        },
        "premium_plus":{
            "base":1000,
            "base_gst":180,
            "orderflow":3000,
            "orderflow_gst":540,
            "orderflow_stocks":5000,
            "orderflow_stocks_gst":900,
        }
    },
    "quarterly":{
        "premium":{
            "base":855,
            "base_gst":162,
            "stocks":1995,
            "stocks_gst":378,
        },
        "premium_plus":{
            "base":2850,
            "base_gst":540,
            "orderflow":11400,
            "orderflow_gst":2160,
            "orderflow_stocks":17100,
            "orderflow_stocks_gst":3240,
        }
    },
    "half_yearly":{
        "premium":{
            "base":1911,
            "stocks":4460,
        },
        "premium_plus":{
            "base":6372,
            "orderflow":25488,
            "orderflow_stocks":38232,
        }

    },
    "annually":{
        "premium":{
            "base":2548,
            "stocks":5947,
        },
        "premium_plus":{
            "base":8496,
            "orderflow":33984,
            "orderflow_stocks":50976,
            
        }
    }
}

export const SUBSCRIPTION_PLANS={
    PREMIUM_MONTHLY_1_OLD:"PREMIUM_MONTHLY_1",
    PREMIUM_MONTHLY_6_OLD:"PREMIUM_MONTHLY_6",
    PREMIUM_MONTHLY_12_OLD:"PREMIUM_MONTHLY_12",
    PREMIUM_MONTHLY_1:"PREMIUM_INDEX_MONTHLY_1",
    PREMIUM_MONTHLY_3:"PREMIUM_INDEX_MONTHLY_3",
    PREMIUM_MONTHLY_6:"PREMIUM_INDEX_MONTHLY_6",
    PREMIUM_MONTHLY_12:"PREMIUM_INDEX_MONTHLY_12",
    PREMIUM_PLUS_MONTHLY_1:"PREMIUM_PLUS_MONTHLY_1",
    PREMIUM_PLUS_MONTHLY_3:"PREMIUM_PLUS_MONTHLY_3",
    PREMIUM_PLUS_MONTHLY_6:"PREMIUM_PLUS_MONTHLY_6",
    PREMIUM_PLUS_MONTHLY_12:"PREMIUM_PLUS_MONTHLY_12",
    PREMIUM_STOCKS_MONTHLY_1:"PREMIUM_INDEX_STOCKS_MONTHLY_1",
    PREMIUM_STOCKS_MONTHLY_3:"PREMIUM_INDEX_STOCKS_MONTHLY_3",
    PREMIUM_STOCKS_MONTHLY_6:"PREMIUM_INDEX_STOCKS_MONTHLY_6",
    PREMIUM_STOCKS_MONTHLY_12:"PREMIUM_INDEX_STOCKS_MONTHLY_12",
    PREMIUM_PLUS_ORDERFLOW_MONTHLY_1:"PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1",
    PREMIUM_PLUS_ORDERFLOW_MONTHLY_3:"PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3",
    PREMIUM_PLUS_ORDERFLOW_MONTHLY_6:"PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6",
    PREMIUM_PLUS_ORDERFLOW_MONTHLY_12:"PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12",
    PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_1:"PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1",
    PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_3:"PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3",
    PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_6:"PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6",
    PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_12:"PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12",
    ORDERFLOW_MONTHLY:"ORDERFLOW_INDEX_MONTHLY_1",
    ORDERFLOW_STOCKS_MONTHLY:"ORDERFLOW_INDEX_STOCKS_MONTHLY_1",
    STOCKS_MONTHLY_1:"STOCKS_MONTHLY_1",
    VMR_ECOURSE:"VMR_ECOURSE",
   
}  

 export const USER_MANUAL_TAB_LIST=[
    {
        value:0,
        name:"Vtrender Charts - Intro",
        id:"intro",
        title:"Vtrender Charts: The Triumvirate of Trading Excellence",
        // path:[],
        demoVideo:[
            {
                name: "Intro and Registration 1",
                src:"https://vimeo.com/854128544",
                yt_src: "https://youtu.be/QSy9oUdZxy8"
            }, 
            {
                name: "Intro and Registration 2",
                src:"https://vimeo.com/854262777",
                yt_src: "https://youtu.be/QSy9oUdZxy8"
            }, 
         ]
    },
    {
        value:1,
        name:"How-to in 5 min Videos",
        id:"intro_videos",
        title:"Intro Videos"
    },
    {
        value:2,
        name:"Orderflow Intro Videos",
        id:"orderflow_intro_videos",
        title:"Orderflow Intro Videos"
    },
    {
        value:3,
        name:"Market Profile Charts",
        id:"market_profile",
        path:["/market-profile"],
        title:"MarketProfile: The Blueprint of Trading Mastery",
        demoVideo:[
            {
                name: "Market Profile Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/Nsu_GxDl02Q"
            },
            {
                name: "Market Profile Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/LHtds-SrTi0"
            },
            
        ]

    },
    {
        value:4,
        name:"Orderflow Charts",
        id:"orderflow",
        path:["/orderflow"],
        title:"Demystifying OrderFlow: The Symphony of Market Dynamics",
        demoVideo:[
            {
                name: "Orderflow Charts 1",
                src:"https://vimeo.com/853735125",
                yt_src: "https://youtu.be/Ght8jNRidPo"
            },
            {
                name: "Orderflow Charts 2",
                src:"https://vimeo.com/853812666",
                yt_src: "https://youtu.be/Ght8jNRidPo"
            },
            
        ]
    },
    {
        value:5,
        name:"Options Table",
        id:"options_table",
        path:["/options-table"],
        title:"The Artistry of Option Tables: The Compass for Modern Traders",
        demoVideo:[
            {
                name: "Options Table 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/yp_EP2X41c4"
            },
            {
                name: "Options Table 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/NsTGNHKCd-I"
            },
            
        ]
    },{
        value:6,
        name:"Greeks Table",
        id:"greeks_table",
        path:["/greek-chart"],
        title:"The Alchemy of the Greeks Table: The Trader's Grimoire",
        demoVideo:[
            {
                name: "Greeks Table 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/3bll1e06vDo"
            },
            {
                name: "Greeks Table 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/NsTGNHKCd-I"
            },
            
        ]
    },{
        value:7,
        name:"Index Table",
        id:"index_table",
        path:["/index-table"],
        title:"Navigating the Index Galaxy: A Stellar Guide for Traders",
        demoVideo:[
            {
                name: "Index Table 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/ZcNnHieorVc"
            },
            {
                name: "Index Table 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/VkBMqJyqYcE"
            },
            
        ]
    },{
        value:8,
        name:"Spectrum Charts",
        id:"spectrum_charts",
        path:["/line-chart/NF_Options_Strike_Spectrum_Charts","/line-chart/BN_Options_Strike_Spectrum_Charts","/line-chart/FN_Options_Strike_Spectrum_Charts","/line-chart/MN_Options_Strike_Spectrum_Charts"],
        title:"Deciphering the Spectrum: Navigating Index Forecasts",
        demoVideo:[
            {
                name: "Spectrum Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/_L5B9RhFPhc"
            },
            {
                name: "Spectrum Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/G5n9__MxR24"
            },
            
        ]
    },{
        value:9,
        name:"OI Change Charts",
        id:"oi_change_charts",
        path:["/line-chart/NF_Options_OI_Change_Charts","/line-chart/BN_Options_OI_Change_Charts","/line-chart/FN_Options_OI_Change_Charts","/line-chart/MN_Options_OI_Change_Charts"],
        title:"Decoding Open Interest: Unveiling Market Pulse Through Moving Lines",
        demoVideo:[
            {
                name: "OI Change Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/Z5gSyz68jKw"
            },
            {
                name: "OI Change Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/SnTTzUDiVIY"
            },
            
        ]
    },{
        value:10,
        name:"NTM OI Change Charts",
        id:"ntm_oi_change_charts",
        path:["/line-chart/NF_Options_NTM_OI_Change_Charts","/line-chart/BN_Options_NTM_OI_Change_Charts","/line-chart/FN_Options_NTM_OI_Change_Charts","/line-chart/MN_Options_NTM_OI_Change_Charts"],
        title:"Zooming into Near Money Strikes: The Magnified Lens of OI Change",
        demoVideo:[
            {
                name: "NTM OI Change Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/hItCJb7WX-M"
            },
            {
                name: "NTM OI Change Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/Z5gSyz68jKw"
            },
            
        ]
    },{
        value:11,
        name:"Historical Volatility Charts",
        id:"historical_volatility_line_charts",
        path:["/hv-stock-chart/NIFTY","/hv-stock-chart/BANKNIFTY"],
        title:"Historical Volatility: Gazing Back to Navigate Forward",
        demoVideo:[
            {
                name: "HV Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/TfyGzJYR2Zs"
            },
            {
                name: "HV Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/419_jYo54cI"
            },
            
        ]
    },{
        value:12,
        name:"OI Inventory Visualization Charts",
        id:"oi_inventory_visualization_charts",
        path:["/bar-chart/NIFTY_Strike_OI","/bar-chart/BANKNIFTY_Strike_OI","/bar-chart/FINNIFTY_Strike_OI","/bar-chart/MIDCPNIFTY_Strike_OI"],
        title:"Inventory Visualization Charts: Illuminating the Options Landscape",
        demoVideo:[
            {
                name: "OI Viz Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/419_jYo54cI"
            },
            {
                name: "OI Viz Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/419_jYo54cI"
            },
            
        ]
    },{
        value:13,
        name:"Gamma Charts",
        id:"gamma_charts",
        path:["/bar-chart/NIFTY_Strike_Value","/bar-chart/BANKNIFTY_Strike_Value","/bar-chart/FINNIFTY_Strike_Value","/bar-chart/MIDCPNIFTY_Strike_Value"],
        title:"Gamma Visualization: The Art of Options Illumination",
        demoVideo:[
            {
                name: "Gamma Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/G5n9__MxR24"
            },
            {
                name: "Gamma Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/0TE9hzbJBQk"
            },
            
        ]
    },
    {
        value:14,
        name:"IV Curve Charts",
        id:"iv_curve_charts",
        path:["/bar-chart/NIFTY_Strike_IV","/bar-chart/BANKNIFTY_Strike_IV"],
        title:"Implied Volatility (IV) Curves: Decoding Market Pulse",
        demoVideo:[
            {
                name: "IV Curve Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/TfyGzJYR2Zs"
            },
            {
                name: "IV Curve Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/i986iS6MB_c"
            },
            
        ]
    },{
        value:15,
        name:"Options Snakes Line Charts",
        id:"options_snakes_line_charts",
        path:["/line-chart/NIFTY_Snakes","/line-chart/BANKNIFTY_Snakes","/line-chart/FINNIFTY_Snakes","/line-chart/MIDCPNIFTY_Snakes"],
        title:"Options Snakes Line Charts: Unraveling the Market's OI Dance",
        demoVideo:[
            {
                name: "Options Snakes Charts 1",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/419_jYo54cI"
            },
            {
                name: "Options Snakes Charts 2",
                src:"https://vimeo.com/802425925",
                yt_src: "https://youtu.be/TfyGzJYR2Zs"
            },
            
        ]
    },{
        value:16,
        name:"Options Volume Charts",
        id:"opt_vol_charts",
        path:["/line-chart/NF_Options_Vol_Charts","/line-chart/BN_Options_Vol_Charts","/line-chart/FN_Options_Vol_Charts","/line-chart/MN_Options_Vol_Charts"],
        title:"Decoding Volumes: Unveiling Market Pulse Through Moving Volumes Lines",
        demoVideo:[
            // {
            //     name: "OI Change Charts 1",
            //     src:"https://vimeo.com/802425925",
            //     yt_src: "https://youtu.be/Z5gSyz68jKw"
            // },
            // {
            //     name: "OI Change Charts 2",
            //     src:"https://vimeo.com/802425925",
            //     yt_src: "https://youtu.be/SnTTzUDiVIY"
            // },
            
        ]
    },
   

]   

export const INTRO_VIDIEOS = [
    {
      name: "The menu of the Charts",
      src:"https://vimeo.com/838217751",
      yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "Register for Vtrender Charts",
        src:"https://vimeo.com/838217723",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    },
    {
        name: "Market Profile Charts Section - Walkthrough",
        src:"https://vimeo.com/838217583",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "Additional Market Profile Info - Deep Dive",
        src:"https://vimeo.com/838217352",
        yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "Options Table - Part 1",
        src:"https://vimeo.com/838217621",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    },
    {
        name: "Options Table - Part 2",
        src:"https://vimeo.com/838217669",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "Greeks Table",
        src:"https://vimeo.com/838217442",
        yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "Index Table",
        src:"https://vimeo.com/838217528",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    },
    {
        name: "Spectrum Charts",
        src:"https://vimeo.com/838310937",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "OI Visualization Charts",
        src:"https://vimeo.com/839013191",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "Sensitivity",
        src:"https://vimeo.com/839014959",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "OI Change Charts",
        src:"https://vimeo.com/838317496",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    }

  ] 

  export const OF_INTRO_VIDIEOS = [
    {
        name: "Introduction to Vtrender's Orderflow Charts",
        src:"https://vimeo.com/853735125",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
      name: "Market Liquidity in Orderflow",
      src:"https://vimeo.com/853796900",
      yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "COT and OI in Orderflow Charts",
        src:"https://vimeo.com/853805750",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    },
    {
        name: "Market Dynamics with RB, IB, RS and IS",
        src:"https://vimeo.com/853812666",
        yt_src: "https://youtu.be/Ght8jNRidPo"
    },
    {
        name: "Volume Clusters in Orderflow Charts",
        src:"https://vimeo.com/853750725",
        yt_src: "https://youtu.be/QSy9oUdZxy8"
    },
    {
        name: "Understanding Imbalances in Orderflow Charts",
        src:"https://vimeo.com/853743768",
        yt_src: "https://youtu.be/EAhEGDhpYZg"
    }
  ] 

export const OF_ZOOM_TYPE={
    SYMMETRIC:"symmetric",
    ASYMMETRIC:"asymmetric"
}

export const ROTATING_COT_COUNT=10;

export const OF_BOTTOM_COL_LIST=["Volume","VPOC","VPOC Vol","COT","OI","dOI"];

export const SUBSCRIPTION_PLANS_PRICE_NEW={
    "monthly":{
        "premium":{
            "base":299,
            "base_gst":54,
            "stocks":699,
            "stocks_gst":126,
        },
       "expert":{
            "base":799,
            "base_gst":144,
            "stocks":999,
            "stocks_gst":180,
        },
        "elite":{
            "base":3999,
            "base_gst":720,
            "stocks":5999,
            "stocks_gst":1080,
        },
    },
    // Independence day offer - 10, 15, 30
    // "quarterly":{
    //     "premium":{
    //         "base":807,
    //         "base_gst":145,
    //         "base_actual":1059,
    //         "stocks":1887,
    //         "stocks_gst":340,
    //         "stocks_actual":2475,
    //     },
    //    "expert":{
    //         "base":2157,
    //         "base_gst":388,
    //         "base_actual":2859,
    //         "stocks":2697,
    //         "stocks_gst":485,
    //         "stocks_actual":3537
    //     },
    //     "elite":{
    //         "base":10797,
    //         "base_gst":1943,
    //         "base_actual":14157,
    //         "stocks":16197,
    //         "stocks_gst":2915,
    //         "stocks_actual":21237
    //     },
    // },
    // "half_yearly":{
    //     "premium":{
    //         "base":1525,
    //         "base_gst":274,
    //         "base_actual":2118,
    //         "stocks":3565,
    //         "stocks_gst":642,
    //         "stocks_actual":4950
    //     },
    //    "expert":{
    //         "base":4075,
    //         "base_gst":734,
    //         "base_actual":5718,
    //         "stocks":5095,
    //         "stocks_gst":917,
    //         "stocks_actual":7074
    //     },
    //     "elite":{
    //         "base":20395,
    //         "base_gst":3671,
    //         "base_actual":28314,
    //         "stocks":30595,
    //         "stocks_gst":5507,
    //         "stocks_actual":42474
    //     },

    // },
    // "annually":{
    //     "premium":{
    //         "base":2511,
    //         "base_gst":452,
    //         "base_actual":4236,
    //         "stocks":5871,
    //         "stocks_gst":1057,
    //         "stocks_actual":9900
    //     },
    //    "expert":{
    //         "base":6711,
    //         "base_gst":1208,
    //         "base_actual":11436,
    //         "stocks":8391,
    //         "stocks_gst":1510,
    //         "stocks_actual":14148
    //     },
    //     "elite":{
    //         "base":33591,
    //         "base_gst":6046,
    //         "base_actual":56628,
    //         "stocks":50391,
    //         "stocks_gst":9070,
    //         "stocks_actual":84948
    //     },
    // }
    // Normal discounts - 5, 10, 20
    "quarterly":{
        "premium":{
            "base":849,
            "base_gst":153,
            "base_actual":1059,
            "stocks":1989,
            "stocks_gst":358,
            "stocks_actual":2475,
        },
       "expert":{
            "base":2269,
            "base_gst":408,
            "base_actual":2859,
            "stocks":2839,
            "stocks_gst":511,
            "stocks_actual":3537
        },
        "elite":{
            "base":11389,
            "base_gst":2050,
            "base_actual":14157,
            "stocks":17089,
            "stocks_gst":3076,
            "stocks_actual":21237
        },
    },
    "half_yearly":{
        "premium":{
            "base":1609,
            "base_gst":290,
            "base_actual":2118,
            "stocks":3769,
            "stocks_gst":688,
            "stocks_actual":4950
        },
       "expert":{
            "base":4309,
            "base_gst":776,
            "base_actual":5718,
            "stocks":5389,
            "stocks_gst":970,
            "stocks_actual":7074
        },
        "elite":{
            "base":21589,
            "base_gst":3886,
            "base_actual":28314,
            "stocks":32389,
            "stocks_gst":5830,
            "stocks_actual":42474
        },
    },
    "annually":{
        "premium":{
            "base":2869,
            "base_gst":516,
            "base_actual":4236,
            "stocks":6709,
            "stocks_gst":1208,
            "stocks_actual":9900
        },
       "expert":{
            "base":7669,
            "base_gst":1380,
            "base_actual":11436,
            "stocks":9589,
            "stocks_gst":1726,
            "stocks_actual":14148
        },
        "elite":{
            "base":38389,
            "base_gst":6910,
            "base_actual":56628,
            "stocks":57589,
            "stocks_gst":10366,
            "stocks_actual":84948
        },
    }
}


export const SUBSCRIPTION_PLANS_NEW={
    PREMIUM_MONTHLY_1:"NEW_PREMIUM_MONTHLY_1",
    PREMIUM_MONTHLY_3:"NEW_PREMIUM_MONTHLY_3",
    PREMIUM_MONTHLY_6:"NEW_PREMIUM_MONTHLY_6",
    PREMIUM_MONTHLY_12:"NEW_PREMIUM_MONTHLY_12",
    PREMIUM_STOCKS_MONTHLY_1:"NEW_PREMIUM_STOCKS_MONTHLY_1",
    PREMIUM_STOCKS_MONTHLY_3:"NEW_PREMIUM_STOCKS_MONTHLY_3",
    PREMIUM_STOCKS_MONTHLY_6:"NEW_PREMIUM_STOCKS_MONTHLY_6",
    PREMIUM_STOCKS_MONTHLY_12:"NEW_PREMIUM_STOCKS_MONTHLY_12",
    PREMIUM_PLUS_MONTHLY_1:"NEW_PREMIUM_PLUS_MONTHLY_1",
    PREMIUM_PLUS_MONTHLY_3:"NEW_PREMIUM_PLUS_MONTHLY_3",
    PREMIUM_PLUS_MONTHLY_6:"NEW_PREMIUM_PLUS_MONTHLY_6",
    PREMIUM_PLUS_MONTHLY_12:"NEW_PREMIUM_PLUS_MONTHLY_12",
    PREMIUM_PLUS_STOCKS_MONTHLY_1:"NEW_PREMIUM_PLUS_STOCKS_MONTHLY_1",
    PREMIUM_PLUS_STOCKS_MONTHLY_3:"NEW_PREMIUM_PLUS_STOCKS_MONTHLY_3",
    PREMIUM_PLUS_STOCKS_MONTHLY_6:"NEW_PREMIUM_PLUS_STOCKS_MONTHLY_6",
    PREMIUM_PLUS_STOCKS_MONTHLY_12:"NEW_PREMIUM_PLUS_STOCKS_MONTHLY_12",
    PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1",
    PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3",
    PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6",
    PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12",
    PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1",
    PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3",
    PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6",
    PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12:"NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12",
}

export const SUBSCRIPTION_PLAN_TYPE={
    PREMIUM:"PREMIUM",
    EXPERT:"EXPERT",
    ELITE:"ELITE"
}