import React, { useState, useEffect, useRef, useContext } from 'react';
import { CircularProgress,Typography,Container,Box,IconButton, Tooltip,Snackbar,Alert } from '@mui/material';
import LineChartHeader from './components/line_chart_header';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import OptChainChartContainer from './components/optchain_chart_container';
import OptChainGexChartContainer from './components/optchain_gex_chart_container';
import OptChainVerticalChartContainer from './components/optchain_vertical_chart_container';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS, CHART_RELOAD_TIME,MARKET_CLOSE_TIME,MARKET_OPEN_TIME,TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT,MIN_BAR_CHART_WIDTH } from '../../common/utility/constant';
import { useQuery } from '../../common/utility/helperFunc';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { useNavigate, useLocation } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { createDictList, createIncrementDictList } from '../../common/utility/helperFunc';
import LoginModal from './components/login_popups';



const TPO_TIME=30;
const startTime = MARKET_OPEN_TIME;
const endTime = MARKET_CLOSE_TIME;
const interval = 15;
const gap=TPO_TIME/interval;    //it means every two interval represents one TPO and label will be created based on it. gap=30/interval


const BarChart = (props) => {

  const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
  const [cancelUIData, responseDataUIData, errorUIData, loadedUIData, resetUIData, executeAPIUIData ] = useAxiosWithAuth();
  const [cancelStatus, responseDataStatus, errorStatus, loadedStatus, resetStatus, executeAPIStatus ] = useAxiosWithAuth();
  const { auth } = useAuth();
  const navigate = useNavigate(); 
  const location = useLocation();
  const from = "/login";
  const [expiryList,setExpiryList]=useState()
  const [paramsLoaded,setParamsLoaded]=useState(false);
  const [timeInterval,setTimeInterval]=useState(createDictList(startTime, endTime, interval,gap));
  const [timeIncrements,setTimeIncrements]=useState(createIncrementDictList(0, 60, 6));
  const [targetTime,setTargetTime]=useState(timeInterval[0]);
  const [targetInterval,setTargetInterval]=useState(timeIncrements[0]);
  const [dateList,setDateList]=useState();
  const [isDrity,setIsDirty]=useState(false);
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  

  const [state, setState] = useState({
    isLoading: true,
    chartData: undefined,
    metadata: undefined,
    isInstrumentLive: false,
    reloadInterval: CHART_RELOAD_TIME.LC_DATA,
    plotParams: new Map(),
    chartLoaded:false,
    isPollingAllowed:false,
    isError:false,
    isTradingSessionActive:false,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME
  });

  // console.log("ERROR HERE")
  // console.log(props)

  const { isLoading, chartData, metadata, isInstrumentLive, reloadInterval: reloadInterval, plotParams,chartLoaded,isPollingAllowed,isError,
    isTradingSessionActive,isStatusCheckAllowed,statusCheckPollingInterval } = state;

  const {userSettings, setUserSettings } = useContext(UserSettingsContext);

  const type = useQuery("type");
  const symbol = useQuery("symbol");
  const expiry = useQuery("expiry");
  const date_key = useQuery("date");
  const tstr = useQuery("time");
  const fincr = useQuery("fincr");
  const plot = useQuery("plot");
  const menuType = useQuery("menu_type");
  // var isIncrementalDataFetched = false;

  const [queryParams, setQueryParams] = useState({
    type: type ? type : "OPTCHAIN",
    symbol: symbol ? symbol : "NIFTY",
    expiry: expiry ? expiry : "",
    date_key: date_key ? date_key : "",
    tstr: tstr ? tstr : "",
    fincr: fincr ? fincr : "",
    plot: plot ? plot : "_igex",
    menuType: menuType ? menuType : "oi",
  });

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  /**
   * autoclosing of the snackbar msg bar 
   */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };
  
  const { open, msg, severity } = msgState;

  //execute the data fetch on the component mount
   useEffect(()=>{
   
    let data={
      "symbol": "NIFTY",
      "instruments": 1,
      "optchain_instruments": 1,
      "snakechart_data": 1
    }
    
    executeAPIUIData(URL.MPCHART_UIDATA,"POST",data); 
  
  },[props.stateData]);

   /**
   * API response handler  
   */  
   useEffect(() => {
    if(loadedUIData){
      if(responseDataUIData!=null){
          console.log("MPCHart UI Data response=",responseDataUIData)
          console.log(queryParams.symbol);
          if(queryParams.symbol == "FINNIFTY")
            setExpiryList(responseDataUIData.active_fnf_expiries);
          else if(queryParams.symbol == "MIDCPNIFTY")
            setExpiryList(responseDataUIData.active_mcpn_expiries);
          else if(queryParams.symbol == "BANKNIFTY")
            setExpiryList(responseDataUIData.active_bnf_expiries);
          else
            setExpiryList(responseDataUIData.active_expiries);
          setDateList(responseDataUIData.optchain_tdates);
      }
      else if(errorUIData!==null){
        console.log("Error data=",errorUIData);
        // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorUIData?.response?.status === 401 || errorUIData?.response?.status === 403){
          console.log("status received =",errorUIData?.response?.status)
          navigate(from, { replace: true });
          // setShowLoginPopup(true);
        }
    }
    resetUIData();
    }
  },[loadedUIData,responseDataUIData]);
  // console.log("queryparams in bar chart, loaded =  ", queryParams, loaded);

  //state ref is reuired to keep track of lastLoadTime value in the state.
  const stateRef = useRef(state);

  useEffect(() => {
    // ReloadChart(location.search);
   
    // loadChartStateData();
  }, [location]);
  
  const ReloadChart=(searchVal)=>{
    setState((prevState) => ({
      ...prevState,
      isPollingAllowed:false,
      isLoading: true}));

  const searchParams = new URLSearchParams(searchVal);

  const paramObject={
    type: searchParams.get('type') ? searchParams.get('type') : "OPTCHAIN",
    symbol: searchParams.get('symbol') ? searchParams.get('symbol') : "NIFTY",
    expiry: searchParams.get('expiry') ? searchParams.get('expiry') : "",
    date_key: searchParams.get('date') ? searchParams.get('date') : "",
    tstr: searchParams.get('time') ? searchParams.get('time') : "",
    plot: searchParams.get('plot') ? searchParams.get('plot') : "net_gcash",
    menuType: searchParams.get('menu_type') ? searchParams.get('menu_type') : "oi"
    
    }
    setQueryParams(paramObject);
  }

  useEffect(() => {
    loadChartStateData();
  }, [props.stateData]);

  const loadChartStateData=()=>{
    if(props.stateData!=undefined){
      
      if(props.stateData?.title)
        document.title = props.stateData.title;
      
      setState((prevState) => ({
        ...prevState,
        isPollingAllowed:false,
        isLoading: true}));

        const paramObject={
          type: props.stateData.data.type? props.stateData.data.type : "OPTCHAIN",
          symbol: props.stateData.data.symbol ? props.stateData.data.symbol : "NIFTY",
          expiry: props.stateData.data.expiry ? props.stateData.data.expiry : "",
          date_key: props.stateData.data.date ? props.stateData.data.date : "",
          tstr: props.stateData.data.time ? props.stateData.data.time : "",
          fincr: props.stateData.data.fincr ? props.stateData.data.fincr : "",
          plot: props.stateData.data.plot ? props.stateData.data.plot : "net_gcash",
          menuType: props.stateData.data.menu_type ? props.stateData.data.menu_type : "oi"
          
          }
          setParamsLoaded(true);  
          setQueryParams(paramObject);  
    }else{
      setState((prevState) => ({
        ...prevState,
        isPollingAllowed:false,
        isLoading: true}));

        const paramObject={
          type: type ? type : "OPTCHAIN",
          symbol: symbol ? symbol : "NIFTY",
          expiry: expiry ? expiry : "",
          date_key: date_key ? date_key : "",
          tstr: tstr ? tstr : "",
          fincr: fincr ? fincr : "",
          plot: plot ? plot : "net_gcash",
          menuType: menuType ? menuType : "oi",
          }
          setParamsLoaded(true);  
          setQueryParams(paramObject);
    }

  }


  useEffect(() => {
    //set the ref to current state
    stateRef.current = state;

    console.log("Test use effect called=", stateRef.current, reloadInterval,queryParams);

    const timeout = setInterval(() => {
      console.log(stateRef.current.isInstrumentLive, stateRef.current.isPollingAllowed)
      if (stateRef.current.isInstrumentLive && stateRef.current.isPollingAllowed) {
        executeAPI(URL.LINE_CHART_DATA + `?type=${queryParams.type}&symbol=${queryParams.symbol}&expiry=${queryParams.expiry}&date=${queryParams.date_key}&time=${queryParams.tstr}&fincr=${queryParams.fincr}&plot=${queryParams.plot}&c=${userSettings.data.data_in_contracts}`, "GET", {});
      } else {
        console.log("instrument not live = ", stateRef.current.isInstrumentLive);
      }
    }, reloadInterval);

    //market status polling
    const timeoutStatusCheck=setInterval(() => {
      if(stateRef.current.isStatusCheckAllowed && !stateRef.current.isInstrumentLive)   {
          executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:queryParams.symbol});
      }
    },stateRef.current.statusCheckPollingInterval); 

    return () => {
      // just to clear the timeout when component unmounts
      clearInterval(timeout);
      clearInterval(timeoutStatusCheck)
    };
  }, [state]);

   /**
 * Market live status check response handler
 */  
useEffect(() => {
  if(loadedStatus){
    if(responseDataStatus!=null){
              //if instrument is live then load the live data else set the status as per the response
              
              if(responseDataStatus.isInstrumentLive!=undefined && responseDataStatus.isInstrumentLive){
                executeAPI(URL.LINE_CHART_DATA + `?type=${queryParams.type}&symbol=${queryParams.symbol}&expiry=${queryParams.expiry}&date=${queryParams.date_key}&time=${queryParams.tstr}&fincr=${queryParams.fincr}&plot=${queryParams.plot}&c=${userSettings.data.data_in_contracts}`, "GET", {});
              }else{
                setState((prevState) => ({
                  ...prevState,
                  isStatusCheckAllowed:(!responseDataStatus.isInstrumentLive),
                  statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                  isInstrumentLive:responseDataStatus.isInstrumentLive,
                }));
              }
          
    }
    else if(errorStatus!==null){
      console.log("Error data status check=",errorStatus);

      // setIndexState((prevState) => ({
      //   ...prevState,
      //   indexLoaded:true}));
        
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",errorStatus?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorStatus?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    resetStatus();
  }
  },[loadedStatus,responseDataStatus]);



  //execute the data fetch on the component mount
  useEffect(() => {
    // /prevent any api call before the state is laoded properly
    if(!paramsLoaded)
    return;

    //executeAPI(URL.LINE_CHART_DATA+"?type=SINGLE&symbol=NIFTY&startDate=&nDays=0","GET",{}); 
    executeAPI(URL.LINE_CHART_DATA + `?type=${queryParams.type}&symbol=${queryParams.symbol}&expiry=${queryParams.expiry}&date=${queryParams.date_key}&time=${queryParams.tstr}&fincr=${queryParams.fincr}&plot=${queryParams.plot}&c=${userSettings.data.data_in_contracts}`, "GET", {});
  }, [queryParams]);


  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        console.log("response bar chart: ", responseData,responseData.chart_data?.OPTCHAIN,responseData.chart_data?.OPTCHAIN?.length,isDrity);
        // console.log(responseData.chart_data.METADATA);
        if (responseData.chart_data !== undefined && responseData.chart_data?.OPTCHAIN?.length > 0) {
          // let index=timeInterval.findIndex((item)=>(item.time>=responseData.lastDataTime.toString()));
        
          let index=timeInterval.findIndex((item)=>(item.time>=responseData.chart_data?.METADATA?.time.toString()));
           if(index!=-1)
            setTargetTime(timeInterval[index]);
          else
            setTargetTime(timeInterval[timeInterval.length-1]);
         
            plotParams.set("plot", queryParams.plot);
            
            setState((prevState) => ({
            ...prevState,
            chartLoaded:true,
            isPollingAllowed:(!isDrity),
            isError:false,
            isTradingSessionActive:responseData.isTradingSessionActive,
            isStatusCheckAllowed:(!responseData.isInstrumentLive),
            statusCheckPollingInterval:(responseData.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
            isLoading: false, metadata: responseData.chart_data.METADATA, chartData: responseData.chart_data.OPTCHAIN, isInstrumentLive: responseData.isInstrumentLive, plotParams: plotParams
          }));
          
        }else{
        
          setMsgState({open:true,msg:TEXT_MSGS.CHART_DATA_NOT_AVAILABLE,severity:"info"});
          setState((prevState) => ({
            ...prevState,
            chartLoaded:true,
            isTradingSessionActive:responseData.isTradingSessionActive,
            isStatusCheckAllowed:(!responseData.isInstrumentLive),
            statusCheckPollingInterval:(responseData.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
            isPollingAllowed:false,
            isError:true,
            isLoading: false,
          }))
        }
      }
      else if (error !== null) {
        console.log("Error data=", error);
        if(error?.response?.status === 401 || error?.response?.status === 403){
          if(!isPollingAllowed){
          navigate(from, { replace: true });
          }else{
            setLoginMsg(error?.response?.data?.message);
            setShowLoginPopup(true);
          }
        }
        else{
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        setState((prevState) => ({
          ...prevState,
          chartLoaded:true,
          isError:true,
          isLoading: false
        }));
      }
    }
      reset();
    }
  }, [loaded, responseData]);

  // console.log([(queryParams.type == "OPTCHAIN"), queryParams.type, chartData])

  const handleStartDateChange=(val)=>{
    setIsDirty(true);
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        date_key:val
      }))

  }

  const changePlotType=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        plot:val
      }))

  }

  const handleExpiryChange=(val)=>{
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        expiry:val
      }))

  }

  const handleTimeChange=(val)=>{
    console.log("Time in bar chart=",val)
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        tstr:val.time
      }))
      setIsDirty(true);
      setTargetTime(val)

  }

  const handleTimeIntervalChange=(val)=>{
    console.log("Time Interval in bar chart=",val)
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));

      setQueryParams((prevState)=>({
        ...prevState,
        fincr:val.value
      }))
      setIsDirty(true);
      setTargetInterval(val)

  }

  const resetDateTime=()=>{
    setIsDirty(false);
    setState((prevState) => ({
      ...prevState,
      // isLoading: true, 
      chartLoaded:false,
      isIncrementalData: false,isPollingAllowed:false}));
      setQueryParams((prevState)=>({
        ...prevState,
        tstr:"",
        fincr:"",
        date_key:dateList[0]
      }))
      setTargetInterval(0);
  }
  
  function toggleFullScreen() {
    let chartdiv = document.querySelector('#'+props.id)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  if (queryParams.type === "OPTCHAIN") {
    // console.log("optchain strike wise plot");
    // console.log(chartData);
    // console.log(metadata);

    let plot = plotParams.get("plot");
    let chartType = "";
    let plotType = "mViz";
    if(plot == "net_gcash" || plot == "total_gcash" || plot == "_gcash"){
      chartType = "Options Strikewise Gamma EXposure";
    }
    else if(plot == "net_igex" || plot == "total_igex" || plot == "_igex"){
      chartType = "Options Strikewise Gamma";
    }
    else if(plot == "net_dgcash" || plot == "total_dgcash" || plot == "_dgcash"){
      chartType = "Options Strikewise Inventory Resistance Estimate";
    }
    else if(plot == "_wcash"){
      chartType = "Options Strikewise Writers' Unrealized Cash"
    }
    else if(plot == "gex_curves"){
      plotType = "gex"
    }
    console.log("plotType", plotType, plot)
    return (
      <>
        {/* <LineChartHeader /> */}
        {isLoading ?
          <div style={{ textAlign: "center", width: "100%", height: "100%" }}><CircularProgress sx={{ marginTop: "20%" }} />
          </div> :
             <>
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: "row", justifyContent: "space-evenly" ,width:"100%",minWidth:`${MIN_BAR_CHART_WIDTH}`, backgroundColor:"#111111"}}>
            <Container sx={{ width: "80%",mt:2 }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{queryParams.symbol} {chartType} Chart at {metadata?.time}</Typography>
          </Container>
         <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              sx={{mr:4,mt:1}}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px"}} />
            </IconButton>
          </Tooltip>
         </Box>
         {console.log("plotType1", plotType, plot, plotParams.get("plot"))}
         {chartLoaded?
          plot == "gex_curves"?
          <OptChainGexChartContainer id={props.id} isError={isError} chartData={chartData} metadata={metadata} symbol={symbol} plotParams={plotParams} startDate={queryParams.date_key} 
          handleStartDateChange={handleStartDateChange} menuType={queryParams.menuType} plotType={queryParams.plot} changePlotType={changePlotType} expiry={queryParams.expiry} expiryList={expiryList} dateList={dateList}
          handleExpiryChange={handleExpiryChange} time={""} handleTimeIntervalChange={handleTimeIntervalChange} handleTimeChange={handleTimeChange} timeInterval={timeInterval} timeIncrements={timeIncrements} targetTime={targetTime} targetInterval={targetInterval} isDrity={isDrity} 
          resetDateTime={resetDateTime}
          />
          :<OptChainChartContainer id={props.id} isError={isError} chartData={chartData} metadata={metadata} symbol={symbol} plotParams={plotParams} startDate={queryParams.date_key} 
          handleStartDateChange={handleStartDateChange} menuType={queryParams.menuType} plotType={queryParams.plot} changePlotType={changePlotType} expiry={queryParams.expiry} expiryList={expiryList} dateList={dateList}
          handleExpiryChange={handleExpiryChange} time={""} handleTimeChange={handleTimeChange} timeInterval={timeInterval} targetTime={targetTime} isDrity={isDrity} 
          resetDateTime={resetDateTime}
          />
          :
          <div style={{ textAlign: "center", width: "100%", height: "100%",backgroundColor:"#111111" }}><CircularProgress sx={{ marginTop: "20%" }} />
          </div>
         }
         <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       {showLoginPopup?
          <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
          </>
        }
      </>
    )
  }
}

export default BarChart

