import React, {useState,useEffect,useRef,useContext,useReducer} from 'react'

import { CONSTANTS, TIME_FRAME_VALUES,OF_TIME_FRAME_VALUES,BOOKMARK_CATEGORY_AUTOSAVE, DEFAULT_CANDLE_TIME_FRAME } from '../../common/utility/constant'
import OFChartHeaderSettings from './components/ofchart_header';

import MPChartFooter from './components/mpchart_footer';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,MP_CHART_RELOAD_TIME,DEFAULT_OF_TIME_FRAME } from '../../common/utility/constant';
import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '../../common/utility/helperFunc';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { VA_TYPE,VP_TYPE } from '../../common/utility/mp_chart_constants';
import { CHART_TYPE } from '../../common/utility/mp_chart_constants';
import LineChart from './line_chart';
import StockChartHV from './stock_chart_hv';
import BarChart from './bar_chart';
import ConfirmActionDialog from './components/confirm_dialog';
import {useSearchParams} from 'react-router-dom';

import OFChartFooter from './components/ofchart_footer';
// import OFChart from './components/orderflow_chart';
import CandlestickChart from './components/candlestick_chart';
import ChartContainer from './chart_container';
import BarChartContainer from './BarChartContainer';
import LineChartContainer from './LineChartContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import OFSettings from './components/of_settings';
import CandlestickFooter from './components/candlestick_footer';
import CandlestickHeader from './components/candlestick_header';
import CandleSettings from './components/candle_settings';




const DEFAULT_PROFILE_WIDTH=60;
const DEFAULT_PROFILE_WIDTH_WEEKLY=100;
const DEFAULT_PROFILE_WIDTH_MONTHLY=120;
const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const PROFILE_LOAD_FACTOR=1.2;
const CandlestickContainer = (props) => {
  
  // console.log("props==========================>",props);
  const { auth } = useAuth();
  const location = useLocation();
  const [chartType,setChartType]=useState(props.chartType?props.chartType:CHART_TYPE.OF_CHART)
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  // const from = location.state?.from?.pathname || "/login";
  const from = "/login";
  const symbol = useQuery("symbol");
  console.log("props.key 1=",props.key,props.val)
  const [state, setState] = useState({
    isLoading:true,
    liveData:undefined,
    chartData:undefined,
    instrument:props.symbol?props.symbol:(symbol&&props.val==0?symbol:userSettings.data.default_instrument_candle?userSettings.data.default_instrument_candle:userSettings.data.default_instrument),
    // instrument:symbol&&props.val==0?symbol:userSettings.data.default_instrument,
    isIncrementalData:false,
    lastLoadTime:-1,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?MP_CHART_RELOAD_TIME.FAST_DATA:MP_CHART_RELOAD_TIME.SLOW_DATA,
    pollingInterval:MP_CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    selectedTimeFrame:userSettings.data.default_tf_candle?userSettings.data.default_tf_candle:DEFAULT_CANDLE_TIME_FRAME,
    // selectedTimeFrame:"1",
    isPollingAllowed:false,
    isTradingSessionActive:false,
    isJsonLoaded:false,
    isFirtLoadComplete:false,
    apiData:undefined,
    bookmarkData:undefined,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:MP_CHART_RELOAD_TIME.POLLING_TIME,
    isLivePollingAllowed:false,
    lastTime:undefined,
    chartRendered:false,

  });

  const [footerLoaded,setFooterLoaded]=useState(false);
  
const [msgState, setMsgState] = useState({
  open: false,
  msg:"" ,
  severity:"info"
});

const { open, msg, severity } = msgState;

  const {isLoading,chartData,liveData,isIncrementalData,isInstrumentLive,lastLoadTime,reloadInerval,pollingInterval,instrument,refreshInterval,selectedTimeFrame,
    isPollingAllowed,isTradingSessionActive,isJsonLoaded,isFirtLoadComplete,apiData,bookmarkData,isStatusCheckAllowed,statusCheckPollingInterval,isLivePollingAllowed,lastTime,chartRendered } = state;
  const stateRef = useRef(state);
 
  
const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
const [cancelSaved,responseDataSaved,errorSaved,loadedSaved,resetSaved,executeAPISaved] = useAxiosWithAuth();
const [cancelDelete,responseDataDelete, errorDelete, loadedDelete, resetDelete,executeAPIDelete ] = useAxiosWithAuth();
const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();


  const [selectedInstrument,setSelectedInstrument]=useState()
  const selectedInstrumentRef=useRef(selectedInstrument)
  const [selectedTPO,setSelectedTPO]=useState(undefined)
  const [selectedInstrumentData,setSelectedInstrumentData]=useState();
  const [selectedInstrumentTPOData,setSelectedInstrumentTPOData]=useState();
  const [globalVolumeVisible,setGlobalVolumeVisible]=useState(userSettings.data.show_global_vp_of!=undefined?userSettings.data.show_global_vp_of:true);
  const [volumeNumberVisible,setVolumeNumberVisible]=useState(userSettings.data.show_global_vp_numbers_of!=undefined?userSettings.data.show_global_vp_numbers_of:false);
  const [onlyVolumeProfile,setOnlyVolumeProfile]=useState(false);
  const [tpoOnVolume,setTpoOnVolume]=useState(false);
  const [perProfilelVolumeVisible,setPerProfileVolumeVisible]=useState(false);
  const [perProfileVolumeNumberVisible,setProfileVolumeNumberVisible]=useState(false);
  const matches = useMediaQuery('(min-width:820px)');
  const [livePanelVisible,setLivePanelVisible]=useState((matches?true:false)&&(userSettings.data.show_live_panel_of!=undefined && userSettings.data.show_live_panel_of?userSettings.data.show_live_panel_of:true));
  const [bottomTableVisible,setBottomTableVisible]=useState(userSettings.data.show_bottom_table_of!=undefined && userSettings.data.show_bottom_table_of?userSettings.data.show_bottom_table_of:true);
  const navigate = useNavigate(); 
  const [autoCenter, setAutocenter] = useState(false);
  // const [viewState, setViewState] = React.useState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  const [viewState, setViewState] = React.useState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
 
  const [tpoBasedVA,setTPOBasedVA]=useState(userSettings.data.va_type==VA_TYPE.TPO_BASED);
  const [toggleReset,setToggleReset]=useState(false);
  const [toggleUnselect,setToggleUnselect]=useState(false);
  const [rightPanelConatinerState,setRightPanelConatinerState]=useState({
    show:false,
    metadata:undefined,
    histoticalData:undefined,
    selectedIndexes:[],
    isLiveSession:false
  })
  const [toggleSave,setToggleSave]=useState(false);
  const [toggleSaveBookmark,setToggleBookmark,]=useState(false);
  const [jsonData,setJsonData]=useState();
  const [vpType,setVPType]= React.useState(userSettings.data.vp_type);
  const [stateData,setStateData]=useState();
  const [openAutosaveDeleteDialog,setOpenAutosaveDeleteDialog]=useState(false);
  const [reloadInstrument,setReloadInstrument]=useState();
  const [isDirty,setIsDirty]=useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleMPLoading,setToggleMPLoading]=useState(false);
  const [showTable,setShowTable]=useState(false);
  const [tableData,setTableData]=useState(false);
  const [levelData,setLevelData]=useState([-1,-1]);
  const [showSettings,setShowSettings]=useState(false);
  
  useEffect(()=>{
    console.log("props OF container==",props);
    if(props.setChartType)
    props.setChartType(chartType);
  },[])
   
  const openStockChart=()=>{
    navigate(`/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }
  const openMPChart=()=>{
    setShowTable(false);
    if(chartType!=CHART_TYPE.MARKET_PROFILE){
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel(); 
      // console.log("state=",state);
      setPollingAllowedVal(false);
      setChartType(CHART_TYPE.MARKET_PROFILE)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.MARKET_PROFILE)
      
      // setState((prevState) => ({
      //   ...prevState,
      //   isLoading:false}));
      
      // setSelectedInstrument(selectedInstrument);
      }
    // navigate(`/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }

  const setCurrentChartType=(val)=>{
    // props.setParentChartType(val)
  }
  
  const openLineChart=()=>{
    
    // if(chartType!=CHART_TYPE.LINE_CHART){
      console.log("selected instrument",props.val,selectedInstrument);
      console.log("live panel resetRightPanel===2")
      props.resetRightPanel(); 
      setPollingAllowedVal(false)
      setStateData(undefined);
      setChartType(CHART_TYPE.LINE_CHART)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.LINE_CHART)
    // }
    // navigate(`/line-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }

  const openOFChart=()=>{
    setShowTable(false);
    if(chartType!=CHART_TYPE.OF_CHART){
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel(); 
      // console.log("state=",state);
      setPollingAllowedVal(false)
      setChartType(CHART_TYPE.OF_CHART)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.OF_CHART)
      
     
      }
     
  }

  useEffect(()=>{
    console.log("OF Chart container Props changes, proUser",props.chartStateData,props.proUser)
  },[props.chartStateData])

  
  const loadAutoSaveData=(val)=>{
    console.log("LoadAutsave dat val====",val);
    // executeAPISaved(`${URL.GET_BOOKMARK_DATA}?id=${val}`,"GET",{});
  }

   /**
 * API response handler  auto saved data
 */  
   useEffect(() => {
    if(loadedSaved){
      console.log("loaded Save=true",responseDataSaved);
      if(responseDataSaved!=null){
           console.log("Saved data loaded",responseDataSaved.data)
           const jsonData=responseDataSaved.data.data;
           document.title = "MP Charts - " + jsonData.instrument.replace('O_','');
          //  setJsonData(jsonData);
           let data={
            "symbol": jsonData.instrument,
            "nDays":0,
            "nProf": "",
            // "nProf": 2,
            "dstr": jsonData.dstr,   //
            "liveData": true,
            "liveOnly": false,
            "liveDataComposite": false,
            "startDate":jsonData.startDate,
            // "endDate": "",
            "tf": jsonData.tf,
            "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
            "contracts": userSettings.data.data_in_contracts,
            "upperLevel":(jsonData.upperLevel? jsonData.upperLevel : undefined),
            "lowerLevel":(jsonData.lowerLevel? jsonData.lowerLevel : undefined),
          }
         

          //save the symbol in the url
           if(responseDataSaved.data.category==BOOKMARK_CATEGORY_AUTOSAVE && props.val==0)
          setSearchParams({"symbol":jsonData.instrument})
          
          setSelectedTPO(jsonData.tpo);
          let bookmarkData=responseDataSaved.data;
         
          bookmarkData.last_saved=responseDataSaved.last_saved;
          setState((prevState) => ({
            ...prevState,
            isLoading:true,
            isJsonLoaded:true,
            selectedTimeFrame:data.tf,
            apiData:data,
            bookmarkData:responseDataSaved.data,
            selectedInstrument:jsonData.instrument,
            instrument:jsonData.instrument}));
            setJsonData(jsonData);
            setFooterLoaded(true);
            
            if(jsonData.upperLevel && jsonData.lowerLevel){
              let levels=[];
              levels.push(jsonData.upperLevel);
              levels.push(jsonData.lowerLevel);
              setLevelData(levels.sort());
            }
            // if(selectedInstrument!=undefined)
            // executeAPI(URL.MPCHART,"POST",data);
      }
      else if(errorSaved!==null){
        // loadData();
        console.log("Error data=",errorSaved);
        setState((prevState) => ({
          ...prevState,
          isJsonLoaded:true,
          isFirtLoadComplete:true,
          isLoading:false}));
          
          // setMsgState({open:true,msg:errorSaved?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorSaved?.response?.status === 401 || errorSaved?.response?.status === 403){
            console.log("status received =",errorSaved?.response?.status)
            navigate(from, { replace: true });
          }else
          setMsgState({open:true,msg:errorSaved?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetSaved();
    }
    },[loadedSaved,responseDataSaved]);

 const loadData=()=>{
  console.log("loadData selectedTimeFRame=",selectedTimeFrame)
  let nprof=3;//TODO: testing... set it to one in prod
  if(selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_30_min){
    nprof=20;
  }else if(selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_15_min){
    nprof=12;
  }
  else if(selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_5_min){
    nprof=10;
  }
  else if(selectedTimeFrame==OF_TIME_FRAME_VALUES.tf_3_min){
    nprof=8;
  }
  else{
    nprof=3;
  }  
  
  // console.log("usersettings=",userSettings.data)
  document.title = "Candlestick Charts - " + selectedInstrument.replace('O_','');
 
  // console.log("chart api data = ",data,selectedInstrument);
  setState((prevState) => ({
    ...prevState,
    isLoading:true,
    instrument:selectedInstrument}));
  

//   executeAPI(URL.MPCHART,"POST",data);
  let tempData={
    "symbol": selectedInstrument,
    "nProf": nprof,
    "liveData": true,
    "liveOnly": false,
    "startDate": "",
    "endDate": "",
    "tf": selectedTimeFrame,
    "contracts": true
}

executeAPI(URL.CANDLE_CHART,"POST",tempData);

 }  
 

 
 

  //execute the data fetch on the component mount
  useEffect(()=>{
     setIsDirty(true);
      setJsonData(undefined);
      setState((prevState) => ({
        ...prevState,
        isJsonLoaded:true,
        isFirtLoadComplete:true}));
  },[]);

  useEffect(()=>{
    console.log("OFData useeffect is called for instr and time frame",selectedInstrument, selectedTimeFrame,apiData);
    if(selectedInstrument!=undefined){
     
     
      //save the current symbol in URL if its not in the bookmark view
      if (!searchParams.has('bookmarkID') && props.val==0) {
        setSearchParams({"symbol":selectedInstrument})
      }
      // setJsonData(undefined);
      setState((prevState)=>({
        ...prevState,
        // apiData:undefined,
        bookmarkData:undefined,
        // isJsonLoaded:false,
      }))
      setFooterLoaded(true);
      loadData();
      setIsDirty(true);
   
    }
  },[selectedInstrument,selectedTimeFrame,toggleMPLoading]);

  

 
  useState(()=>{
    console.log("charttype,state",chartType,state);
  },[state])
  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loaded){
      // responseData=JSON.parse(responseData)
     
      if(responseData!=null){
        // console.log("ofdata loaded=true",responseData,responseData.dateList);
            if(responseData.cc_data!==undefined && responseData.cc_data.length>0){
              let updateInterval=pollingInterval;
              if(responseData.isPreOpenLive!=undefined && responseData.isPreOpenLive){
                updateInterval=!selectedInstrumentData.slow?reloadInerval:MP_CHART_RELOAD_TIME.SLOW_DATA;
              }
              // responseData.isTradingSessionActive=true;
              let isLiveDataPresent=(responseData.isInstrumentLive && responseData.dateList.length>0 && responseData.dateList[responseData.dateList.length-1]==responseData.liveDate);
               setState((prevState) => ({
                  ...prevState,refreshInterval:updateInterval,
                  isPollingAllowed:true,
                  isStatusCheckAllowed:(!responseData.isPreOpenLive),
                  statusCheckPollingInterval:(responseData.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                  isJsonLoaded:true,
                  isFirtLoadComplete:true,
                  // selectedTimeFrame:(jsonData!=undefined?jsonData.tf:selectedTimeFrame),
                  isTradingSessionActive:responseData.isTradingSessionActive,
                  isLoading:false,
                  isIncrementalData:isLiveDataPresent,
                  isInstrumentLive:(responseData.isPreOpenLive && responseData.dateList.length>0 && responseData.dateList[responseData.dateList.length-1]==responseData.liveDate),
                  isLivePollingAllowed:responseData.isPreOpenLive,
                  chartData:responseData,
                  lastTime:responseData.lastTime?responseData.lastTime:undefined
                }));
                // console.log("OFdata api loaded",responseData.of_data);
            }
           
            if(responseData.upgrade_notify!=undefined && responseData.upgrade_notify){
              props.openSubscribeModal();
            }
      }
      else if(error!==null){
        console.log("Error data=",error);
        setState((prevState) => ({
          ...prevState,
          isJsonLoaded:true,
          isFirtLoadComplete:true,
          isLoading:false}));
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
    }
    },[loaded,responseData]);

    useEffect(()=>{
      console.log("OFdata isLoading, footerloaded=",isLoading,footerLoaded,isJsonLoaded,chartData)
    },[isLoading,chartData,footerLoaded,isJsonLoaded])

    //polling or live chart update handler
    useEffect(() => {
      //set the ref to current state
      stateRef.current = state;
      
      console.log("Live use effect called incremental=",stateRef.current,stateRef.current.refreshInterval);
      const timeout = setInterval(() => {
        //if instrument is live, do fetch data based on user role else keep polling every 1min 
          let data={
            "symbol": stateRef.current.instrument,
            "nDays": 0,
            "nProf": 1,
            "liveData": true,
            "liveOnly": true,
            "startDate": "",
            "endDate": "",
            "tf": selectedTimeFrame,
            "lastTime":stateRef.current.isIncrementalData?lastTime:undefined,
            "incremental":stateRef.current.isIncrementalData?1:undefined,
            // "incremental":stateRef.current.isInstrumentLive?1:undefined
          }
           if(stateRef.current.isPollingAllowed && stateRef.current.isLivePollingAllowed)   {
             console.log("calling live of chart");
            //  cancelLive();
            executeLive(URL.CANDLE_CHART,"POST",data); 
           } 
          
      
      }, stateRef.current.refreshInterval);

      //market status polling
      const timeoutStatusCheck=setInterval(() => {
        if(stateRef.current.isStatusCheckAllowed && !stateRef.current.isInstrumentLive)   {
           executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:stateRef.current.instrument});
        }
      },stateRef.current.statusCheckPollingInterval);
    
      return () => {
        // just to clear the timeout when component unmounts
        clearInterval(timeout);
        clearInterval(timeoutStatusCheck);
      };
    }, [state]);


  /**
 * Market live status check response handler
 */  
   useEffect(() => {
    if(loadedStatus){
      if(responseDataStatus!=null){
                //if instrument is live then load the live data else set the status as per the response
                
                if(responseDataStatus.isPreOpenLive!=undefined && responseDataStatus.isPreOpenLive){
                  let data={
                    "symbol": stateRef.current.instrument,
                    "nDays": 0,
                    "nProf": 1,
                    "liveData": true,
                    "liveOnly": true,
                    "startDate": "",
                    "endDate": "",
                    "tf": selectedTimeFrame,
                     } 
                  executeLive(URL.CANDLE_CHART,"POST",data); 
                }else{
                  //reload the chart once tradinng session goes from active to inactive
                  if(isPollingAllowed && isTradingSessionActive && !responseDataStatus.isTradingSessionActive){
                    console.log("RELOADING OF CHART-------> Trading session is set to false");
                    setJsonData(undefined);
                    loadData(); 
                  }
                  else{
                    setState((prevState) => ({
                      ...prevState,
                      isStatusCheckAllowed:(!responseDataStatus.isPreOpenLive),
                      statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                      isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                      isInstrumentLive:responseDataStatus.isInstrumentLive,
                      }));
                  }
     
                }
            
      }
      else if(errorStatus!==null){
        console.log("Error data status check=",errorStatus);

        // setState((prevState) => ({
        //   ...prevState,
        //   isLoading:false}));
          
          if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
            console.log("status received =",errorStatus?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              props.showLoginPopup(true, errorStatus?.response?.data?.message);
            }
          }
      }
      resetStatus();
    }
    },[loadedStatus,responseDataStatus]);
   
  /**
 * Live data API response handler  
 */  
   useEffect(() => {
    if(loadedLive){
      // console.log("loaded=true live",responseDataLive);
      if(responseDataLive!=null && isPollingAllowed){
        let updateInterval=pollingInterval;
              if(responseDataLive.isPreOpenLive!=undefined && responseDataLive.isPreOpenLive){
                updateInterval=!selectedInstrumentData.slow?reloadInerval:MP_CHART_RELOAD_TIME.SLOW_DATA;
              }
            if(responseDataLive.cc_data!==undefined && responseDataLive.cc_data.length>0){
             
              setState((prevState) => ({
                  ...prevState,refreshInterval:updateInterval,
                  isPollingAllowed:isPollingAllowed,
                  isStatusCheckAllowed:(!responseDataLive.isPreOpenLive),
                  statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataLive.isTradingSessionActive,
                  isLoading:false,isIncrementalData:true,isInstrumentLive:responseDataLive.isInstrumentLive,
                  isLivePollingAllowed:responseDataLive.isPreOpenLive,
                  liveData:responseDataLive,
                  lastTime:responseDataLive.lastTime,
                  isIncrementalData:true
                }));
            }
            else{
              setState((prevState) => ({
                ...prevState,refreshInterval:updateInterval,
                isPollingAllowed:isPollingAllowed,
                isStatusCheckAllowed:(!responseDataLive.isPreOpenLive),
                statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isLivePollingAllowed:responseDataLive.isPreOpenLive,
                isInstrumentLive:(responseDataLive.isPreOpenLive && responseDataLive.dateList.length>0 && responseDataLive.dateList[0]==responseDataLive.liveDate)
                }));
               
            }
            if(responseDataLive.upgrade_notify!=undefined && responseDataLive.upgrade_notify){
              props.openSubscribeModal();
            }
      }
      else if(errorLive!==null){
        console.log("Error data live=",errorLive);
       
        setState((prevState) => ({
          ...prevState,
          isLoading:false}));
          // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          // console.log("status received =",errorLive?.response?.status)
          
          if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
            console.log("status received =",errorLive?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              props.showLoginPopup(true, errorLive?.response?.data?.message);
            }
          }
          if(errorLive?.response?.status === 416){      //handle bad request in case last daya polling resumes in the next day
            window.location.reload();
          }
      }
      resetLive();
    }
    },[loadedLive,responseDataLive]);




/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

const handleInstrumentChange=(instrument,tpoData)=>{
  // setSelectedInstrument(undefined);
  setChartRenderedState(false);
  console.log("instrument change in orderflow =",instrument,tpoData,selectedInstrument,instrument.instr)
  if(selectedInstrument==instrument.instr && (levelData[0]!=-1 && levelData[1]!=-1)){
    // setLevelData([-1,-1]); 
    let defaultTPO=tpoData.of_default_tpo;
    // setSelectedTPO(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
    defaultTPO=userSettings.data.tf_tpo_map_of!=undefined&&userSettings.data.tf_tpo_map_of[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[instrument.tpo_group].min3:defaultTPO;
    setSelectedTPO(defaultTPO);
   
    setSelectedInstrumentTPOData(tpoData);
    setViewState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
    setGlobalVolumeVisible(userSettings.data.show_global_vp_of!=undefined?userSettings.data.show_global_vp_of:true);
    setVolumeNumberVisible(userSettings.data.show_global_vp_numbers_of!=undefined?userSettings.data.show_global_vp_numbers_of:false);
    setTPOBasedVA(!instrument.vp || userSettings.data.va_type==VA_TYPE.TPO_BASED)
    setProfileVolumeNumberVisible(false);
    return;
  }
  // setSelectedInstrumentData(instrument);
  // if(instrument.instr!=selectedInstrument){
    if(true){
      setLevelData([-1,-1]);  
    setJsonData(undefined);
    console.log("ofdata live panel resetRightPanel===3")
    props.resetRightPanel();    
  setState((prevState) => ({
    ...prevState,
    // selectedTimeFrame:jsonData!=undefined && jsonData.instrument==instrument.instr?jsonData.tf:  DEFAULT_OF_TIME_FRAME,
    selectedTimeFrame:jsonData!=undefined && jsonData.instrument==instrument.instr?jsonData.tf:  userSettings.data.default_tf_candle?userSettings.data.default_tf_candle:DEFAULT_CANDLE_TIME_FRAME,
    isLoading:true,
    isPollingAllowed:false,   //polling will start when 1st load of new instrument is done
    isStatusCheckAllowed:false,
    instrument:instrument.instr,
    chartData:undefined,
    isIncrementalData:false

  }));
  console.log("instrument change in Market profile 2 =",instrument,tpoData,selectedInstrument,instrument.instr)
  setSelectedInstrument(instrument.instr);
  if(instrument.instr==selectedInstrument)
  setToggleMPLoading(!toggleMPLoading);
    
  // setSelectedTPO(tpoData.default_tpo);
  if(jsonData!=undefined && jsonData.instrument==instrument.instr){
    // setIsDirty(false);
    setSelectedTPO(jsonData.tpo)
  }
  else{
    let defaultTPO=tpoData.of_default_tpo;
    defaultTPO=userSettings.data.tf_tpo_map_of!=undefined&& userSettings.data.tf_tpo_map_of[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[instrument.tpo_group].min3:defaultTPO;
    setSelectedTPO(defaultTPO);
    // setSelectedTPO(tpoData.of_default_tpo);
    // setSelectedTPO(0.2)
    // setSelectedTPO(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
    // setIsDirty(false);
  }       
  setSelectedInstrumentTPOData(tpoData);
  // setViewState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  setViewState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
 
  setGlobalVolumeVisible(userSettings.data.show_global_vp_of!=undefined? userSettings.data.show_global_vp_of:true);
  setVolumeNumberVisible(userSettings.data.show_global_vp_numbers_of!=undefined?userSettings.data.show_global_vp_numbers_of:false);
  // setTPOBasedVA(!instrument.vp);
  setTPOBasedVA(!instrument.vp || userSettings.data.va_type==VA_TYPE.TPO_BASED)
  setProfileVolumeNumberVisible(false);
  setSelectedInstrumentData(instrument);
}
 
  //if instrument is slow and instrument is live then then change the chart update time
}
const handleTpoChange=(val)=>{
   
  
  if(selectedTPO!=val){
    console.log("handleTPO= ",selectedTPO,val);
    console.log("live panel resetRightPanel===4")
    props.resetRightPanel(); 
    setSelectedTPO(val);
    setIsDirty(true);
  }
 
}

const changeTimeFrame=(val)=>{
 
  // props.resetRightPanel();  
  // if(val==selectedTimeFrame || isLoading)
  // return;
  if(selectedTimeFrame==val)
  return;
  setChartRenderedState(false);
   console.log("live panel resetRightPanel===5")
  props.resetRightPanel();  
  setIsDirty(true);
  setJsonData(undefined);
console.log("time frame changed val= ==============================?",val,selectedTimeFrame,selectedTPO);
// setGlobalVolumeVisible(userSettings.data.show_global_vp);
// // setVolumeNumberVisible(false);
// setVolumeNumberVisible(userSettings.data.show_global_vp_numbers)
setGlobalVolumeVisible(userSettings.data.show_global_vp_of!=undefined? userSettings.data.show_global_vp_of:true);
setVolumeNumberVisible(userSettings.data.show_global_vp_numbers_of!=undefined?userSettings.data.show_global_vp_numbers_of:false);
setPerProfileVolumeVisible(false);
setProfileVolumeNumberVisible(false);

// setState((prevState) => ({
//   ...prevState,
//   isPollingAllowed:false,
//   isStatusCheckAllowed:false,
//   isLoading:true,
//   selectedTimeFrame:val
// }));

if(val!=TIME_FRAME_VALUES.daily){
  setLevelData([-1,-1]);
}
// console.log("Time frame change tpo vals====>",instrument.tpo_group,userSettings.data.tf_tpo_map[instrument.tpo_group],selectedInstrumentTPOData.of_tf_factor["30"],selectedInstrumentTPOData.of_tf_factor)
  //set the default tpo based on time frame selected
  let newTPO;
  if(val==OF_TIME_FRAME_VALUES.tf_1_min){
    newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min1:undefined;
  
    newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["1"]? selectedInstrumentTPOData.of_tf_factor["1"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo;
    // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["1"]? selectedInstrumentTPOData.of_tf_factor["1"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
  
  }
  else if (val==OF_TIME_FRAME_VALUES.tf_3_min ){
    newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min3:undefined;
    console.log("OFSETTINGS container newTPO=",newTPO)
    newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["3"]? selectedInstrumentTPOData.of_tf_factor["3"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo;
    // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["3"]? selectedInstrumentTPOData.of_tf_factor["3"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
      // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
}
else if (val==OF_TIME_FRAME_VALUES.tf_5_min ){
  newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min5:undefined;
  console.log("OFSETTINGS container newTPO=",newTPO,selectedTPO)
  newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["5"]? selectedInstrumentTPOData.of_tf_factor["5"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo;
  // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["5"]? selectedInstrumentTPOData.of_tf_factor["5"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
}
else if (val==OF_TIME_FRAME_VALUES.tf_10_min ){
  newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min10:undefined;
  
  newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["10"]? selectedInstrumentTPOData.of_tf_factor["10"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo
  // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["10"]? selectedInstrumentTPOData.of_tf_factor["10"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
}
else if (val==OF_TIME_FRAME_VALUES.tf_15_min ){
  newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min15:undefined;
  
  newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["15"]? selectedInstrumentTPOData.of_tf_factor["15"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo;
  // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["15"]? selectedInstrumentTPOData.of_tf_factor["15"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
}
else if (val==OF_TIME_FRAME_VALUES.tf_30_min ){
  newTPO=userSettings.data.tf_tpo_map_of!=undefined && userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[selectedInstrumentData.tpo_group].min30:undefined;
  
  newTPO=newTPO?newTPO:selectedInstrumentTPOData.of_tf_factor && selectedInstrumentTPOData.of_tf_factor["30"]? selectedInstrumentTPOData.of_tf_factor["30"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo;
  // setSelectedTPO(selectedInstrumentTPOData.of_tf_factor["30"]? selectedInstrumentTPOData.of_tf_factor["30"]*selectedInstrumentTPOData.of_default_tpo:1*selectedInstrumentTPOData.of_default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
}
// if(!selectedTPO)
setSelectedTPO(newTPO);
 
  setState((prevState) => ({
    ...prevState,
    isPollingAllowed:false,
    isStatusCheckAllowed:false,
    isLoading:true,
    selectedTimeFrame:val,
    isIncrementalData:false
  }));
  console.log("time frame changed val End    = ==============================?",val,selectedTimeFrame);
}

const setPollingAllowedVal=(val)=>{
  setState((prevState) => ({
    ...prevState,
    isPollingAllowed:val}));
}


const showVolumeNumber=(val)=>{
  setVolumeNumberVisible(val);
}

const setShowGlobalVolume=(val)=>{
  setGlobalVolumeVisible(val)
}

const setShowLivePanel=(val)=>{
  setLivePanelVisible(val)
}

const setShowBottomTable=(val)=>{
  setBottomTableVisible(val)
}

const showProfileVolumeNumber=(val)=>{
  setProfileVolumeNumberVisible(val);
}

const showProfileVolume=(val)=>{
  setPerProfileVolumeVisible(val)
}
const showTPOOnVolume=(val)=>{
  setTpoOnVolume(val);
}
const showOnlyVolumeProfile=(val)=>{
  setOnlyVolumeProfile(val);
}

const autocenterChart=(val)=>{
  setAutocenter(val);
}

const handleViewStateChange=(state)=>{
  setViewState(state);
  // setIsDirty(true);
}

const handleTPOBasedVA=(val)=>{
  setTPOBasedVA(val);
}

const resetChart=()=>{
setToggleReset(!toggleReset)
}

const unselectProfiles=()=>{
  console.log("unselect profile chart container");
setToggleUnselect(!toggleUnselect)
}

const setRightContainerData=(bshow,metaData,historicalData,selectedProfilesIndex,isLiveSession)=>{
  console.log("livepanel setRightContainerData show,meta,historicalData,selectedIndex,isLiveSession",bshow,metaData,historicalData,selectedProfilesIndex,isLiveSession)
  function compareNumbers(a, b) {
    return b - a;
  }
  let sortedArray=[...selectedProfilesIndex]
  sortedArray.sort(compareNumbers);
  console.log("livepanel calling MP setRightPanel");
  props.setRightPanel(bshow,metaData,historicalData,sortedArray,isLiveSession);
}

const handleVPType=(val)=>{
setVPType(val);
}

const handleFooterLoaded=()=>{
  setFooterLoaded(true);
}

const handleChartChange=(data)=>{
console.log("handleChartChange",data);
setStateData(data);
setChartType(data.data.chart_type);

}

const loadDefaultMPChart=()=>{
  console.log("OFDataLoad default mp chart start",selectedInstrument)
  // setSelectedInstrument(undefined);
  setState((prevState)=>({
    ...prevState,
    apiData:undefined,
    bookmarkData:undefined,
    instrument:undefined,
    chartData:undefined,
    isPollingAllowed:false,
    isStatusCheckAllowed:false,
    isFirtLoadComplete:false,

    // isJsonLoaded:false,
  }))
  setJsonData(undefined);
  console.log("Load default mp chart end",selectedInstrument)
  if (searchParams.has('bookmarkID') && props.val==0) {
    searchParams.delete('bookmarkID');
    setSearchParams({"symbol":selectedInstrument})
    // setSearchParams(searchParams);
  }
  setToggleMPLoading(!toggleMPLoading);
  // loadData();

}

useEffect(()=>{
  selectedInstrumentRef.current=selectedInstrument;
console.log("useeffect selectedinstrument",selectedInstrument,selectedInstrumentRef.current);
},[selectedInstrument])

const loadBookmark=(bookmark)=>{
  console.log("ofdata loadbookmark")
  setState((prevState)=>({
    ...prevState,
    apiData:undefined,
    bookmarkData:undefined,
    isFirtLoadComplete:false,
    isJsonLoaded:false,
    chartData:undefined,
    isPollingAllowed:false,
    isStatusCheckAllowed:false
  }))
  setIsDirty(false)
  setFooterLoaded(false)
  loadAutoSaveData(bookmark.id);
  if(props.val==0)
  setSearchParams({"bookmarkID": bookmark.id})
}

const deleteAutosavedBookmark=()=>{
  setOpenAutosaveDeleteDialog(false);
  const auto_saved_links=JSON.parse(localStorage.getItem('auto_saved_links'));
  const id =auto_saved_links[selectedInstrument];
  if(id!=undefined && id!=null){
    let data={
      "id": id
    }
  
    executeAPIDelete(URL.DELETE_BOOKMARK,"DELETE",data)
}

}

const cancelAutosaveDelete=()=>{
  setOpenAutosaveDeleteDialog(false)
}

const clearAutosave=()=>{
  setOpenAutosaveDeleteDialog(true);
}



//delete api response handler
useEffect(()=>{
  if(loadedDelete){
    console.log("Delete response ========>",responseDataDelete);
    if(responseDataDelete!=null){
      setMsgState({open:true,msg:`${TEXT_MSGS.CLEAR_AUTOSAVED_BOOKMARK_SUCCESS}`,severity:"success"});
      
      // let updatedData= bookmarkData.filter(item => item.id!=deletedId);
      let auto_saved_links=JSON.parse(localStorage.getItem('auto_saved_links'));
      delete auto_saved_links[selectedInstrument];
      localStorage.setItem('auto_saved_links', JSON.stringify(auto_saved_links));
      setState((prevState)=>({
        ...prevState,
        apiData:undefined,
        bookmarkData:undefined,
      }))
      setJsonData(undefined);
      
      setReloadInstrument(selectedInstrument);
      setSelectedInstrument(undefined)
 
     }else if(errorDelete!==null){
      
      if(errorDelete?.response?.status === 401 || errorDelete?.response?.status === 403){
        console.log("status received =",errorDelete?.response?.status)
        navigate(from, { replace: true });
      }else
      setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
    }
    resetDelete();
    
  }

},[loadedDelete,responseDataDelete])

const handleDirty=(val)=>{
  setIsDirty(val);
}

const updateBookmarkData=(data)=>{
  setState((prevState)=>({
    ...prevState,
    // apiData:undefined,
    bookmarkData:data,
    // isFirtLoadComplete:false,
    isJsonLoaded:true,
    // chartData:undefined,
    // isPollingAllowed:false
  }))
  setJsonData(undefined)
  
}

const showPriceTable=()=>{
  console.log("showTable=",showTable);
    setShowTable(true)
}

const showSubscribeModal=(isCustom,msg)=>{
  props.openSubscribeModal(isCustom,msg);
}



 const openSunscriptionModal=(isCustom,msg)=>{
  props.openSubscribeModal(isCustom,msg);

 }

 const openSettings=()=>{
  setShowSettings(true);
 }

 const cancelSettings=()=>{
  setShowSettings(false);
 }

 const saveSettings=(settings)=>{
  setShowSettings(false);
  console.log("Candle Settings save settings in OF chart container",settings);
  setUserSettings(settings);
 }

 useEffect(()=>{
  console.log("Candle Settings useEffect called");
 },[userSettings])


 const setChartRenderedState=(val)=>{
  setState((prevState)=>({
    ...prevState,
    chartRendered:val
  }))

 }
  return (
    <>
     {chartType==CHART_TYPE.OF_CHART?
      <>
      {isJsonLoaded?
      <CandlestickHeader chartStateData={props.chartStateData} resetRightPanel={props.resetRightPanel} userSettings={userSettings} symbol={instrument} selectedTimeFrame={selectedTimeFrame}
        instrumentChange={handleInstrumentChange} tpo={selectedTPO} tpoChange={handleTpoChange} jsonData={jsonData} bookmarkData={bookmarkData} loadDefaultMPChart={loadDefaultMPChart}
        basicUser={props.basicUser} proUser={props.proUser} saveChart={()=>setToggleSave(!toggleSave)} saveBookmark={()=>setToggleBookmark(!toggleSaveBookmark)} clearAutosave={clearAutosave}
         reloadInstrument={reloadInstrument} handleDirty={handleDirty} isDirty={isDirty} showTable={showTable} levelData={levelData} openSunscriptionModal={openSunscriptionModal}
        />
        :<></>
      }
      <div style={{paddingTop:`${CONSTANTS.FIXED_HEADER_HEIGHT}px`,width:"100%",height:"100%"}}>
       {isLoading? 
          <div  style={{textAlign:"center", width:"100%",height:"100%"}}><CircularProgress sx={{marginTop:"20%"}}/>
          </div>:
          <>
          {chartData!=undefined && footerLoaded?
          <div >
          {/* {!showTable? */}

          <CandlestickChart showLoginPopup={props.showLoginPopup} key={props.key} val={props.val} chartScreenType={props.chartScreenType} id={props.id} chartStateData={props.chartStateData} userSettings={userSettings} liveData={liveData}
           volumeNumberVisible={volumeNumberVisible} selectedInstrumentData={selectedInstrumentData} globalVolumeVisible={globalVolumeVisible}
            selectedInstrumentTPOData={selectedInstrumentTPOData} chartData={chartData} isInstrumentLive={isInstrumentLive} 
          instrument={selectedInstrument} tpo={selectedTPO} selectedTimeFrame={selectedTimeFrame}
          setPollingAllowedVal={setPollingAllowedVal} isTradingSessionActive={isTradingSessionActive}
          profileVolumeVisible={perProfilelVolumeVisible}
          isPollingAllowed={isPollingAllowed}
          profileVolumeNumberVisible={perProfileVolumeNumberVisible}
          onlyVolumeProfile={onlyVolumeProfile}
          tpoOnVolume={tpoOnVolume}
          viewState={viewState}
          tpoBasedVA={tpoBasedVA}
          resetChart={toggleReset}  
          unselectProfiles={toggleUnselect}
          max_cmp_days={parseInt(userSettings.data.max_ndays_for_composite)}
          contracts={userSettings.data.data_in_contracts}
          locale_string={userSettings.data.number_format_locale}
          setRightContainerData={setRightContainerData}
          resetRightPanel={props.resetRightPanel}
          basicUser={props.basicUser}
          saveChart={toggleSave}
          saveBookmark={toggleSaveBookmark}
          vpType={vpType}
          bookmarkData={bookmarkData}
          handleDirty={handleDirty}
          updateBookmarkData={updateBookmarkData}
          showPriceTable={showPriceTable}
          openSubscribeModal= {showSubscribeModal}
          levelData={levelData}
          boundClass={props.boundClass}
          livePanelVisible={livePanelVisible}
          bottomTableVisible={bottomTableVisible}
          setShowLivePanel={setShowLivePanel}
          setChartRenderedState={setChartRenderedState}
          autoCenter={autoCenter}/>
         
         
          </div>
          :<>
          
          </>
          }
          </>
       }
        {/* <Divider orientation="vertical" sx={{width:"100%",height:'2px'}} /> 
        <MPChartRightPanel/> */}
        {!isLoading && chartData!=undefined && isJsonLoaded? 
          <CandlestickFooter chartType={chartType}chartStateData={props.chartStateData} userSettings={userSettings} selectedInstrumentData={selectedInstrumentData} changeTimeFrame={changeTimeFrame}setAutocenter={autocenterChart} 
          setShowGlobalVolume={setShowGlobalVolume}  timeFrame={selectedTimeFrame}
          showVolumeNumber={showVolumeNumber}openStockChart={openStockChart}
          showPerProfileVolumeNumber={showProfileVolumeNumber}
          showPerProfilelVolume={showProfileVolume}
          showTPOOnVolume={showTPOOnVolume}
          showOnlyVolumeProfile={showOnlyVolumeProfile}
          handleViewStateChange={handleViewStateChange}
          showTPOBasedVA={handleTPOBasedVA}
          selectedInstrument={selectedInstrument}
          openLineChart={openLineChart}
          resetChart={resetChart}
          unselectProfiles={unselectProfiles}
          basicUser={props.basicUser}
          resetRightPanel={props.resetRightPanel}
          jsonData={jsonData} 
          bookmarkData={bookmarkData}
          handleVPType={handleVPType}
          handleFooterLoaded={handleFooterLoaded}
          loadBookmark={loadBookmark}
          auth={auth}
          showPriceTable={showPriceTable}
          showTable={showTable}
          handleDirty={handleDirty}
          livePanelVisible={livePanelVisible}
          setShowLivePanel={setShowLivePanel}
          bottomTableVisible={bottomTableVisible}
          setShowBottomTable={setShowBottomTable}
          openSubscribeModal= {showSubscribeModal}
          openOFChart={openOFChart}
          openMPChart={openMPChart}
          openSettings={openSettings}/>:
         <></>}
      </div>
        {openAutosaveDeleteDialog?
      <ConfirmActionDialog open={true} cancelHandler={cancelAutosaveDelete} saveHandler={deleteAutosavedBookmark} 
          title={TEXT_MSGS.CLEAR_AUTOSAVED_TITLE} description={TEXT_MSGS.CLEAR_AUTOSAVED_BOOKMARK}></ConfirmActionDialog>    :
      <></>
      }  
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
    </>:
    <>
     {chartType==CHART_TYPE.MARKET_PROFILE?
      <ChartContainer  showLoginPopup={props.showLoginPopup} id={props.id} val={props.val}  setChartLoaded={props.setChartLoaded} boundClass={props.boundClass} chartScreenType={props.chartScreenType} setChartType={props.setChartType} chartStateData={props.chartStateData} symbol={selectedInstrument} 
      resetRightPanel={props.resetRightPanel} setRightPanel={props.setRightPanel} openSubscribeModal={props.openSubscribeModal}  basicUser={props.basicUser}  proUser={props.proUser}>
       
     {/* <ChartContainer  id={props.id} val={props.val} boundClass={props.boundClass} setChartType={props.setChartType} setCurrentChartType={setCurrentChartType}chartStateData={props.chartStateData} symbol={selectedInstrument} 
     resetRightPanel={props.resetRightPanel} chartScreenType={props.chartScreenType} setRightPanel={props.setRightPanel} chartType={chartType} openSubscribeModal={props.openSubscribeModal}  basicUser={props.basicUser}  proUser={props.proUser}> */}
      
     </ChartContainer>:
     <></>
    }
    {chartType==CHART_TYPE.LINE_CHART?
    // <LineChart id={props.id} val={props.val} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></LineChart>
    <LineChartContainer
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
  ></LineChartContainer>
    :
    <></>}
    {chartType==CHART_TYPE.HV_CHART?
    <StockChartHV id={props.id} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></StockChartHV>:
    <></>}
     {chartType==CHART_TYPE.BAR_CHART?
    // <BarChart id={props.id} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></BarChart>
    <BarChartContainer
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
  />
    :
    <></>}
     {chartType!=CHART_TYPE.MARKET_PROFILE && chartType!=CHART_TYPE.LINE_CHART && chartType!=CHART_TYPE.BAR_CHART?
    <MPChartFooter chartType={chartType} chartStateData={props.chartStateData} userSettings={userSettings} selectedInstrumentData={selectedInstrumentData} changeTimeFrame={changeTimeFrame}setAutocenter={autocenterChart} 
          setShowGlobalVolume={setShowGlobalVolume}  timeFrame={selectedTimeFrame}
          showVolumeNumber={showVolumeNumber}openStockChart={openStockChart}
          showPerProfileVolumeNumber={showProfileVolumeNumber}
          showPerProfilelVolume={showProfileVolume}
          showTPOOnVolume={showTPOOnVolume}
          showOnlyVolumeProfile={showOnlyVolumeProfile}
          handleViewStateChange={handleViewStateChange}
          showTPOBasedVA={handleTPOBasedVA}
          selectedInstrument={selectedInstrument}
          openLineChart={openLineChart}
          basicUser={props.basicUser}
          handleVPType={handleVPType}
          handleFooterLoaded={handleFooterLoaded}
          handleChartChange={handleChartChange}
          auth={auth}
          showPriceTable={showPriceTable}
          showTable={showTable}
          openSubscribeModal= {showSubscribeModal}
         openOFChart={openOFChart}
          openMPChart={openMPChart}/>
          :
          <></>
     }
    </>
    }   

     {showSettings?
     <CandleSettings plot={"candle"} open={true} proUser={props.proUser} selectedInstrumentData={selectedInstrumentData} instrument={selectedInstrument} cancelHandler={cancelSettings} saveHandler={saveSettings} />    :
     <></>
    }  
    </>
       
   
   
  )
}

export default CandlestickContainer

