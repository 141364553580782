import React, {useState,useEffect,useRef,useContext} from 'react'



import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '../../common/utility/helperFunc';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { VA_TYPE,VP_TYPE,CHART_SCREEN_TYPE,CHART_TYPE,RIGHT_PANEL_CONTAINER_WIDTH } from '../../common/utility/mp_chart_constants';
// import OrderflowContainer from './orderflow_container';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS } from '../../common/utility/constant';
import './styles/styles.scss';
import SubscriptionPopup from './components/subscription_popup';
import MPChartRightPanelContainer from './components/mp_chart_right_container';
import MPChartRightPanel from './components/mpchart_right_panel';
import LoginModal from './components/login_popups';
import CandlestickContainer from './candlestick_container';



const CandlestickCharts = (props) => {
  
  // console.log("props==========================>",props);
  const windowSize=useWindowSize();
  const { auth } = useAuth();
  const bookmarkID = useQuery("bookmarkID");
  const [chartScreenType,setChartScreenType]=useState(CHART_SCREEN_TYPE.FULL_CHART)
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isProUser,setIsProUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false)
  const [showPopup,setShowPopup]=useState(false)
  const [showProPopup,setShowProPopup]=useState(false)
  const {userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [rightConatianerState, setrRghtConatianerState] = useState({
    rightConatianerVisible:false,
    metadata:undefined,
    histoticalData:undefined,
    selectedIndexes:[],
    isLiveSession:false,
    showLive:false,
  })
  const [parentChartType,setParentChartType]=useState(CHART_TYPE.MARKET_PROFILE);
  const {rightConatianerVisible,metadata,histoticalData,selectedIndexes,isLiveSession,showLive}=rightConatianerState;
  const [toggleLiveButton,setToggleLiveButton]=useState(false);
  const fontFile = new FontFace(
    "Inconsolata",
    'url(https://fonts.gstatic.com/s/inconsolata/v31/QlddNThLqRwH-OJ1UHjlKENVzlm-WkL3GZQmAwPyya15.woff2) format("woff2")',
    { stretch: "50% 200%" }
  );

  // console.log("locale set: ", userSettings.data.number_format_locale)
  const [subscriptionPopupData,setSubscriptionPopupData]=useState({isCustom:false,msg:""});
  const [ChartContainerState,setChartContainerState]=useState({
    loaded:false,
    chartStateData:[
    {
      top:0,
      left:0,
      height:0,
      width:0,
      bookmarkID:bookmarkID,
      type:CHART_TYPE.OF_CHART,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.OF_CHART,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.OF_CHART,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.OF_CHART,
      showPriceTable:false
    },
    {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      type: CHART_TYPE.OF_CHART,
      showPriceTable: false,
    },
    {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      type: CHART_TYPE.OF_CHART,
      showPriceTable: false,
    },
  ]
  })  
  
  const {chartStateData,loaded}=ChartContainerState;

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  const { open, msg, severity } = msgState;

  const [isLiveEnabled,setIsLiveEnabled]=useState(false);
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  
  const setChartLoaded=(val)=>{
    // console.log("HelloTest val 2=",val)
    setIsLiveEnabled(val);
  }

  useEffect(() => {
    document.fonts.add(fontFile);
    document.title = "MP Charts";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    document.head.appendChild(link);
    var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', 'Orderflow charts. Futures and Options. NIFTY. BANKNIFTY. FINNIFTY. MIDCPNIFTY. Stock Futures and Options.');
    document.head.appendChild(meta);
  }, []);

  useEffect(()=>{
    // let width=window.innerWidth-51;
    // let height=window.innerHeight - 48;
    // let data=chartStateData[0];
    // data.top=0;
    // data.left=0;
    // data.height=height;
    // data.width=width;
    // chartStateData[0]=data;
    // setChartContainerState((prevState) => ({
    //   // ...prevState,
    //   loaded:true,
    //   chartStateData:chartStateData}));
    const rightMargin=(rightConatianerVisible?(RIGHT_PANEL_CONTAINER_WIDTH+51):51)
    let leftData=chartStateData[0];
    let width=Math.floor((window.innerWidth-rightMargin));
    let height=Math.floor((window.innerHeight - 48));
    leftData.top=0;
    leftData.left=0;
    leftData.height=height;
    leftData.width=width;
    chartStateData[0]=leftData;
    console.log("chartdata=",chartStateData)
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:chartStateData}));


},[])
  /**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};


const handleChartScreenType=(val)=>{
  console.log("chart screen type changed val=",val);
  setChartContainerState((prevState) => ({
    ...prevState,
    loaded:false,
    }));
   setChartScreenType(val); 
   const rightMargin=(rightConatianerVisible?(RIGHT_PANEL_CONTAINER_WIDTH+51):51)
   if(val==CHART_SCREEN_TYPE.FULL_CHART){
    let leftData=chartStateData[0];
    let width=Math.floor((window.innerWidth-rightMargin));
    let height=Math.floor((window.innerHeight - 48));
    leftData.top=0;
    leftData.left=0;
    leftData.height=height;
    leftData.width=width;
    chartStateData[0]=leftData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))

    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

   }
  else if(val==CHART_SCREEN_TYPE.TWO_COLUMNS){
    setrRghtConatianerState((prevState) => ({
        ...prevState,
        rightConatianerVisible:false}));

    let leftData=chartStateData[0];
    let rightData=chartStateData[1];
    let width=Math.floor((window.innerWidth-51)/2);
    let height=Math.floor((window.innerHeight - 48));
    leftData.top=0;
    leftData.left=0;
    leftData.height=height;
    leftData.width=width;
    chartStateData[0]=leftData;
    rightData.top=0;
    rightData.left=leftData.width;
    rightData.height=height;
    rightData.width=width;
    chartStateData[1]=rightData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }
  else if(val==CHART_SCREEN_TYPE.TWO_ROWS){
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible:false}));

    let topData=chartStateData[0];
    let bottomData=chartStateData[2];
    let width=Math.floor((window.innerWidth-51));
    let height=Math.floor((window.innerHeight - 48)/2);
    topData.top=0;
    topData.left=0;
    topData.height=height;
    topData.width=width;
    chartStateData[0]=topData;
    bottomData.top=topData.height;
    bottomData.left=0;
    bottomData.height=height;
    bottomData.width=width;
    chartStateData[2]=bottomData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }
  else if(val==CHART_SCREEN_TYPE.GRID){
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible:false}));

    let topLeftData=chartStateData[0];
    let topRightData=chartStateData[1];
    let bottomLeftData=chartStateData[2];
    let bottomRightData=chartStateData[3];
    let width=Math.floor((window.innerWidth-51)/2);
    let height=Math.floor((window.innerHeight - 48)/2);
    topLeftData.top=0;
    topLeftData.left=0;
    topLeftData.height=height;
    topLeftData.width=width;
    chartStateData[0]=topLeftData;

    topRightData.top=0;
    topRightData.left=topLeftData.width;
    topRightData.height=height;
    topRightData.width=width;
    chartStateData[1]=topRightData;
    
    bottomLeftData.top=topLeftData.height;
    bottomLeftData.left=0;
    bottomLeftData.height=height;
    bottomLeftData.width=width;
    chartStateData[2]=bottomLeftData;
    
    bottomRightData.top=topLeftData.height;
    bottomRightData.left=topLeftData.width;
    bottomRightData.height=height;
    bottomRightData.width=width;
    chartStateData[3]=bottomRightData;
    
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }else if (val === CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW) {
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible: false,
    }))

    let leftData = chartStateData[0]
    let rightData = chartStateData[1]
    let topThirdCol = chartStateData[4]

    let width = Math.floor((window.innerWidth - 51) / 3)
    let height = Math.floor(window.innerHeight - 48)
    // first
    leftData.top = 0
    leftData.left = 0
    leftData.height = height
    leftData.width = width
    chartStateData[0] = leftData

    rightData.top = 0
    rightData.left = leftData.width
    rightData.height = height
    rightData.width = width
    chartStateData[1] = rightData

    // third container
    topThirdCol.top = 0
    topThirdCol.left = leftData.width + rightData.width
    topThirdCol.height = height
    topThirdCol.width = width
    chartStateData[4] = topThirdCol

    console.log('chartdata=', chartStateData)
    let newState = JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded: true,
      chartStateData: newState,
    }))
  } else if (val === CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS) {
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible: false,
    }))

    let topLeftData = chartStateData[0]
    let topRightData = chartStateData[1]
    let bottomLeftData = chartStateData[2]
    let bottomRightData = chartStateData[3]
    let topThirdCol = chartStateData[4]
    let bottomThirdCol = chartStateData[5]

    let width = Math.floor((window.innerWidth - 51) / 3)
    let height = Math.floor((window.innerHeight - 48) / 2)

    topLeftData.top = 0
    topLeftData.left = 0
    topLeftData.height = height
    topLeftData.width = width
    chartStateData[0] = topLeftData

    topRightData.top = 0
    topRightData.left = topLeftData.width
    topRightData.height = height
    topRightData.width = width
    chartStateData[1] = topRightData

    bottomLeftData.top = topLeftData.height
    bottomLeftData.left = 0
    bottomLeftData.height = height
    bottomLeftData.width = width
    chartStateData[2] = bottomLeftData

    bottomRightData.top = topLeftData.height
    bottomRightData.left = topLeftData.width
    bottomRightData.height = height
    bottomRightData.width = width
    chartStateData[3] = bottomRightData

    // last col 1st row
    topThirdCol.top = 0
    topThirdCol.left = topLeftData.width + topRightData.width
    topThirdCol.height = height
    topThirdCol.width = width
    chartStateData[4] = topThirdCol

    // last col 2nd row
    bottomThirdCol.top = topThirdCol.height
    bottomThirdCol.left = bottomLeftData.width + bottomRightData.width
    bottomThirdCol.height = height
    bottomThirdCol.width = width
    chartStateData[5] = bottomThirdCol

    console.log('chartdata=', chartStateData)
    let newState = JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded: true,
      chartStateData: newState,
    }))
  }
}

useEffect(()=>{
  handleChartScreenType(chartScreenType)
},[windowSize,rightConatianerVisible]);

const showSubscribeModal=(isCustom=false,msg)=>{
  if(isCustom){
    setSubscriptionPopupData({isCustom:true,msg:msg});
    setShowProPopup(true);
  }else{
    setSubscriptionPopupData({isCustom:false,msg:""});
    setShowProPopup(true);
  }
}

const showSubscribeProModal=(isCustom=false,msg)=>{
  if(isCustom){
    setSubscriptionPopupData({isCustom:true,msg:msg});
    setShowProPopup(true);
  }else{
    setSubscriptionPopupData({isCustom:false,msg:""});
    setShowProPopup(true);
  }
}

// const openRightPanel=(bshow,metaData,historicalData,selectedIndexes,isLiveSession)=>{
//   // console.log("live panel Market Profile:openRightPanel==> show,meta,historicalData,selectedIndex",bshow,metaData,historicalData,selectedIndexes,isLiveSession)
//   if(bshow){
//     // console.log("live panel Market Profile:openRightPanel==> 1");
//   setrRghtConatianerState((prevState) => ({
//     ...prevState,
//     showLive:false,
//     rightConatianerVisible:bshow,
//     metadata:metaData,
//     histoticalData:historicalData,
//     selectedIndexes:selectedIndexes,
//     isLiveSession:isLiveSession,
//   }));
//   setToggleLiveButton(!toggleLiveButton);
// }else{
//   if(showLive){   //update live profile state in container
//     // console.log("live panel Market Profile:openRightPanel==> 2");
//     setrRghtConatianerState((prevState) => ({
//       ...prevState,
//       // showLive:false,
//       metadata:metaData,
//       histoticalData:historicalData,
//       selectedIndexes:selectedIndexes,
//       isLiveSession:isLiveSession
//     }));
//   }
//   else if(rightConatianerVisible){       //update live profile in container when open through context menu
//     // console.log("live panel Market Profile:openRightPanel==> 3");
//     setrRghtConatianerState((prevState) => ({
//       ...prevState,
//       // showLive:false,
//       metadata:metaData,
//       histoticalData:historicalData,
//       // selectedIndexes:selectedIndexes,
//       isLiveSession:isLiveSession
//     }));
//   }
//   else {       //update live profile in container when open through context menu
//     // console.log("live panel Market Profile:openRightPanel==> 4",isLiveSession);
//     setrRghtConatianerState((prevState) => ({
//       ...prevState,
//       // showLive:false,
//       metadata:metaData,
//       histoticalData:historicalData,
//       selectedIndexes:selectedIndexes,
//       isLiveSession:isLiveSession
//     }));
//   }
// }
// }
const openRightPanel=(bshow,metaData,historicalData,selectedIndexes,isLiveSession,isUpdateDataOnly=false)=>{
  const finalIndexes = selectedIndexes.sort((a, b) => b - a);
  console.log("live panel Market Profile:openRightPanel==> show,meta,historicalData,selectedIndex",bshow,metaData,historicalData,selectedIndexes,isLiveSession)
  if(!isUpdateDataOnly){
      if(bshow){
        console.log("live panel Market Profile:openRightPanel==> 1");
      setrRghtConatianerState((prevState) => ({
        ...prevState,
        showLive:false,
        rightConatianerVisible:bshow,
        metadata:metaData,
        histoticalData:historicalData,
        selectedIndexes:finalIndexes,
        isLiveSession:isLiveSession,
      }));
      setToggleLiveButton(!toggleLiveButton);
    }else{
      if(showLive){   //update live profile state in container
        console.log("live panel Market Profile:openRightPanel==> 2");
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          selectedIndexes:finalIndexes,
          isLiveSession:isLiveSession
        }));
      }
      else if(rightConatianerVisible){       //update live profile in container when open through context menu
        console.log("live panel Market Profile:openRightPanel==> 3");
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          // selectedIndexes:selectedIndexes,
          isLiveSession:isLiveSession
        }));
      }
      else {       //update live profile in container when open through context menu
        console.log("live panel Market Profile:openRightPanel==> 4",isLiveSession);
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          selectedIndexes:finalIndexes,
          isLiveSession:isLiveSession
        }));
      }
    }
  }else{
    console.log("live panel Market Profile:openRightPanel==> 5",isLiveSession,metaData);
    if(selectedIndexes && selectedIndexes.length>0)
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      histoticalData:historicalData,
      //  selectedIndexes:finalIndexes,
      metadata:metaData,

    }));
    else
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      histoticalData:historicalData,
      //  selectedIndexes:selectedIndexes,
       metadata:metaData,

    }));

  }
}

const closeRightPanelModal=()=>{
  setrRghtConatianerState((prevState) => ({
    ...prevState,
    showLive:false,
    rightConatianerVisible:false,
  }));
}

const openLivePanel=(isAllowed)=>{
  console.log("openlivepanel is called",isAllowed,isLiveSession)
  if(isAllowed && parentChartType==CHART_TYPE.MARKET_PROFILE){
    console.log("opeLive panel state=",rightConatianerState);
    if(histoticalData){
      let index=[];
      if(histoticalData)
      index.push(histoticalData.profilesData.length-1)

    setrRghtConatianerState((prevState) => ({
      ...prevState,
      showLive:true,
      selectedIndexes:index,
      rightConatianerVisible:true,
    }));
  }
    else{
    //   setMsgState({open:true,msg:`${TEXT_MSGS.MARKET_NOT_LIVE}`,severity:"info"});
      setToggleLiveButton(!toggleLiveButton);
     }
    }else{
      if(parentChartType==CHART_TYPE.MARKET_PROFILE)
      setMsgState({open:true,msg:`${TEXT_MSGS.LIVE_PANEL_NOT_SUPPORTED}`,severity:"info"});
      else{
      
      setMsgState({open:true,msg:`${TEXT_MSGS.LIVE_PANEL_NOT_SUPPORTED_LC}`,severity:"info"});
      }
     
      setToggleLiveButton(!toggleLiveButton);
  }
}

const resetRightPanel=()=>{
  console.log("live panel handlechange Reset right panel called")
  setrRghtConatianerState((prevState) => ({
    ...prevState,
  rightConatianerVisible:false,
  metadata:undefined,
  histoticalData:undefined,
  selectedIndexes:[],
  isLiveSession:false,
  showLive:false,
}));
}

const setLoginPopup=(val=true,msg=TEXT_MSGS.LOGIN_POPUP_MSG)=>{
  setLoginMsg(msg);
  // console.log("TEST LOGIN Market profile 1",msg)
  setShowLoginPopup(val);
}

  return (
    <>

      <div style={{width:"100%",height:"100%"}}>
        {loaded?
        <>
          {/* <div style={{display:'flex'}}> */}
          {/* className="chart-container" */}
          <div className="of_chart-container-new "  setChartLoaded={setChartLoaded}  style={{height:`calc(${chartStateData[0].height}px)`, width:`calc(${chartStateData[0].width}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={0} val={0} setChartLoaded={setChartLoaded} id="container_1" boundClass=".of_chart-container-new" setChartType={setParentChartType}resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[0]} selectedIndexes={selectedIndexes}></CandlestickContainer>
          </div>

          {chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS ||chartScreenType==CHART_SCREEN_TYPE.GRID|| chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS  ?
          <div className="of_chart-container-new1" style={{visibility:(chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS ||chartScreenType==CHART_SCREEN_TYPE.GRID || chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS)?'visible':'hidden', height:`calc(${chartStateData[1].height}px)`, width:`calc(${chartStateData[1].width}px)`, marginLeft:`calc(${chartStateData[1].left}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={1} val={1} id="container_2"   boundClass=".of_chart-container-new1" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[1]}></CandlestickContainer>
          </div>
           :
          <></>}

        {chartScreenType==CHART_SCREEN_TYPE.TWO_ROWS ||chartScreenType==CHART_SCREEN_TYPE.GRID ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="of_chart-container-new2" style={{ height:`calc(${chartStateData[2].height}px)`, width:`calc(${chartStateData[2].width}px)`, marginLeft:`calc(${chartStateData[2].left}px)`, marginTop:`calc(${chartStateData[2].top}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={2} val={2} id="container_3"  boundClass=".of_chart-container-new2" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[2]}></CandlestickContainer>
          </div>
          :
          <></>}
            
          {chartScreenType==CHART_SCREEN_TYPE.GRID ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="of_chart-container-new3" style={{ height:`calc(${chartStateData[3].height}px)`, width:`calc(${chartStateData[3].width}px)`, marginLeft:`calc(${chartStateData[3].left}px)`, marginTop:`calc(${chartStateData[3].top}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={3} val={3} id="container_4"  boundClass=".of_chart-container-new3" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[3]}></CandlestickContainer>
          </div>
          :
          <></>}
           {chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="of_chart-container-new4" style={{ height:`calc(${chartStateData[4].height}px)`, width:`calc(${chartStateData[4].width}px)`, marginLeft:`calc(${chartStateData[4].left}px)`, marginTop:`calc(${chartStateData[4].top}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={4} val={4} id="container_5"  boundClass=".of_chart-container-new4" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[4]}></CandlestickContainer>
          </div>
          :
          <></>}
           {chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="of_chart-container-new5" style={{ height:`calc(${chartStateData[3].height}px)`, width:`calc(${chartStateData[5].width}px)`, marginLeft:`calc(${chartStateData[5].left}px)`, marginTop:`calc(${chartStateData[5].top}px)` }}>
          <CandlestickContainer showLoginPopup={setLoginPopup} key={5} val={5} id="container_6"  boundClass=".of_chart-container-new5" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} proUser={isProUser} chartStateData={chartStateData[5]}></CandlestickContainer>
          </div>
          :
          <></>}
           
           {rightConatianerVisible && chartScreenType==CHART_SCREEN_TYPE.FULL_CHART? 
            <MPChartRightPanelContainer closeModal={closeRightPanelModal}
              metadata={metadata}
              historicalData={histoticalData}
              selectedIndexes={selectedIndexes}
              isLiveSession={isLiveSession}
              isBasicUser={isBasicUser}
              proUser={isProUser}
              pro2User={isProUser}
              showLive={showLive}
              localeString={userSettings.data.number_format_locale}
            />
            :
            <></>
           }

          <MPChartRightPanel toggleLiveButton={toggleLiveButton} isLiveEnabled={isLiveEnabled} resetRightPanel={resetRightPanel}  closeModal={closeRightPanelModal} showLive={showLive} openLivePanel={openLivePanel} isBasicUser={isBasicUser} proUser={isProUser} openSubscribeModal={showSubscribeProModal} chartScreenType={chartScreenType}  basicUser={isBasicUser} setChartScreenType={handleChartScreenType}/>
          
          </>:
          <></>
          }
      </div>
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
      {showPopup?
         <SubscriptionPopup open={showPopup}  data={subscriptionPopupData} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      }
      {showProPopup?
         <SubscriptionPopup open={showProPopup}  data={subscriptionPopupData} proMessage={true} onClose={()=>setShowProPopup(false)}></SubscriptionPopup>  :
         <></>
      }

        {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
    </>
       
   
   
  )
}

export default CandlestickCharts

