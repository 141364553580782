import { useEffect, useState,createContext,useMemo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../common/hooks/useAuth";
import DashboardLayoutContainer from "../../pages/dashboard/components/main_layout";
import { useAxiosWithAuth } from "../../common/api/hooks/useAxiosWithAuth";
import { URL } from "../../common/api/urls";
import { BOOKMARK_CATEGORY_AUTOSAVE,BOOKMARKT_CATEGORY_DATA } from "../../common/utility/constant";
import { useQuery } from "../../common/utility/helperFunc";

/**
 * Wrapper component to manage navigation based on user authentication status
 * @returns the route if the user is authenticated else it will redirect the user to login page
 */
 export const UserSettingsContext = createContext({
    userSettings: undefined,
    setUserSettings: () => {},
  });

const RequireAuth = ({allowedRoles}) => {
    const { auth } = useAuth();
    const location = useLocation();
    const symbol = useQuery("symbol");
    
    const [userSettings, setUserSettings] = useState(undefined);
    const [isLoading, setIsLoading]=useState(true)
    const [cancel, responseData, error, loaded, reset, executeAPI ] = useAxiosWithAuth();
    const [cancelBookamrk, responseDataBookamrk, errorBookamrk, loadedBookamrk, resetBookamrk, executeAPIBookamrk ] = useAxiosWithAuth();
    const [cancelUIData,responseDataUIData, errorUIData, loadedUIData, resetUIData,executeAPIUIData ] = useAxiosWithAuth();
    const [isUIDataLoaded,setUIDataLoaded]=useState(false);
      
    const value = useMemo(
    () => ({ userSettings, setUserSettings }), 
    [userSettings]
    );
    
    console.log("TestRefresh RequireAuth Auth,location=",auth,location);
    
      //get user settings at the start
      useEffect(()=>{
        // console.log("Aloowed roles in Require auth and auth object",allowedRoles,auth)
        if(auth?.accessToken){
          let data={
            "symbol": "NIFTY",
            "instruments": 1,
            "tpoList": 1,
             "additional_instruments": symbol?[symbol]:undefined,
            "dateList":1,
          
          }
          
          executeAPIUIData(URL.MPCHART_UIDATA,"POST",data); 
          executeAPIBookamrk(URL.GET_BOOKMARK_LIST,"GET",{});
          executeAPI(URL.USER_SETTING,"GET",{});
          // executeAPIBookamrk(URL.DELETE_ALL_AUTOSAVED_BOOKMARK,"DELETE",{});
        }
        else{
            setUserSettings({})
            setIsLoading(false);
        }
      },[]);

       //api response handler UI Data
       useEffect(()=>{
        if(loadedUIData){
          // console.log("Require auth:loaded user settings ========>",responseData);
          if(responseDataUIData!=null){
            localStorage.setItem('UIData', JSON.stringify(responseDataUIData));
            setUIDataLoaded(true);
            
            
           }else if(errorUIData!==null){
           
          }
        }
    
      },[loadedUIData,responseDataUIData])
    
      //api response handler
      useEffect(()=>{
        if(loaded){
          // console.log("Require auth:loaded user settings ========>",responseData);
          if(responseData!=null){
            setUserSettings(responseData)
            localStorage.setItem('userSettings', JSON.stringify(responseData));
            const items = JSON.parse(localStorage.getItem('userSettings'));
            console.log("settings in local storage=",items);
            localStorage.setItem('auto_saved_links', JSON.stringify(responseData.data.auto_saved_links));
            
            setIsLoading(false);
           }else if(error!==null){
            setUserSettings({})
            setIsLoading(false);
          }
        }
    
      },[loaded,responseData])

      //api response handler
      useEffect(()=>{
        if(loadedBookamrk){
          // console.log("Require auth:loaded user settings ========>",responseData);
          if(responseDataBookamrk!=null){
            console.log("Bookmark response=",responseDataBookamrk,responseDataBookamrk.data,responseDataBookamrk.data.length)
            let bookmarkCategoryList=[...BOOKMARKT_CATEGORY_DATA];
            let bookmarkList=[];
            for(let i=0;i<responseDataBookamrk.data.length;i++){
              if(responseDataBookamrk.data[i].category!=BOOKMARK_CATEGORY_AUTOSAVE){
                bookmarkList.push(responseDataBookamrk.data[i])
                // if(!bookmarkCategoryList.some(item=>item.category==responseDataBookamrk.data[i].category)){
                  if (!bookmarkCategoryList.find(e => e.name === responseDataBookamrk.data[i].category)) {
                bookmarkCategoryList.push({
                  name:responseDataBookamrk.data[i].category,
                  value:responseDataBookamrk.data[i].category
                })
              }
              }
            }
            if(bookmarkCategoryList.length>0){
              
              localStorage.setItem('bookmark_category', JSON.stringify(bookmarkCategoryList));
              localStorage.setItem("bookmark_list",JSON.stringify(bookmarkList));
            }
          
           }else if(errorBookamrk!==null){
            // setUserSettings({})
            // setIsLoading(false);
          }
        }
    
      },[loadedBookamrk,responseDataBookamrk])

      
      
       return (
        auth?.accessToken && auth?.role?.find(role =>allowedRoles?.includes(role)) 
            ?   userSettings!=undefined && isUIDataLoaded && !isLoading?
                <UserSettingsContext.Provider value={value}>
                    <DashboardLayoutContainer>
                    <Outlet />
                    </DashboardLayoutContainer>
                </UserSettingsContext.Provider>:
                <></>
            :
            auth?.accessToken?
                <Navigate to="/unauthorized" state={{ from: location }} replace />      
                :   
                <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;