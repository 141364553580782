import * as am5 from "@amcharts/amcharts5"
import dark_theme from "@amcharts/amcharts5/themes/Dark"
import * as am5xy from "@amcharts/amcharts5/xy"
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker } from "antd"
import dayjs from "dayjs"
import React, { useContext, useLayoutEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../../../common/hooks/useAuth"
import {
  DateFormat,
  MIN_CHART_WIDTH,
  MenuProps,
  NO_OF_DAYS,
  USER_RESOURCES_LIST,
  USER_ROLE_FIELDS,
  WATERMARK_CONFIG,
} from "../../../common/utility/constant"
import InstrumentsDropdown from "./InstrumentsDropdown"

// import { Percent, PortableWifiOffSharp } from '@mui/icons-material';

const FuturesLineChartContainer = (props) => {
  const seriesVWAPRef = useRef(null)
  const seriesVPOCRef = useRef(null)
  const seriesGFRef = useRef(null)
  const seriesGFHRef = useRef(null)
  const seriesGFLRef = useRef(null)
  const seriesGRHRef = useRef(null)
  const seriesGRLRef = useRef(null)
  const seriesGMRef = useRef(null)
  const seriesOIRef = useRef(null)
  const seriesVolRef = useRef(null)
  const seriesTrpRef = useRef(null)
  const candlestickSeriesRef = useRef(null)
  const seriesPremiumRef = useRef(null)
  const seriesPcrRef = useRef(null)
  // const seriesSyntheticPremRef = useRef(null);

  const seriesPutOIRef = useRef(null)
  const seriesCallOIRef = useRef(null)
  const seriesTotalOIRef = useRef(null)
  const seriesNetOIRef = useRef(null)

  const seriesPutDOIRef = useRef(null)
  const seriesCallDOIRef = useRef(null)
  // const seriesTotalDOIRef = useRef(null);
  const seriesNetDOIRef = useRef(null)

  const seriesPutDOIXRef = useRef(null)
  const seriesCallDOIXRef = useRef(null)
  const seriesTotalDOIXRef = useRef(null)
  const seriesNetDOIXRef = useRef(null)

  const { auth } = useAuth()
  const [isBasicUser, setIsBasicUser] = useState(
    auth.role.find((el) => el == USER_ROLE_FIELDS.FAST_DATA) ? false : true
  )
  const [isProUser, setIsProUser] = useState(
    auth.role.find((el) => el == USER_ROLE_FIELDS.ADMIN) ? true : false
  )
  const [nDays, setnDays] = useState(props.nDays)
  const [startDate, setStartDate] = useState(
    props.startDate != "" ? dayjs(props.startDate, DateFormat) : ""
  )
  const [instrumentsData, setInstrumentsData] = useState()
  // const [selectedInstrument, setSelectedInstrument] = React.useState("");
  const [searchText, setSearchText] = React.useState("")
  const [isStocksAllowed, setIsStocksAllowed] = useState(
    auth.resources?.find((el) => el == USER_RESOURCES_LIST.MP_STOCKS)
      ? true
      : false
  )
  const [selectedInstrumentData, setSelectedInstrumentData] = React.useState()
  // const [options,setOptions]=useState();

  // const theme = useTheme();
  const navigate = useNavigate()

  // console.log("CONTAINER BEGINS")
  console.log(props)
  console.log("isProUser", isProUser)

  useLayoutEffect(() => {
    console.log("Future test default layout")
    let root = am5.Root.new(props.id, {
      // useSafeResolution: false  TODO: itest incase charts break on iOS device uncomment it
    })

    root.setThemes([dark_theme.new(root)])
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "zoomX",
        wheelZoomPositionX: 1,
        pinchZoomX: false,
        pinchZoomY: false,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        layout: root.verticalLayout,
      })
    )

    //Refere for zoom and pan https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/

    // uncomment the below line is to hide the chart zoom button
    // chart.zoomOutButton.set("forceHidden", true);

    // add scrollbar to control
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    )

    let rendrerDateTime = am5xy.AxisRendererX.new(root, {
      opposite: true,
    })
    rendrerDateTime.grid.template.setAll({
      location: 0,
      disabled: false,
    })
    rendrerDateTime.ticks.template.setAll({
      length: 8,
      strokeOpacity: 0.2,
      disabled: false,
    })
    rendrerDateTime.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    })

    //create date axis
    let dateTimeAxis;
    if(isProUser) {
      dateTimeAxis = am5xy.GaplessDateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        dateFormats: {
          minute: "d MMM, HH:mm",
          hour: "d MMM, HH",
          day: "d MMM",
        },
        markUnitChange: false,
        tooltipDateFormat: "d MMM, HH:mm",
        minHeight: 20,
        groupData: true,
        groupCount: 800,
        minZoomCount: 400,
        // maxZoomCount: 40,
        // fixAxisSize: false,
        // minGridDistance: 100,
        // minWidth:20,
        // width:20,
        groupIntervals: [
          { timeUnit: "minute", count: 1 },
          // { timeUnit: "minute", count: 2 },
          // { timeUnit: "minute", count: 5 },
          // { timeUnit: "minute", count: 15 },
          // { timeUnit: "minute", count: 30 },
          // { timeUnit: "hour", count: 1 },
        ],
        renderer: rendrerDateTime,
      })
    } else {
      dateTimeAxis = am5xy.GaplessDateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        dateFormats: {
          minute: "d MMM, HH:mm",
          hour: "d MMM, HH:mm",
          day: "d MMM",
        },
        markUnitChange: false,
        tooltipDateFormat: "d MMM, HH:mm",
        minHeight: 20,
        groupData: true,
        groupCount: 800,
        minZoomCount: 1,
        groupIntervals: [
          { timeUnit: "minute", count: 1 },
          { timeUnit: "minute", count: 2 },
          { timeUnit: "minute", count: 5 },
          // { timeUnit: "minute", count: 15 },
          // { timeUnit: "minute", count: 30 },
          // { timeUnit: "hour", count: 1 },
        ],
        renderer: rendrerDateTime,
      })
    }

    //push the datetime axis in the chart
    let xAxis = chart.xAxes.push(dateTimeAxis)

    // let tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   autoTextColor: false,
    //   getLabelFillFromSprite: true,
    //   // labelText: "{name}: {valueY}"
    // });

    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.4,
    // });

    // % height of each panel
    let y1, y2, y3
    ;[y1, y2, y3] = [60, 20, 20]
    if (props.plotParams.get("prem") === "") {
      if (props.plotParams.get("fvol") || props.plotParams.get("oiPlot") !== "")
        [y1, y2, y3] = [70, 30, 0]
      else if (props.plotParams.get("doix")) [y1, y2, y3] = [60, 40, 0]
    } else {
      if (props.plotParams.get("fvol")) [y1, y2, y3] = [50, 30, 20]
      else if (props.plotParams.get("doix")) [y1, y2, y3] = [40, 40, 20]
      else if (props.plotParams.get("prem") === "fut")
        if (isProUser) [y1, y2, y3] = [70, 15, 15]
        else [y1, y2, y3] = [80, 5, 15]
    }

    let valueAxisLhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    )

    let valueAxisLhs2
    if (props.plotParams.get("fvol") || props.plotParams.get("doix")) {
      valueAxisLhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )
    }

    let valueAxisRhs3
    let valueAxisLhs3
    if (props.plotParams.get("prem") !== "") {
      valueAxisLhs3 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y3),
          marginTop: 20,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )

      valueAxisRhs3 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y3),
          marginTop: 10,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      )
    }

    let valueAxisRhs2
    if (
      props.plotParams.get("fvol") ||
      props.plotParams.get("doix") ||
      props.plotParams.get("trp") ||
      isProUser
    ) {
      valueAxisRhs2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          height: am5.percent(y2),
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        })
      )
    }

    let valueAxisRhs1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: valueAxisLhs1,
        height: am5.percent(y1),
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    )

    let valueAxisFuturesRenderer = valueAxisRhs1.get("renderer")
    valueAxisFuturesRenderer.labels.template.setAll({
      paddingLeft: 2,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 5,
      verticalCenter: "bottom",
      fontSize: "0.8em",
      minPosition: 0.01,
      maxPosition: 0.99,
    })

    let seriesVWAP, seriesVPOC
    if (props.plotParams.get("plot") === "fut") {
      seriesVWAP = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "VWAP",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vwap",
          valueXField: "time",
          stroke: am5.color("#128E0B"),
          fill: am5.color("#128E0B"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesVWAP.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesVWAP.get("tooltip").label.set("text", "VWAP: {valueY}")

      seriesVPOC = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "VPOC",
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          valueYField: "vpoc",
          valueXField: "time",
          stroke: am5.color("#AB039D"),
          fill: am5.color("#AB039D"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesVPOC.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesVPOC.get("tooltip").label.set("text", "VPOC: {valueY}")
    }

    let seriesPutOI, seriesCallOI, seriesTotalOI, seriesNetOI
    if (props.plotParams.get("oiPlot") === "oi") {
      seriesPutOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PUT_OI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "put_oi",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesPutOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      seriesPutOI.get("tooltip").label.set("text", "PUT_OI: {valueY}")

      seriesCallOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "CALL_OI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "call_oi",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesCallOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      seriesCallOI.get("tooltip").label.set("text", "CALL_OI: {valueY}")

      seriesTotalOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "TOTAL_OI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "total_oi",
          valueXField: "time",
          stroke: am5.color("#00FFFF"),
          fill: am5.color("#00FFFF"),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesTotalOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      seriesTotalOI.get("tooltip").label.set("text", "TOTAL_OI: {valueY}")

      seriesNetOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "NET_OI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "net_oi",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          fill: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesNetOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      seriesNetOI.get("tooltip").label.set("text", "NET_OI: {valueY}")
    }

    let seriesPutDOI, seriesCallDOI, seriesNetDOI // seriesTotalDOI
    if (props.plotParams.get("oiPlot") === "doi") {
      seriesPutDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PUT_DOI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "put_doi",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesPutDOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesPutDOI.get("tooltip").label.set("text", "PUT_DOI: {valueY}")

      seriesCallDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "CALL_DOI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "call_doi",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesCallDOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesCallDOI.get("tooltip").label.set("text", "CALL_DOI: {valueY}")

      // seriesTotalDOI = chart.series.push(
      //   am5xy.LineSeries.new(root, {
      //     name: "TOTAL_DOI",
      //     xAxis: xAxis,
      //     yAxis: valueAxisLhs1,
      //     valueYField: "total_doi",
      //     valueXField: "time",
      //     stroke: am5.color("#00FFFF"),
      //     fill: am5.color("#00FFFF"),
      //     tooltip: am5.Tooltip.new(root, {})
      //   })
      // );

      // seriesTotalDOI.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      // seriesTotalDOI.get("tooltip").label.set("text", "TOTAL_DOI: {valueY}")

      seriesNetDOI = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "NET_DOI",
          xAxis: xAxis,
          yAxis: valueAxisLhs1,
          valueYField: "net_doi",
          valueXField: "time",
          stroke: am5.color("#FFFFFF"),
          fill: am5.color("#FFFFFF"),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesNetDOI.strokes.template.setAll({
        strokeWidth: 2,
      })

      seriesNetDOI.get("tooltip").label.set("text", "NET_DOI: {valueY}")
    }

    let seriesPutDOIX, seriesCallDOIX, seriesNetDOIX, seriesTotalDOIX
    if (props.plotParams.get("doix")) {
      seriesPutDOIX = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "PE NTM OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "put_doix",
          valueXField: "time",
          stroke: am5.color("#ff00ff"),
          fill: am5.color("#ff00ff"),
          zIndex: 5,
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesPutDOIX.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesPutDOIX.get("tooltip").label.set("text", "PE Trend: {valueY}")

      seriesCallDOIX = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: "CE NTM OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "call_doix",
          valueXField: "time",
          stroke: am5.color("#00FF00"),
          fill: am5.color("#00FF00"),
          zIndex: 5,
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesCallDOIX.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesCallDOIX.get("tooltip").label.set("text", "CE Trend: {valueY}")

      seriesTotalDOIX = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Avg NTM OI Change",
          xAxis: xAxis,
          yAxis: valueAxisLhs2,
          valueYField: "total_doix",
          valueXField: "time",
          stroke: am5.color("#00FFFF"),
          fill: am5.color("#00FFFF"),
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesTotalDOIX.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesTotalDOIX.get("tooltip").label.set("text", "TOTAL_DOIX: {valueY}")

      // seriesNetDOIX = chart.series.push(
      //   am5xy.StepLineSeries.new(root, {
      //     name: "TREND_FUEL",
      //     xAxis: xAxis,
      //     yAxis: valueAxisLhs2,
      //     valueYField: "net_doix",
      //     valueXField: "time",
      //     stroke: am5.color("#FFFFFF"),
      //     fill: am5.color("#FFFFFF"),
      //     zIndex: 5,
      //     tooltip: am5.Tooltip.new(root, {})
      //   })
      // );

      // seriesNetDOIX.strokes.template.setAll({
      //   strokeWidth: 2,
      // });

      // seriesNetDOIX.fills.template.setAll({
      //   visible: true,
      //   fillOpacity: 0.4,
      // });

      // seriesNetDOIX.strokes.template.adapters.add("fill", function(fill, target) {
      //   if (target.dataItem && target.dataItem.get("valueY") < 0) {
      //       return am5.color("#01DF01");  // green
      //   }
      //   else {
      //       return am5.color("#FF0000");  // red
      //   }
      // });

      seriesNetDOIX = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Trend Fuel",
          xAxis: xAxis,
          syncWithAxis: valueAxisLhs2,
          yAxis: valueAxisLhs2,
          valueYField: "net_doix",
          valueYGrouped: "sum",
          valueXField: "time",
          clustered: false,
          // tooltip: am5.Tooltip.new(root, {})
        })
      )

      seriesNetDOIX.columns.template.setAll({
        fillOpacity: 0.3,
        strokeWidth: 0,
        strokeOpacity: 0,
        width: am5.percent(100),
      })

      seriesNetDOIX.columns.template.adapters.add(
        "fill",
        function (fill, target) {
          if (target.dataItem && target.dataItem.get("valueY") < 0) {
            return am5.color("#01DF01") // green
          } else {
            return am5.color("#FF0000") // red
          }
        }
      )

      seriesNetDOIX.columns.template.adapters.add(
        "stroke",
        function (fill, target) {
          if (target.dataItem && target.dataItem.get("valueY") < 0) {
            return am5.color("#01DF01") // green
          } else {
            return am5.color("#FF0000") // red
          }
        }
      )

      // seriesNetDOIX.get("tooltip").label.set("text", "Fuel: {valueY}")
    }

    let seriesGF, seriesGFH, seriesGFL
    if (props.plotParams.get("gf")) {
      if (props.plotParams.get("plot") === "fut") {
        seriesGFH = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "mViz High",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gf_high",
            valueXField: "time",
            stroke: am5.color("#DCFF00"),
            fill: am5.color("#DCFF00"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGFH.strokes.template.setAll({
          strokeWidth: 2,
        })

        seriesGFL = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "mViz Low",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gf_low",
            valueXField: "time",
            stroke: am5.color("#DCFF00"),
            fill: am5.color("#DCFF00"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGFL.strokes.template.setAll({
          strokeWidth: 2,
        })
      } else {
        if (isProUser) {
          seriesGF = chart.series.push(
            am5xy.StepLineSeries.new(root, {
              name: "mViz Level",
              xAxis: xAxis,
              yAxis: valueAxisRhs1,
              valueYField: "mviz_level",
              // valueYGrouped: "mviz_level",
              valueXField: "time",
              stroke: am5.color("#FFFFFF"),
              fill: am5.color("#FFFFFF"),
              // tooltip: am5.Tooltip.new(root, {})
            })
          )

          seriesGF.strokes.template.setAll({
            strokeWidth: 1,
            // strokeDasharray: [5,2],
          })
        }

        seriesGFH = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "mViz High",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "upper_mviz",
            valueYGrouped: "upper_mviz",
            valueXField: "time",
            stroke: am5.color("#DCFF00"),
            fill: am5.color("#DCFF00"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGFH.strokes.template.setAll({
          strokeWidth: 2,
        })

        seriesGFL = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "mViz Low",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "lower_mviz",
            valueYGrouped: "lower_mviz",
            valueXField: "time",
            stroke: am5.color("#DCFF00"),
            fill: am5.color("#DCFF00"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGFL.strokes.template.setAll({
          strokeWidth: 2,
        })
      }
    }

    let seriesGRH, seriesGRL
    if (props.plotParams.get("gr")) {
      if (props.plotParams.get("plot") === "fut") {
        seriesGRH = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Wall High",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gmax_res",
            valueXField: "time",
            stroke: am5.color("#0ffaee"),
            fill: am5.color("#0ffaee"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGRH.strokes.template.setAll({
          strokeWidth: 2,
        })

        seriesGRL = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Wall Low",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gmin_sup",
            valueXField: "time",
            stroke: am5.color("#0ffaee"),
            fill: am5.color("#0ffaee"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGRL.strokes.template.setAll({
          strokeWidth: 2,
        })
      } else {
        seriesGRH = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Wall High",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gwall_high",
            valueYGrouped: "gwall_high",
            valueXField: "time",
            stroke: am5.color("#0ffaee"),
            fill: am5.color("#0ffaee"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGRH.strokes.template.setAll({
          strokeWidth: 2,
        })

        seriesGRL = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Wall Low",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gwall_low",
            valueYGrouped: "gwall_low",
            valueXField: "time",
            stroke: am5.color("#0ffaee"),
            fill: am5.color("#0ffaee"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGRL.strokes.template.setAll({
          strokeWidth: 2,
        })
      }
    }

    let seriesGM
    if (props.plotParams.get("gm")) {
      if (props.plotParams.get("plot") === "fut") {
        seriesGM = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Zero",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gpain",
            valueXField: "time",
            stroke: am5.color("#4f92f7"),
            fill: am5.color("#4f92f7"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGM.strokes.template.setAll({
          strokeWidth: 2,
          strokeDasharray: [10, 6],
        })
      } else {
        seriesGM = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "Gamma Zero",
            xAxis: xAxis,
            yAxis: valueAxisRhs1,
            valueYField: "gzero",
            valueXField: "time",
            valueYGrouped: "gzero",
            stroke: am5.color("#4f92f7"),
            fill: am5.color("#4f92f7"),
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesGM.strokes.template.setAll({
          strokeWidth: 2,
          strokeDasharray: [10, 6],
        })
      }
    }

    let seriesOI, seriesVol
    if (props.plotParams.get("fvol")) {
      // do not show OI line when showing doix
      if (!props.plotParams.get("doix")) {
        seriesOI = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: "OI",
            xAxis: xAxis,
            zIndex: 3,
            syncWithAxis: valueAxisLhs2,
            yAxis: valueAxisLhs2,
            stroke: am5.color("#FF9933"),
            fill: am5.color("#FF9933"),
            valueYField: "oi",
            valueXField: "time",
            tooltip: am5.Tooltip.new(root, {}),
          })
        )

        seriesOI.strokes.template.setAll({
          strokeWidth: 2,
        })
        seriesOI.get("tooltip").label.set("text", "OI: {valueY}")
      }

      if (!props.plotParams.get("trp")) {
        seriesVol = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Vol",
            xAxis: xAxis,
            syncWithAxis: valueAxisRhs2,
            yAxis: valueAxisRhs2,
            valueYField: "vol",
            valueXField: "time",
            tooltip: am5.Tooltip.new(root, {}),
          })
        )

        seriesVol.columns.template.setAll({
          fillOpacity: 0.2,
          strokeWidth: 0,
          width: am5.percent(70),
        })

        seriesVol.get("tooltip").label.set("text", "Vol: {valueY}")
      }
    }

    let seriesTrp
    if (props.plotParams.get("plot") === "fut") {
      if (props.plotParams.get("trp")) {
        seriesTrp = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Trend Push",
            xAxis: xAxis,
            syncWithAxis: valueAxisRhs2,
            yAxis: valueAxisRhs2,
            valueYField: "net_doix_change",
            valueYGrouped: "extreme",
            valueXField: "time",
            clustered: false,
            // tooltip: am5.Tooltip.new(root, {})
          })
        )

        seriesTrp.columns.template.setAll({
          fillOpacity: 0.6,
          strokeWidth: 0,
          strokeOpacity: 0,
          width: am5.percent(100),
        })

        seriesTrp.columns.template.adapters.add(
          "fill",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") > 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )

        seriesTrp.columns.template.adapters.add(
          "stroke",
          function (fill, target) {
            if (target.dataItem && target.dataItem.get("valueY") > 0) {
              return am5.color("#01DF01") // green
            } else {
              return am5.color("#FF0000") // red
            }
          }
        )

        // seriesTrp.get("tooltip").label.set("text", "Push: {valueY}")
      }
    } else if (isProUser) {
      seriesTrp = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Inventory Bias Strength",
          xAxis: xAxis,
          syncWithAxis: valueAxisRhs2,
          yAxis: valueAxisRhs2,
          valueYField: "inv_bias",
          valueYGrouped: "extreme",
          // valueYShow: "valueYSum",
          valueXField: "time",
          clustered: false,
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesTrp.columns.template.setAll({
        fillOpacity: 0.6,
        strokeWidth: 0,
        strokeOpacity: 0,
        width: am5.percent(100),
      })

      seriesTrp.columns.template.adapters.add("fill", function (fill, target) {
        if (target.dataItem && target.dataItem.get("valueY") > 0) {
          return am5.color("#01DF01") // green
        } else {
          return am5.color("#FF0000") // red
        }
      })

      seriesTrp.columns.template.adapters.add(
        "stroke",
        function (fill, target) {
          if (target.dataItem && target.dataItem.get("valueY") > 0) {
            return am5.color("#01DF01") // green
          } else {
            return am5.color("#FF0000") // red
          }
        }
      )

      seriesTrp.get("tooltip").label.set("text", "Bias: {valueY}")
    }

    let candlestickSeries
    console.log("Future test props.plotParams=",props.plotParams);
    if (props.plotParams.get("plot") === "fut") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Futures Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "open",
          highValueYField: "high",
          lowValueYField: "low",
          valueYField: "close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      )
    } else if (props.plotParams.get("plot") === "spt") {
      candlestickSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: props.plotParams.get("displayInstr") + " Spot Price",
          clustered: false,
          xAxis: xAxis,
          yAxis: valueAxisRhs1,
          openValueYField: "undy_open",
          highValueYField: "undy_high",
          lowValueYField: "undy_low",
          valueYField: "undy_close",
          valueXField: "time",
          // tooltip: am5.Tooltip.new(root, {})
        })
      )
    }

    if (candlestickSeries != undefined) {
      candlestickSeries.columns.template.states.create("riseFromOpen", {
        fill: am5.color(0x76b041),
        stroke: am5.color(0x76b041),
      })
      candlestickSeries.columns.template.states.create("dropFromOpen", {
        fill: am5.color(0xe4572e),
        stroke: am5.color(0xe4572e),
      })

      // candlestickSeries.get("tooltip").label.set("text", "Close: {valueY}");
      // candlestickSeries.get("tooltip").label.set("text", "Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

      candlestickSeries.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }
    if (props.plotParams.get("plot") === "fut") {
      seriesVWAP.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesVPOC.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("gf")) {
      if (isProUser && props.plotParams.get("plot") === "spt") {
        seriesGF.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"],
        })
      }
      
      seriesGFH.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesGFL.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("gr")) {
      seriesGRH.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesGRL.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("gm")) {
      seriesGM.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("oiPlot") === "oi") {
      seriesPutOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesCallOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesTotalOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesNetOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("oiPlot") === "doi") {
      seriesPutDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesCallDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      // seriesTotalDOI.data.processor = am5.DataProcessor.new(root, {
      //   dateFormat: "dd-MM-yyyy HH:mm",
      //   dateFields: ["time"]
      // });
      seriesNetDOI.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("doix")) {
      seriesPutDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesCallDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesTotalDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
      seriesNetDOIX.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    if (props.plotParams.get("fvol")) {
      if (!props.plotParams.get("doix")) {
        seriesOI.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"],
        })
      }
      if (!props.plotParams.get("trp")) {
        seriesVol.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"],
        })
      }
    }

    if (props.plotParams.get("plot") === "fut") {
      if (props.plotParams.get("trp")) {
        seriesTrp.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "dd-MM-yyyy HH:mm",
          dateFields: ["time"],
        })
      }
    } else if (isProUser) {
      seriesTrp.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })
    }

    // candlestickSeries.get("tooltip").label.set("text", "[{column.fill}]Open: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")

    let seriesPremium, seriesPcr
    if (props.plotParams.get("prem") === "snth") {
      let valueAxisSyntheticPremiumRenderer = valueAxisRhs3.get("renderer")
      valueAxisSyntheticPremiumRenderer.labels.template.setAll({
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 5,
        verticalCenter: "bottom",
        fontSize: "0.8em",
        maxPosition: 0.95,
      })

      seriesPremium = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Synthetic Premium",
          xAxis: xAxis,
          yAxis: valueAxisRhs3,
          valueYField: "synthetic_fut_diff",
          valueXField: "time",
          fillOpacity: 0.4,
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesPremium.columns.template.setAll({
        fillOpacity: 0.5,
        strokeWidth: 2,
        width: am5.percent(70),
      })

      seriesPremium.get("tooltip").label.set("text", "S Prem: {valueY}")

      seriesPremium.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesPremiumRef.current = seriesPremium
    } else if (props.plotParams.get("prem") === "fut") {
      let valueAxisPremiumRenderer = valueAxisRhs3.get("renderer")
      valueAxisPremiumRenderer.labels.template.setAll({
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 5,
        verticalCenter: "bottom",
        fontSize: "0.8em",
        maxPosition: 0.95,
        inside: false,
      })

      seriesPremium = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Futures Premium",
          xAxis: xAxis,
          yAxis: valueAxisRhs3,
          valueYField: "undy_prem",
          // valueYField: "mviz_prem",
          valueXField: "time",
          fillOpacity: 0.3,
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesPremium.columns.template.setAll({
        fillOpacity: 0.5,
        strokeWidth: 2,
        width: am5.percent(70),
      })

      seriesPremium.get("tooltip").label.set("text", "Prem: {valueY}")

      seriesPremium.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesPremiumRef.current = seriesPremium
    } else if (props.plotParams.get("prem") === "bias") {
      let valueAxisPremiumRenderer = valueAxisRhs3.get("renderer")
      valueAxisPremiumRenderer.labels.template.setAll({
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 5,
        verticalCenter: "bottom",
        fontSize: "0.8em",
        maxPosition: 0.95,
        inside: false,
      })

      seriesPremium = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Bias",
          xAxis: xAxis,
          yAxis: valueAxisRhs3,
          valueYField: "trend_bias",
          valueXField: "time",
          clustered: false,
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesPremium.columns.template.setAll({
        fillOpacity: 0.6,
        strokeWidth: 0,
        strokeOpacity: 0,
        width: am5.percent(100),
      })

      seriesPremium.columns.template.adapters.add(
        "fill",
        function (fill, target) {
          if (
            target.dataItem &&
            (target.dataItem.get("valueY") > 130 ||
              (target.dataItem.get("valueY") < 0 &&
                target.dataItem.get("valueY") > -130))
          ) {
            return am5.color("#01DF01") // green
          } else {
            return am5.color("#FF0000") // red
          }
        }
      )

      seriesPremium.columns.template.adapters.add(
        "stroke",
        function (fill, target) {
          if (
            target.dataItem &&
            (target.dataItem.get("valueY") > 130 ||
              (target.dataItem.get("valueY") < 0 &&
                target.dataItem.get("valueY") > -130))
          ) {
            return am5.color("#01DF01") // green
          } else {
            return am5.color("#FF0000") // red
          }
        }
      )

      seriesPremium.get("tooltip").label.set("text", "Bias: {valueY}")

      seriesPremium.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesPremiumRef.current = seriesPremium

      // var colorSet = am5.ColorSet.new(root, {});

      seriesPcr = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "PCR",
          xAxis: xAxis,
          yAxis: valueAxisLhs3,
          valueYField: "oi_pcr",
          valueXField: "time",
          stroke: am5.color("#FDBD45"),
          clustered: false,
          tooltip: am5.Tooltip.new(root, {}),
        })
      )

      seriesPcr.strokes.template.setAll({
        strokeWidth: 2,
      })

      // seriesPcr.strokes.template.adapters.add("stroke", function(fill, target) {
      //   if (target.dataItem && target.dataItem.get("valueY") >= 1.0) {
      //       return am5.color("#01DF01");  // green
      //   }
      //   else {
      //       return am5.color("#FF0000");  // red
      //   }
      // });

      seriesPcr.get("tooltip").label.set("text", "PCR: {valueY}")

      seriesPcr.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "dd-MM-yyyy HH:mm",
        dateFields: ["time"],
      })

      seriesPcrRef.current = seriesPcr
    }

    // Stack Y-axes
    chart.leftAxesContainer.set("layout", root.verticalLayout)
    chart.rightAxesContainer.set("layout", root.verticalLayout)

    seriesVWAPRef.current = seriesVWAP
    seriesVPOCRef.current = seriesVPOC
    seriesGFRef.current = seriesGF
    seriesGFHRef.current = seriesGFH
    seriesGFLRef.current = seriesGFL
    seriesGRHRef.current = seriesGRH
    seriesGRLRef.current = seriesGRL
    seriesGMRef.current = seriesGM
    seriesOIRef.current = seriesOI
    seriesVolRef.current = seriesVol
    seriesTrpRef.current = seriesTrp
    candlestickSeriesRef.current = candlestickSeries

    seriesPutOIRef.current = seriesPutOI
    seriesCallOIRef.current = seriesCallOI
    seriesTotalOIRef.current = seriesTotalOI
    seriesNetOIRef.current = seriesNetOI

    seriesPutDOIRef.current = seriesPutDOI
    seriesCallDOIRef.current = seriesCallDOI
    // seriesTotalDOIRef.current = seriesTotalDOI;
    seriesNetDOIRef.current = seriesNetDOI

    seriesPutDOIXRef.current = seriesPutDOIX
    seriesCallDOIXRef.current = seriesCallDOIX
    seriesTotalDOIXRef.current = seriesTotalDOIX
    seriesNetDOIXRef.current = seriesNetDOIX

    //create chart legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    )
    legend.data.setAll(chart.series.values)

    // //create chart title
    // chart.children.unshift(am5.Label.new(root, {
    //   text: "NIFTY Futures Line Chart",
    //   fontSize: 24,
    //   fontWeight: "500",
    //   textAlign: "center",
    //   x: am5.percent(50),
    //   centerX: am5.percent(50),
    //   paddingTop: 0,
    //   paddingBottom: 4
    // }));

    //add watermark text
    chart.plotContainer.children.push(
      am5.Label.new(root, {
        text: WATERMARK_CONFIG.LC_TEXT,
        y: am5.percent(100),
        centerY: am5.percent(100),
        opacity: 0.3,
        marginLeft: 10,
        marginBottom: 10,
        fontSize: 24,
        fontWeight: "400",
      })
    )

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#000000"),
      strokeOpacity: 0.5,
      fill: am5.color("#000000"),
      fillOpacity: 0.7,
    })

    // Add cursor for date time x axis
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        // behavior: "zoomX",
        xAxis: xAxis,
      })
    )

    //add tooltip for date time x axis
    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    )

    valueAxisRhs1.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    )

    if (props.plotParams.get("fvol")) {
      valueAxisRhs2.set(
        "tooltip",
        am5.Tooltip.new(root, {
          themeTags: ["axis"],
        })
      )

      //add tooltip for date time x axis
      valueAxisLhs2.set(
        "tooltip",
        am5.Tooltip.new(root, {
          themeTags: ["axis"],
        })
      )
    }

    if (props.plotParams.get("prem") !== "") {
      valueAxisLhs3.set(
        "tooltip",
        am5.Tooltip.new(root, {
          themeTags: ["axis"],
        })
      )
    }
    console.log("Future test end of default =",candlestickSeriesRef.current)

    return () => {
      root.dispose()
    }
  }, [])

  //this will handle the props data change and set/update the chart
  useLayoutEffect(() => {
    console.log("Future test uselayout effect child ===========>>>>>>>>>>>>>>>",props.chartData,props.isIncrementalData, candlestickSeriesRef.current);
    //1st load
    if (props.chartData != undefined && props.chartData.length > 0) {
      if (props.isIncrementalData  &&  candlestickSeriesRef.current) {
        console.log("incremental data updates", props.chartData)
        candlestickSeriesRef.current.data.pushAll(props.chartData)
        if (props.plotParams.get("plot") === "fut") {
          seriesVWAPRef.current.data.pushAll(props.chartData)
          seriesVPOCRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("gf")) {
          if(isProUser && props.plotParams.get("plot") === "spt")
            seriesGFRef.current.data.pushAll(props.chartData)
          seriesGFHRef.current.data.pushAll(props.chartData)
          seriesGFLRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("gr")) {
          seriesGRHRef.current.data.pushAll(props.chartData)
          seriesGRLRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("gm")) {
          seriesGMRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("fvol")) {
          if (!props.plotParams.get("doix"))
            seriesOIRef.current.data.pushAll(props.chartData)
          if (!props.plotParams.get("trp"))
            seriesVolRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("plot") === "fut") {
          if (props.plotParams.get("trp"))
            seriesTrpRef.current.data.pushAll(props.chartData)
        } else if (isProUser) {
          seriesTrpRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("oiPlot") === "oi") {
          seriesPutOIRef.current.data.pushAll(props.chartData)
          seriesCallOIRef.current.data.pushAll(props.chartData)
          seriesTotalOIRef.current.data.pushAll(props.chartData)
          seriesNetOIRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("oiPlot") === "doi") {
          seriesPutDOIRef.current.data.pushAll(props.chartData)
          seriesCallDOIRef.current.data.pushAll(props.chartData)
          // seriesTotalDOIRef.current.data.pushAll(props.chartData);
          seriesNetDOIRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("doix")) {
          // seriesPutDOIXRef.current.data.pushAll(props.chartData)
          // seriesCallDOIXRef.current.data.pushAll(props.chartData)
          // seriesTotalDOIXRef.current.data.pushAll(props.chartData)
          seriesNetDOIXRef.current.data.pushAll(props.chartData)
        }

        if (props.plotParams.get("prem") === "bias")
          seriesPcrRef.current.data.pushAll(props.chartData)
        if (props.plotParams.get("prem") !== "")
          seriesPremiumRef.current.data.pushAll(props.chartData)
        // else if(props.plotParams.get("prem") === "fut")
        //   seriesPremiumRef.current.data.pushAll(props.chartData);
        // seriesSpotRef.current.data.pushAll(props.chartData);
      } else {
        console.log("initial chart load")
        candlestickSeriesRef.current.data.setAll(props.chartData)

        if (props.plotParams.get("plot") === "fut") {
          seriesVWAPRef.current.data.setAll(props.chartData)
          seriesVPOCRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("gf")) {
          if(isProUser && props.plotParams.get("plot") === "spt")
            seriesGFRef.current.data.setAll(props.chartData)
          seriesGFHRef.current.data.setAll(props.chartData)
          seriesGFLRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("gr")) {
          seriesGRHRef.current.data.setAll(props.chartData)
          seriesGRLRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("gm")) {
          seriesGMRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("fvol")) {
          if (!props.plotParams.get("doix"))
            seriesOIRef.current.data.setAll(props.chartData)
          if (!props.plotParams.get("trp"))
            seriesVolRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("plot") === "fut") {
          if (props.plotParams.get("trp"))
            seriesTrpRef.current.data.setAll(props.chartData)
        } else if (isProUser) {
          seriesTrpRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("oiPlot") === "oi") {
          seriesPutOIRef.current.data.setAll(props.chartData)
          seriesCallOIRef.current.data.setAll(props.chartData)
          seriesTotalOIRef.current.data.setAll(props.chartData)
          seriesNetOIRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("oiPlot") === "doi") {
          seriesPutDOIRef.current.data.setAll(props.chartData)
          seriesCallDOIRef.current.data.setAll(props.chartData)
          // seriesTotalDOIRef.current.data.setAll(props.chartData);
          seriesNetDOIRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("doix")) {
          // seriesPutDOIXRef.current.data.setAll(props.chartData)
          // seriesCallDOIXRef.current.data.setAll(props.chartData)
          // seriesTotalDOIXRef.current.data.setAll(props.chartData)
          seriesNetDOIXRef.current.data.setAll(props.chartData)
        }

        if (props.plotParams.get("prem") === "bias")
          seriesPcrRef.current.data.setAll(props.chartData)
        if (props.plotParams.get("prem") !== "")
          seriesPremiumRef.current.data.setAll(props.chartData)

        // else if(props.plotParams.get("prem") === "fut")
        //   seriesPremiumRef.current.data.setAll(props.chartData);
      }
    }
    // seriesPutDOIXRef.current?.hide();
    // seriesCallDOIXRef.current?.hide();
    // seriesTotalDOIXRef.current?.hide();
  }, [props.plotParams, props.chartData, props.isIncrementalData])

  function toggleFullScreen() {
    let chartdiv = document.querySelector(`#${props.id}`)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  const handlenDaysChange = (event) => {
    setnDays(event.target.value)
    props.changenDays(event.target.value)
  }

  const disabledDate = (current) => {
    return (
      (current && current >= dayjs().endOf("day")) ||
      dayjs(current).day() == 6 ||
      dayjs(current).day() == 0
    )
  }
  const onDateChange = (date, dateString) => {
    setStartDate(dayjs(date))
    console.log("Date changed", dayjs(date), dateString)
    props.handleStartDateChange(dateString)
  }

  // const handleInstrumentChange = (event) => {
  //   // console.log("Instument selected=",event,event.target,userSettings.data,);
  //   let newSymbol
  //   newSymbol = event.target.value
  //   let instrument = undefined
  //   // for(let i=0;i<instrumentsData.length;i++){
  //   //   if(instrumentsData[i].instr==newSymbol){
  //   //     instrument=instrumentsData[i];
  //   //     if(!isStocksAllowed && instrument.resource && instrument.resource=="stocks"){
  //   //       props.openSunscriptionModal(true,TEXT_MSGS.MP_CHART_STOCK_RESOURCE_NOT_SUBSCRIBED_DESC);
  //   //       return;
  //   //     }
  //   //     setSelectedInstrumentData(instrument)
  //   //   }
  //   // }
  //   const newInstrumentIndex = props.instrumentsData.findIndex(
  //     (e) => e.instr === newSymbol
  //   )
  //   // const isSlowInstrumentSelected=checkIfSlowInstrument(event.target.value,slowInstruments);
  //   props.instrumentChange(props.instrumentsData[newInstrumentIndex])
  // }

  // selectedInstrumentData
  // handleInstrumentChange
  // instrumentsData

  // console.clear()
  console.log(props, "CapProps")
  return (
    <>
      <div
        id="container"
        style={{
          backgroundColor: "#111111",
          zIndex: "99",
          marginTop: "0px",
          padding: "20px",
          width: "100%",
          minWidth: `${MIN_CHART_WIDTH}`,
          minHeight: "calc(110% - 256px)",
        }}
      >
        {/* <div id="container" style={{ backgroundColor: "#111111", zIndex: "99", marginTop: "0px", padding: "20px", width: "100%", minHeight: "calc(110% - 256px)" }}> */}

        {/* <LineChartHeader/> */}
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {/* <Tooltip title="Go Back">
            <IconButton
              onClick={() => navigate(-1)}
              size='large'>
              <ArrowBackIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
          {/* <Container sx={{ width: "80%" }}>
            <Typography variant='h4' color='primary' sx={{ alignSelf: "center" }}>{props.plotParams.get("displayInstr")} {props.plotParams.get("chartPlotType")} Chart</Typography>
          </Container>
          <Tooltip title="Full Screen">
            <IconButton
              onClick={() => toggleFullScreen()}
              size='large'>
              <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <div id="chartContainer">
          {!isBasicUser ? (
            <>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap={4}
                sx={{ mb: 1, ml: "4.6rem" }}
              >
                {/* HERE */}
                {console.log("InstrumentsDropdown 0=",props.selectedInstrument,props.params)}
                {props.params.plot=="fut" || props.params.plot=="opt"?
                <InstrumentsDropdown
                  width="250"
                  MenuProps={MenuProps}
                  selectedInstrument={props.selectedInstrument}
                  instrumentChange={props.instrumentChange}
                  instrumentsData={props.instrumentsData}
                />:
                <></>
              }

                <FormControl
                  size="small"
                  sx={{ width: "90px", minWidth: "90px", ml: 0, mt: 0 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    No. of days
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nDays}
                    MenuProps={MenuProps}
                    sx={{ height: "40px" }}
                    label="No. of days"
                    onChange={(event) => handlenDaysChange(event)}
                  >
                    {NO_OF_DAYS.map((item, index) => (
                      <MenuItem value={item.value}>{item.key}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DatePicker
                  onChange={onDateChange}
                  allowClear={false}
                  disabledDate={disabledDate}
                  placeholder="Select Start Date"
                  value={startDate}
                  format={DateFormat}
                  style={{
                    border: "1px solid #484848",
                    background: "#111111",
                    borderRadius: "4px",
                    width: 200,
                    marginTop: "0px",
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <div
            id="chartcontrols"
            style={{ padding: "10px", marginTop: "16px", maxWidth: "100%" }}
          ></div>
          <div
            id={props.id}
            style={{ width: "100%", maxWidth: "100%", height: "800px" }}
          />
        </div>
      </div>
    </>
  )
}

export default FuturesLineChartContainer